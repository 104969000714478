import React, { useState } from "react";
import { Container, Form, Spinner, Button, Alert } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import http_post from '../scripts/post';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom';
import '../styles/Login.css';
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from '../components/LoadingSpinner';
import me from '../scripts/me';
import qs from 'qs';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Recovery.css';
import Lato from '../components/Lato'
import LatoBold from "../components/LatoBold";

class RecoveryHash extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password1Input: "",
            password2Input: "",
            expired: false,
            formErrors: {
                password1Input: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                password2Input: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            loadingShow: false,
            loading2: true,
            resultError: {
                status: false,
                info: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    showError(show = true, error) {

        const { resultError } = this.state;
        if (!show) resultError.status = show;
        else {
            resultError.status = show;
            resultError.info = error;
        }
        this.setState({ resultError: resultError, loadingShow: false });

    }

    handleSubmit(event) {

        let valid = true;
        const { password1Input, password2Input, formErrors } = this.state;
        const passwordRegex = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
        this.showError(false);

        if (password1Input <= 0) {

            formErrors.password1Input.info = "Uzupełnij nowe hasło!";
            formErrors.password1Input.isInvalid = true;
            valid = false;
            toast.error("Uzupełnij poprawnie formularz!");

        } else {

            if (passwordRegex.test(password1Input)) {
                formErrors.password1Input.isValid = true;
                formErrors.password1Input.isInvalid = false;
                if (password2Input <= 0) {
                    formErrors.password2Input.info = "Powtórz nowe hasło!";
                    formErrors.password2Input.isInvalid = true;
                    valid = false;
                    toast.error("Uzupełnij poprawnie formularz!");
                } else {
                    if (password1Input !== password2Input) {
                        formErrors.password2Input.info = "Powtórzone hasło nie jest identyczne!";
                        formErrors.password2Input.isInvalid = true;
                        valid = false;
                        toast.error("Uzupełnij poprawnie formularz!");
                    } else {
                        formErrors.password1Input.isValid = true;
                        formErrors.password1Input.isInvalid = false;
                    }
                }
            } else {
                formErrors.password1Input.info = "Twoje nowe hasło nie spełnia wymagań złożoności!";
                formErrors.password1Input.isInvalid = true;
                valid = false;
            }
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            this.setState({ loadingShow: true });
            const data = {
                password: password2Input,
                hash: this.props.match.params.hash
            }

            http_post(`resetPassword.php`, data).then((res) => {

                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ loadingShow: false, recoveryProcess: true });
                        this.resetValid();
                    } else if (res.data.valid === 0) {
                        this.resetValid();
                        this.setState({ loadingShow: false, expired: true });
                    } else {
                        window.location.reload();
                    }
                } else {
                    window.location.reload();
                }

            })

        } else event.stopPropagation();


        event.preventDefault();

    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            password1Input: "",
            password2Input: ""
        })
    }

    componentDidMount() {
        me().then((self) => {
            if (self.response === 1) {
                this.props.history.push('/');
            } else {
                const data = {
                    hash: this.props.match.params.hash
                }

                http_post(`recoveryHash.php`, data).then((res) => {

                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            this.setState({ loading2: false });
                        } else {
                            this.setState({ loading2: false, expired: true });
                        }
                    } else {
                        this.props.history.push('/logowanie');
                    }
                })
            }
        });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading2}>
                <Header />
                <Container className="loginForm">
                    <ToastContainer />
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <h2><Lato>Ustaw nowe hasło</Lato></h2>
                        {this.state.loadingShow ?
                            <div style={{ textAlign: 'center' }}>
                                <Spinner animation="border" className="loadingSpinner" variant="secondary" />
                            </div>
                            :
                            <>
                                {this.state.recoveryProcess ?
                                    <>
                                        <Alert variant="success">
                                            <Alert.Heading>Zmieniono hasło</Alert.Heading>
                                            <hr />
                                            <p className="mb-0">
                                                Twoje hasło zostało zmienione. Teraz możesz przejść do logowania i zalogować się na swoje konto.
                                        </p>
                                        </Alert>
                                        <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Przejdź do logowania</Link></Form.Text>
                                    </>
                                    :
                                    <>
                                        {this.state.expired ?
                                            <>
                                                <Alert variant="danger">
                                                    <Alert.Heading>Twój link wygasł lub jest błędny</Alert.Heading>
                                                    <hr />
                                                    <p className="mb-0">
                                                        Upewnij się, że poprawnie otworzyłeś link lub wygeneruj go ponownie, korzystając z opcji odzyskiwania hasła.
                                        </p>
                                                </Alert>
                                                <Form.Text className="text-muted text-right"><Link to="/odzyskiwanie" className="linkRoute">Przejdź do odzyskiwania hasła</Link></Form.Text>
                                            </>
                                            :
                                            <>
                                                <Form.Group>
                                                    <Form.Label>Nowe hasło</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.password1Input.isValid} isInvalid={this.state.formErrors.password1Input.isInvalid} onChange={this.handleChange} value={this.state.password1Input} name="password1Input" type="password" placeholder="Wprowadź nowe hasło" />
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        Twoje hasło musi zawierać minimum 8 znaków oraz jedną wielką literę, cyfrę i znak specjalny.
                                            </Form.Text>
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.password1Input.info}</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Label>Powtórz nowe hasło</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.password2Input.isValid} isInvalid={this.state.formErrors.password2Input.isInvalid} onChange={this.handleChange} value={this.state.password2Input} name="password2Input" type="password" placeholder="Wprowadź potwórzenie nowego hasła" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.password2Input.info}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Button disabled={this.state.loadingShow} type="submit" variant="primary" size="lg" block>
                                                    <LatoBold>Zmień hasło</LatoBold>
                                                </Button>
                                            </>
                                        }
                                    </>
                                }
                            </>

                        }
                    </Form>
                </Container>

            </LoadingSpinner >
        );
    }
}

export default RecoveryHash;