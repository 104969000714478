import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge, Pagination } from 'react-bootstrap';
import http_post from '../scripts/post';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import NewComment from './NewComment';
import Cookies from 'universal-cookie';

const enums = {
    "0": 10,
    "1": 15,
    "2": 25,
    "3": 50
};

class Comments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

    }




    render() {

        let totalPage = 1;

        if (this.props.total != 0)
            totalPage = Math.ceil(parseInt(this.props.total) / 18);

        return (
            <>
                <Row>
                    <Col lg={12}>
                        <Pagination className="text-right" style={{ justifyContent: "flex-end" }}>
                            {this.props.page == "1" ?
                                <>
                                    <Pagination.First disabled />
                                    <Pagination.Prev disabled />
                                    <Pagination.Item disabled>-</Pagination.Item>
                                </>
                                :
                                <>
                                    <Pagination.First onClick={() => { this.props.handlerPage("first") }} />
                                    <Pagination.Prev onClick={() => { this.props.handlerPage("previous") }} />
                                    <Pagination.Item onClick={() => { this.props.handlerPage(this.props.page - 1) }}>{this.props.page - 1}</Pagination.Item>
                                </>
                            }
                            <Pagination.Item active>{this.props.page}</Pagination.Item>

                            {this.props.page == totalPage ?
                                <>
                                    <Pagination.Item disabled>-</Pagination.Item>
                                    <Pagination.Next disabled />
                                    <Pagination.Last disabled />
                                </>
                                :
                                <>
                                    <Pagination.Item onClick={() => { this.props.handlerPage(this.props.page + 1) }}>{this.props.page + 1}</Pagination.Item>
                                    <Pagination.Next onClick={() => { this.props.handlerPage("next") }} />
                                    <Pagination.Last onClick={() => { this.props.handlerPage("last") }} />
                                </>
                            }

                        </Pagination>
                    </Col>
                </Row>
            </>

        )
    }

}

export default Comments;
