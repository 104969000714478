// @flow

import * as React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  font-size: 16px;
  height: auto;
  color: #212529;
  padding: 10px 18px;
  background-color: #f8f9fa;
  border: 1px solid #b5b5b5;
  border-radius: 24px;
  box-shadow: none;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.5s ease 0s;

  &:hover {
    border-color: #000000;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    &:not(:first-child) {
      margin-left: 0;
    }

    font-size: 12px;
    margin: 5px 0;
  }

  @media (prefers-color-scheme: dark) {
    &:hover {
      border-color: #ffffff;
    }
  }
`

type Props = {|
  label: string,
  onButtonClick: () => void,
|}

export default class AcceptButton extends React.Component<Props> {
  render(): React.Node {
    const { label, onButtonClick } = this.props

    return (
      <Button className='accept-button' onClick={onButtonClick}>
        <span>{label}</span>
      </Button>
    )
  }
}
