import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import NewComment from './NewComment';
import SingleComment from './SingleComment';
import Pagination from './Pagination';
import Lato from './Lato';

class Comments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.handleSort = this.handleSort.bind(this);
    }

    handleSort(event) {

        event.preventDefault();

        switch (event.target.name) {
            case "amountPerPage":
                this.props.refreshAmount(event.target.value);
                break;
            case "newestPage":
                this.props.refreshNewest(event.target.value);
                break;
        }
    }

    render() {

        return (
            <>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header><Lato>KOMENTARZE</Lato></Card.Header>
                            <Card.Body>
                                <NewComment type={this.props.type} id={this.props.id} answerComment={null} refreshFunction={this.props.refreshFunction} />
                                <hr />
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form inline className="align-items-center" style={{ marginBottom: "15px" }}>
                                            <Form.Label>
                                                Wyświetl
                                            </Form.Label>
                                            <Form.Control as="select" onChange={this.handleSort} name="amountPerPage" value={this.props.amount} style={{ marginLeft: "10px", marginRight: "10px", width: "auto" }} >
                                                <option value="0">10</option>
                                                <option value="1">15</option>
                                                <option value="2">25</option>
                                                <option value="3">50</option>
                                            </Form.Control>
                                            <Form.Label>
                                                na stronę
                                            </Form.Label>
                                        </Form>
                                    </Col>
                                    <Col xs={12} sm={6} >
                                        <Form inline className="align-items-center text-right" style={{ marginBottom: "10px", justifyContent: "flex-end" }}>
                                            <Form.Label>
                                                Sortuj
                                            </Form.Label>
                                            <Form.Control onChange={this.handleSort} as="select" name="newestPage" value={this.props.newest} style={{ marginLeft: "10px", marginRight: "10px", width: "auto" }} >
                                                <option value="0">od najnowszych</option>
                                                <option value="1">od najstarszych</option>
                                            </Form.Control>
                                        </Form>
                                    </Col>
                                </Row>
                                {this.props.commentLists && this.props.commentLists.map((comment) => (
                                    (comment.answer === "0" &&
                                        <>
                                            <SingleComment controlNumber={this.props.control} page={this.props.page} switch={this.props.switch} updateReplies={this.props.updateReplies} type={this.props.type} comment={comment} />
                                        </>
                                    )
                                ))}
                                <Pagination handlerPage={this.props.handlerPage} total={this.props.total} amount={this.props.amount} comments={this.props.comments} page={this.props.page} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>

        )
    }

}


export default Comments;
