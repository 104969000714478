import React from 'react';
import { Navbar, Nav, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer(props) {

    const noFixed = props.noFixed;

    return (
        <Navbar sticky="bottom" fixed={noFixed ? "" : "bottom"} className="navFooter">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="footerNavbar">
                <Nav className="mr-auto"><Form.Text className="d-none d-sm-inline infoLink">Harcerska Fundacja Turystyczna “Pomarańczarni” 2020. Wszelkie prawa zastrzeżone.</Form.Text></Nav>
                <Nav>
                    <Form.Text className="text-muted text-right"><Link to="/dokumenty" className="footerLink">Dokumenty</Link></Form.Text>
                    <Form.Text className="text-muted text-right"><Link to="/kontakt" className="footerLink">Kontakt</Link></Form.Text>
                </Nav>
            </Navbar.Collapse>
        </Navbar >


    );
}


export default Footer;