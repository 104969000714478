import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import { Row, Col, Navbar, Breadcrumb, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RequireLogin from '../components/RequireLogin';
import '../styles/PrivacyPolicy.css';
import styles from '../styles/Gallery.module.css';
import Pagination from '../components/PaginationSearch';

class Folder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: null,
            page: 1
        };

        this.updateContent = this.updateContent.bind(this);
        this.handlerPage = this.handlerPage.bind(this);
    }

    componentDidMount() {
        this.updateContent();
    }

    updateContent() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            tag: this.props.match.params.tag,
            token: token,
            page: this.state.page
        }

        http_post(`tag.php`, data).then((res) => {
            if (res.status === 200) {
                this.setState({ data: res.data, loading: false });
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Folder&no=' + res.status + '&text=' + res.text + '&url=tag.php'
                });
            }
        });
    }

    handlerPage(page) {

        this.setState({ switch: true }, () => {
            this.forceUpdate()
            switch (page) {
                case "first":
                    this.setState({ page: 1 }, () => { this.updateContent(); });
                    break;
                case "last":
                    this.setState({ page: Math.ceil(parseInt(this.state.data.amount) / 18) }, () => { this.updateContent(); });
                    break;
                case "next":
                    this.setState({ page: this.state.page + 1 }, () => { this.updateContent(); })
                    break;
                case "previous":
                    this.setState({ page: this.state.page - 1 }, () => { this.updateContent(); })
                    break;
                default:
                    this.setState({ page: page }, () => { this.updateContent(); });
                    break;
            }

        });

        this.setState({ switch: false });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin>
                    <Header />
                    {
                        this.state.data !== null ? (
                            <Container className="container">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col><Link className={styles.yearLink} >#{this.props.match.params.tag}</Link></Col>
                                        </Row>
                                        <Row className="align-iteams-center flex-wrap">
                                            {
                                                this.state.data.photos.map((photo) => {
                                                    return (
                                                        <Col xs={6} md={4} xl={2} className="colFolder" align="center">
                                                            <Link to={"/galeria/" + photo.id} className="colLink">
                                                                <img src={photo.mini} className="foldersImg" />
                                                                <div class="colLinkText">{photo.title}</div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        <Row className="mt-2">
                                            <Col xs={12} sm={6}>
                                            Znaleziono wyników: <strong>{this.state.data.amount}</strong>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <Pagination handlerPage={this.handlerPage} total={this.state.data.amount} page={this.state.page} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Container>
                        ) : <></>
                    }

                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default Folder;