import React from "react";
import { Container, Spinner } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, TableHeaderColumn } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfo } from '@fortawesome/free-solid-svg-icons';
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import Select from 'react-select';
import heic2any from "heic2any";
import { FileDrop } from 'react-file-drop';
import imageCompression from 'browser-image-compression';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

const { SearchBar } = Search;

class AdminFolders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "label",
            sortOrder: "asc",
            newFolderInput: "",
            descriptionInput: "",
            folderYearInput: null,
            miniOption: 0,
            miniUploadingInProgress: false,
            miniDefault: null,
            miniPhoto: null,
            modal_miniOption: 0,
            modal_miniUploadingInProgress: false,
            modal_miniPhoto: null,
            modal_miniSelectOptions: [],
            modal_miniSelected: null,
            editDescriptionInput: "",
            editMiniOption: 0,
            formErrors: {
                newFolderInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                descriptionInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                miniOption: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                folderYearInput: {
                    isValid: false,
                    isInvalid: true,
                    info: ""
                },
                editDescriptionInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                editMiniOption: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            show: false,
            modalDelete: {
                label: "",
                value: null
            },
            modalPreview: {
                description: "",
                value: null,
                loading: true
            },
            multiSelect: null
        };

        this.refreshData = this.refreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    componentDidMount() {
        this.refreshDataManually();

        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`foldersDefaultIcon.php`, data).then((res) => {
            if (res.status === 200) {
                this.setState({ miniDefault: res.data });
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminFolders&no=' + res.status + '&text=' + res.text + '&url=foldersDefaultIcon.php'
                });
            }
        })

        http_post(`years.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ years2: res.data }, () => {
                    let years1 = []
                    for (let i in res.data) {
                        years1.push({ label: res.data[i].label + (res.data[i].hidden === "1" ? " (ukryty)" : ""), value: res.data[i].label + (res.data[i].hidden === "1" ? " (ukryty)" : "") })
                    }
                    this.setState({ years: years1 })

                })
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=years.php'
                });
            }
        })
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }



        http_post(`foldersTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.folders.map((row) => {
                    row.photo = <><Link to={"/folder/" + row.value} className="colLink"><img src={row.mini} /></Link></>
                    row.button = <><Button variant="primary" onClick={() => { this.handlePreview(row.value) }}><FontAwesomeIcon icon={faInfo} /></Button> <Button variant="danger" onClick={() => { this.handleDelete(row.value, row.label) }}><FontAwesomeIcon icon={faTrash} /></Button></>
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=tags.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleClose2() {

        this.setState({ show2: !this.state.show2, modal_miniPhoto: null })
        this.resetValid();
    }

    handleDelete(value, label) {
        this.setState({
            modalDelete: {
                value: value,
                label: label
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    handlePreview(value) {
        this.setState({
            modalPreview: {
                loading: true
            }
        }, () => {

            this.setState({ show2: true }, () => {

                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    value: value
                }

                http_post(`foldersTableModal.php`, data).then((res) => {
                    if (res.status === 200) {

                        let tab = [];
                        let miniSelected = null;
                        let miniNo = null;

                        for (let i in res.data.photos) {
                            tab.push({ value: res.data.photos[i].id, label: <div><img className="mr-3" src={res.data.photos[i].mini} />{res.data.photos[i].no}</div> })
                            if (res.data.photos[i].id === res.data.miniPhotoId) {
                                miniSelected = res.data.photos[i].mini;
                                miniNo = res.data.photos[i].no;
                            }
                        }

                        this.setState({
                            modal_miniPhoto: (res.data.mini === "null" ? null : res.data.mini),
                            editDescriptionInput: res.data.description,
                            editPhotos: res.data.photos,
                            editMiniOption: res.data.miniOption,
                            modal_miniSelectOptions: tab,
                            modal_miniSelected: (res.data.miniPhotoId === null ? null : { value: res.data.miniPhotoId, label: <div><img className="mr-3" src={miniSelected} />{miniNo}</div> }),
                            modalPreview: {
                                loading: false,
                                value: value
                            }
                        })

                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=foldersTableModal.php'
                        });
                    }
                })
            })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteFolder.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false }, () => { toast.success("Poprawnie usunięto folder!") });
                    this.refreshDataManually();
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć folderu!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    handleChangeSelect(event) {

        event.preventDefault();
        switch (event.target.name) {
            default:
                this.setState({
                    [event.target.name]: Number(event.target.value),
                });
                break;
        }
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            newFolderInput: "",
            descriptionInput: "",
            folderYearInput: null,
            miniOption: 0,
            miniPhoto: null,
            descriptionInput: ""
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        switch (column.dataField) {
            case "label":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (newValue === "") {
                        toast.warning("Nazwa folderu nie może być pusta!")
                        done(false);
                    } else if (oldValue === newValue || '#' + newValue === oldValue) {
                        done(false);
                    } else {
                        http_post(`editFolder.php?title`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono nazwę!")
                                    if (res.data.rr) {
                                        done(false);
                                        this.refreshDataManually('pagination');
                                    } else {
                                        done();
                                    }
                                } else if (res.data.valid === 2) {
                                    toast.error("Folder o tej nazwie w przypisanym roku już istnieje!")
                                    done(false);
                                } else {
                                    toast.error("Nie udało się zmienić nazwy!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }
                }, 0);
                break;
            case "hidden":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (oldValue === newValue) {
                        done(false);
                    } else {
                        http_post(`editFolder.php?hidden`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono widoczność!")
                                    if (res.data.rr) {
                                        done(false);
                                        this.refreshDataManually('pagination');
                                    } else {
                                        done();
                                    }
                                } else {
                                    toast.error("Nie udało się zmienić widoczności!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }

                }, 0);
                break;
            case "year":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: (newValue === "Nieprzypisane" ? newValue : newValue.substring(0, 4))
                    }
                    if (oldValue === newValue) {
                        done(false);
                    } else {
                        http_post(`editFolder.php?year`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono przypisany rok!")
                                    if (res.data.rr) {
                                        done(false);
                                        this.refreshDataManually('pagination');
                                    } else {
                                        done();
                                    }
                                } else {
                                    toast.error("Nie udało się zmienić przypisanego roku!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }

                }, 0);
                break;
        }
        return { async: true };
    }

    handleSubmit(event) {

        let valid = true;
        const { newFolderInput, descriptionInput, miniOption, formErrors, folderYearInput, miniPhoto } = this.state;

        if (folderYearInput === null) {
            formErrors.folderYearInput.info = "Wybierz rok!";
            formErrors.folderYearInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.folderYearInput.isValid = true;
            formErrors.folderYearInput.isInvalid = false;
        }

        if (newFolderInput <= 0) {
            formErrors.newFolderInput.info = "Wprowadź nazwę folderu!";
            formErrors.newFolderInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.newFolderInput.isValid = true;
            formErrors.newFolderInput.isInvalid = false;
        }

        if (descriptionInput <= 0) {
            formErrors.descriptionInput.info = "Wprowadź opis folderu!";
            formErrors.descriptionInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.descriptionInput.isValid = true;
            formErrors.descriptionInput.isInvalid = false;
        }

        if (miniOption === 0) {
            formErrors.miniOption.info = "Wybierz opcje miniatury!";
            formErrors.miniOption.isInvalid = true;
            valid = false;
        } else {

            if (miniOption === "2") {
                if (miniPhoto === null) {
                    formErrors.miniOption.info = "Wgraj zdjęcie do miniatury!";
                    formErrors.miniOption.isInvalid = true;
                    valid = false;
                } else {
                    formErrors.miniOption.isValid = true;
                    formErrors.miniOption.isInvalid = false;
                }

            } else {
                formErrors.miniOption.isValid = true;
                formErrors.miniOption.isInvalid = false;
            }
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                label: newFolderInput,
                desc: descriptionInput,
                miniOption: miniOption,
                year: folderYearInput.value,
                image: miniPhoto
            }

            http_post(`newFolder.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie dodano folder!") });
                        this.refreshDataManually();
                        this.resetValid();
                    } else if (res.data.valid === 2) {
                        this.setState({ show: false }, () => { toast.error("Folder o tej nazwie w przypisanym roku już istnieje!") });
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się dodać folderu!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })


        } else event.stopPropagation();

        event.preventDefault();
    }

    updateFolder(value) {

        let valid = true;
        const { editDescriptionInput, editMiniOption, modal_miniPhoto, modal_miniSelected, formErrors } = this.state;

        if (editDescriptionInput === "") {
            formErrors.editDescriptionInput.info = "Wprowadź opis!";
            formErrors.editDescriptionInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.editDescriptionInput.isValid = true;
            formErrors.editDescriptionInput.isInvalid = false;
        }

        if (editMiniOption !== 1) {
            if (editMiniOption === 2) {
                if (modal_miniPhoto === null) {
                    formErrors.editMiniOption.info = "Wybierz zdjęcie!";
                    formErrors.editMiniOption.isInvalid = true;
                    valid = false;
                } else {
                    formErrors.editMiniOption.isValid = true;
                    formErrors.editMiniOption.isInvalid = false;
                }
            }

            if (editMiniOption === 3) {
                if (modal_miniSelected === null) {
                    formErrors.editMiniOption.info = "Wybierz zdjęcie!";
                    formErrors.editMiniOption.isInvalid = true;
                    valid = false;
                } else {
                    formErrors.editMiniOption.isValid = true;
                    formErrors.editMiniOption.isInvalid = false;
                }
            }
        } else {
            formErrors.editMiniOption.isValid = true;
            formErrors.editMiniOption.isInvalid = false;
        }



        this.setState({ formErrors: formErrors });
        if (valid) {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                fid: value,
                desc: editDescriptionInput,
                mini: (editMiniOption === 2 ? modal_miniPhoto : null),
                miniPhotoId: (editMiniOption === 3 ? modal_miniSelected.value : null),
                miniOption: editMiniOption
            }
            http_post(`foldersTableModalEdit.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie edytowano szczegóły!") });
                        this.refreshDataManually('pagination');
                        this.handleClose2();
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się edytować szczegółów!") });
                        this.handleClose2();
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })
        }

    }

    render() {
        const columns = [{
            dataField: 'photo',
            text: 'Miniatura',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'label',
            text: 'Nazwa',
            sort: true,
            editorStyle: {
                textAlign: "center"
            },
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'year',
            text: 'Przypisany rok',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: this.state.years
            }
        }, {
            dataField: 'photos',
            text: 'Przypisane zdjęcia',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'hidden',
            text: 'Ukryty',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: [{
                    value: 'TAK',
                    label: 'TAK'
                }, {
                    value: 'NIE',
                    label: 'NIE'
                }]
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '150px' };
            }
        }];

        const defaultSorted = [{
            dataField: 'label',
            order: 'asc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        const customStyles = {
            control: base => ({
                ...base,
                height: 150
            }),
            control: (provided, state) => ({
                ...provided,
                height: 150
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: 150
            })

        }

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={2}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>FOLDERY</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Dodaj folder</Lato></Card.Title>
                                    <Form noValidate onSubmit={this.handleSubmit}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Rok</Form.Label>
                                            <Select isSearchable={true} value={this.state.folderYearInput} placeholder={"Wybierz..."} options={this.state.years2} isClearable={true} onChange={(dd) => this.setState({ folderYearInput: dd })} noOptionsMessage={(dd) => {
                                                if (dd.inputValue !== "") {
                                                    return "Nie znaleziono: " + dd.inputValue
                                                } else {
                                                    return "Brak lat!";
                                                }
                                            }} />
                                            <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.folderYearInput.isValid} isInvalid={this.state.formErrors.folderYearInput.isInvalid} />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.folderYearInput.info}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Nazwa folderu</Form.Label>
                                            <Form.Control isValid={this.state.formErrors.newFolderInput.isValid} isInvalid={this.state.formErrors.newFolderInput.isInvalid} onChange={this.handleChange} value={this.state.newFolderInput} name="newFolderInput" type="text" placeholder="Wprowadź nazwę folderu" />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.newFolderInput.info}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Opis</Form.Label>
                                            <Form.Control isValid={this.state.formErrors.descriptionInput.isValid} isInvalid={this.state.formErrors.descriptionInput.isInvalid} onChange={this.handleChange} value={this.state.descriptionInput} name="descriptionInput" as="textarea" rows="3" placeholder="Wprowadź opis folderu" />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.descriptionInput.info}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Opcja miniatury</Form.Label>
                                            <Form.Control isValid={this.state.formErrors.miniOption.isValid} isInvalid={this.state.formErrors.miniOption.isInvalid} as="select" onChange={this.handleChangeSelect} value={this.state.miniOption} name="miniOption">
                                                <option disabled value="0">Wybierz...</option>
                                                <option value="1">Miniatura standardowa</option>
                                                <option value="2">Miniatura ze zdjęcia</option>
                                            </Form.Control>
                                            <Form.Text className="text-muted">Po dodaniu zdjęć do folderu pojawi się możliwość jednego ze zdjęć jako jego miniatury.</Form.Text>
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.miniOption.info}</Form.Control.Feedback>
                                        </Form.Group>


                                        {this.state.miniOption === 2 &&
                                            <>
                                                <Form.Row>
                                                    <Col sm={12} lg={6}>
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Wybierz zdjęcie</Form.Label>
                                                            <PicUpload thisRef={this} modal={false} />
                                                        </Form.Group>
                                                    </Col>
                                                    {this.state.miniPhoto !== null &&
                                                        <Col sm={12} lg={6}>
                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                <Form.Label>Podgląd miniatury</Form.Label>
                                                                <div align="center" className="profileDiv" onClick={() => { this.setState({ modal_miniPhoto: null }) }} >
                                                                    <img className="profileImg" src={this.state.miniPhoto} />
                                                                    <div class="middle">
                                                                        <div class="centered textProfile">
                                                                            <FontAwesomeIcon icon={faTrash} size="7x" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>}

                                                </Form.Row>
                                            </>}


                                        <Button disabled={this.state.loadingShow} type="submit" variant="primary" block><LatoBold>Dodaj folder</LatoBold></Button>
                                    </Form>
                                    <hr className="mt-4" />
                                    <Card.Title className="mt-4"><Lato>Lista folderów</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.folders}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak folderów!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Usuwanie drużyny</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć drużynę <strong>{this.state.modalDelete.label}</strong>?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.show2} onHide={() => { this.handleClose2() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Szczegóły folderu</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    {this.state.modalPreview.loading ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Opis</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.editDescriptionInput.isValid} isInvalid={this.state.formErrors.editDescriptionInput.isInvalid} onChange={this.handleChange} value={this.state.editDescriptionInput} name="editDescriptionInput" as="textarea" rows="3" placeholder="Wprowadź opis folderu" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.editDescriptionInput.info}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Opcje miniatury</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.editMiniOption.isValid} isInvalid={this.state.formErrors.editMiniOption.isInvalid} as="select" onChange={this.handleChangeSelect} value={this.state.editMiniOption} name="editMiniOption">
                                                        <option disabled value="0">Wybierz...</option>
                                                        <option value="1">Miniatura standardowa</option>
                                                        <option value="2">Miniatura z wgranego zdjęcia</option>
                                                        <option value="3">Miniatura z przypisanego zdjęcia</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.editMiniOption.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Form>

                                            {this.state.editMiniOption === 2 &&
                                                <>
                                                    {this.state.modal_miniPhoto === null ?
                                                        <PicUpload thisRef={this} modal={true} />
                                                        :
                                                        <>
                                                            <Form.Group>
                                                                <Form.Label>Podgląd miniatury</Form.Label>
                                                                <div align="center">
                                                                    <img styles={{ width: "146px" }} src={this.state.modal_miniPhoto} /><br />
                                                                    <Button className="mt-2" onClick={() => { this.setState({ modal_miniPhoto: null }) }} variant="danger">Usuń miniaturę</Button>
                                                                </div>
                                                            </Form.Group>
                                                        </>}
                                                </>}
                                            {this.state.editMiniOption === 3 &&

                                                <>
                                                    <Select styles={customStyles} isSearchable={true} value={this.state.modal_miniSelected} placeholder={"Wybierz..."} options={this.state.modal_miniSelectOptions} isClearable={true} onChange={(dd) => this.setState({ modal_miniSelected: dd })} noOptionsMessage={(dd) => {
                                                        if (dd.inputValue !== "") {
                                                            return "Nie znaleziono: " + dd.inputValue
                                                        } else {
                                                            return "Brak zdjęć w folderze!";
                                                        }
                                                    }} />
                                                </>}

                                        </>
                                    }
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose2() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.updateFolder(this.state.modalPreview.value) }} variant="primary"><LatoBold>Zapisz</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner >
        );
    }
}

class PicUpload extends React.Component {
    constructor(props) {
        super(props);
        this.inputFile = React.createRef(null);
    }

    readFile1 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        })
    }

    readFile2 = event => {
        const blob2 = window.btoa(event.target.result);
        const blob = b64toBlob(blob2, 'image/heic');
        const thisRef = this.props.thisRef;
        heic2any({ blob }).then((conversionResult) => {
            const url = URL.createObjectURL(conversionResult);
            if (this.props.modal === false)
                this.props.thisRef.setState({ miniUploadingInProgress: false, miniPhoto: url });
            else
                this.props.thisRef.setState({ modal_miniUploadingInProgress: false, modal_miniPhoto: url });
        }).catch((e) => {
            toast.error(e);
        });
    }

    onClick() {
        this.inputFile.current.value = null;
        this.inputFile.current.click();
    }

    async onFileChange(e) {
        if (e.files && e.files.length > 0) {
            if (this.props.modal === false)
                this.props.thisRef.setState({ miniUploadingInProgress: true });
            else
                this.props.thisRef.setState({ modal_miniUploadingInProgress: true });

            if (validate(e.files[0])) {
                let file = e.files[0];

                if (file.name.toLowerCase().split('.').pop() === 'heic') {
                    var reader = new FileReader();
                    reader.addEventListener('load', this.readFile2);
                    reader.readAsBinaryString(file);
                } else {
                    const options = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }

                    try {
                        const fileComp = await imageCompression(file, options);
                        let imageDataUrl = await this.readFile1(fileComp);
                        if (this.props.modal === false)
                            this.props.thisRef.setState({ miniUploadingInProgress: false, miniPhoto: imageDataUrl });
                        else
                            this.props.thisRef.setState({ modal_miniUploadingInProgress: false, modal_miniPhoto: imageDataUrl });
                    } catch (error) {
                        toast.error(error);
                    }
                }
            }
        } else {
            toast.error("Błąd przesyłania");
        }
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Container
                    fluid
                    style={{ cursor: 'pointer', backgroundColor: '#EBEDED', padding: 40, height: 182, border: 'thin dashed black' }}
                    onClick={() => this.onClick()}
                >

                    <FileDrop
                        onDrop={(files, event) => { this.inputFile.current.value = null; this.onFileChange({ files: files }); }}
                    >
                        Wybierz lub upuść zdjęcia tutaj
                        <img style={{ width: 100 }} src="./../../img/icons/drop_icon.svg" />
                        <Form.Text muted>Akceptowane formaty: jpg, png, bmp, jpeg, heic</Form.Text>
                    </FileDrop>
                    <input type='file' id='file' multiple ref={this.inputFile} onChange={(e) => this.onFileChange(e.target)} accept=".jpg,.png,.bmp,.jpeg,.heic" style={{ display: 'none' }} />
                </Container>
            </>
        );
    }
}

function isFileImage(file) {
    const acceptedImageTypes = ['jpeg', 'png', 'bmp', 'jpg', 'heic'];

    return file && acceptedImageTypes.includes(file.name.toLowerCase().split('.').pop());
}

function validate(input) {
    if (input) {
        if (isFileImage(input)) {
            return true;
        } else {
            toast.error("Niewłaściwy format obrazka");
            return false;
        }
    } else {
        toast.error("Błąd przesyłania obrazka");
        return false;
    }
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export default AdminFolders;