import React from "react";
import { Container, Button } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/Contact.css';
import Lato from "../components/Lato";

class Documents extends React.Component {

    render() {

        return (
            <>
                <Header />
                <Container className="container">
                    <h2><Lato>Dokumenty</Lato></h2>
                    <a href="/files/CAP_Regulamin.pdf" target="_blank"><Button variant="outline-primary" className="mr-2 mt-1">Regulamin</Button></a>
                    <a href="/files/CAP_Polityka.pdf" target="_blank"><Button variant="outline-primary" className="mr-2 mt-1">Polityka Prywatności</Button></a>
                    <a href="/files/CAP_Klauzula.pdf" target="_blank"><Button variant="outline-primary" className="mt-1">Klauzula informacyjna</Button></a>
                </Container>
            </>
        );
    }
}

export default Documents;