import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Tags.module.css';

class Tags extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                {
                    this.props.tags.map((item) => {
                        return <Link to={"/tag/" + item.name} className={styles.link}><strong>#{item.name}</strong></Link>
                    })
                }
            </div>
        )
    }
}


export default Tags;