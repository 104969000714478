import React, { Component } from 'react';
import me from '../scripts/me';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';

class RequireLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            body: null
        };
    }

    authFailed() {
        const cookies = new Cookies();
        cookies.remove('rank', { path: '/' });
        cookies.remove('nick', { path: '/' });
        cookies.remove('uid', { path: '/' });
        cookies.remove('amentLogin', { path: '/' });
        if (this.props.anonymous === undefined) {
            this.props.history.push({ pathname: '/logowanie' });
        } else {
            this.setState({ loading: false, body: this.props.anonymous });
        }
    }

    componentDidMount() {
        const cookies = new Cookies();
        cookies.set('redirect', this.props.location.pathname, { path: '/' });
        let lvl = 1;

        if (this.props.level === 2) {
            lvl = 2;
        } else if (this.props.level === 3) {
            lvl = 3;
        }

        me().then((self) => {
            if (self.response === 1) {
                if (self.rank >= lvl) {
                    this.setState({ loading: false });
                } else {
                    this.authFailed();
                }
            } else if (self.response === 0) {
                this.authFailed();
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=RequireLogin&no=' + self.status + '&text=' + self.text + '&url=auth.php'
                });
            }
        });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                {this.state.body === null && !this.state.loading ? this.props.children : this.state.body}
            </LoadingSpinner>
        )
    }
}

export default withRouter(RequireLogin);