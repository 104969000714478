import React from "react";
import RequireLogin from '../components/RequireLogin';
import IndexUser from '../components/IndexUser';
import IndexAnonymous from '../components/IndexAnonymous';
import '../styles/Index.css';

class Index extends React.Component {
    render() {
        return (
            <RequireLogin anonymous={
                <IndexAnonymous />
            }>
                <IndexUser />
            </RequireLogin>
        );
    }
}

export default Index;