import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Element, Events } from 'react-scroll';
import NewComment from '../components/NewComment';
import styles from '../styles/SingleComment.module.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import http_post from '../scripts/post';
import LatoBold from './LatoBold';
import Lato from './Lato';

class Replies extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showReport: false,
            formErrors: {
                reportInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            reportInput: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetValid = this.resetValid.bind(this);

    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    resetValid() {
        let formErrors = this.state.formErrors;
        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })
        this.setState({
            formErrors: formErrors,
            reportInput: ""
        })
    }

    handleSubmit(event) {

        let valid = true;
        const { reportInput, formErrors } = this.state;

        if (reportInput <= 0) {
            formErrors.reportInput.info = "Uzupełnij treść zgłoszenia!";
            formErrors.reportInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.reportInput.isValid = true;
            formErrors.reportInput.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {
            this.setState({ loadingShow: true });
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                report: reportInput,
                cid: this.props.comment.id,
                token: token
            }

            http_post(`reportComment.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ loadingShow: false, showReport: false });
                        toast.success("Twoje zgłoszenie zostało przesłane!");
                        this.resetValid();
                    }
                } else {
                    window.location.reload();
                }
            })

        } else {

            toast.error("Uzupełnij poprawnie formularz!");
            event.stopPropagation();
        }

        event.preventDefault();

    }

    render() {

        return (
            <>
                <Element name={"comment_" + this.props.comment.id}>
                    <Row className={styles.rowMargin}>
                        <Col xs={12} xl={1}>
                            <img src={"data:image/jpeg;base64, " + this.props.comment.avatar} className={styles.avatar} />
                        </Col>
                        <Col xs={12} xl={11} className={styles.colPadding}>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <strong class="nick">{this.props.comment.nick}</strong>
                                </div>
                                <div>
                                    <div class="text-muted">{this.props.comment.added}</div>
                                </div>
                            </div>
                            <p class="text-muted" className={styles.commentContent}>
                                {this.props.comment.content}
                            </p>
                            <p class="text-muted">
                                <Link onClick={() => { this.setState({ showReport: true }) }} className={styles.commentLink}><strong>Zgłoś</strong></Link>
                                <Modal show={this.state.showReport} onHide={() => { this.setState({ showReport: false }) }}>
                                    <Form noValidate onSubmit={this.handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title><Lato>Zgłoś odpowiedź</Lato></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {this.state.loadingShow ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="border" variant="secondary" />
                                                </div>
                                                :
                                                <>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Treść zgłoszenia</Form.Label>
                                                        <Form.Control isValid={this.state.formErrors.reportInput.isValid} isInvalid={this.state.formErrors.reportInput.isInvalid} onChange={this.handleChange} value={this.state.reportInput} name="reportInput" as="textarea" rows="3" placeholder="Wprowadź treść zgłoszenia" />
                                                        <Form.Text muted>
                                                            Możesz zgłosić do administratorów niezgodność tego komentarza z regulaminem.
                                                            </Form.Text>
                                                        <Form.Control.Feedback type="invalid">{this.state.formErrors.reportInput.info}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="outline-primary" onClick={() => { this.setState({ showReport: false }) }}><LatoBold>Anuluj</LatoBold></Button>
                                            <Button disabled={this.state.loadingShow} type="submit" variant="primary"><LatoBold>Wyślij</LatoBold></Button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>
                            </p>
                        </Col>
                    </Row>
                </Element>
            </>

        )
    }

}


export default Replies;