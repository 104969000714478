import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/YearContainer.module.css'
import Lato from "../components/Lato";

class YearContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: null
        };
    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`gallery.php?year=` + this.props.match.params.year, data).then((res) => {
            if (res.status === 200) {
                if (res.data === 'empty')
                    this.props.history.push({ pathname: '/galeria' });
                else
                    this.setState({ data: res.data, loading: false });
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=YearContainer&no=' + res.status + '&text=' + res.text + '&url=gallery.php?year=' + this.props.match.params.year
                });
            }
        });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin>
                    <Header />
                    {
                        this.state.data !== null ? (
                            <Container className="container">
                                <Row>
                                    <Col xl={12}>
                                        <Navbar bg="light" className="breadcrumbNav">
                                            <div className="breadcrumbImage mx-auto order-0">
                                                <Breadcrumb.Item align="center" href="/galeria">Galeria</Breadcrumb.Item>
                                                <Breadcrumb.Item active>{this.props.match.params.year}</Breadcrumb.Item>
                                            </div>
                                        </Navbar>
                                    </Col>
                                </Row>

                                <Card className={styles.yearContent}>
                                    <Card.Header><Lato>FOLDERY Z TEGO ROKU</Lato></Card.Header>
                                    <Card.Body>
                                        <Row className="align-iteams-center flex-wrap">
                                            {
                                                this.state.data.map((folder) => {
                                                    return (
                                                        <Col xs={6} md={4} xl={2} className="colFolder" align="center">
                                                            <Link to={"/folder/" + folder.id} className="colLink">
                                                                <img src={folder.mini} className="foldersImg" />
                                                                <div class="colLinkText">{folder.title}</div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Container>
                        ) : <></>
                    }
                    
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default YearContainer;