import React from "react";
import { Button, Row, Carousel, Col, Jumbotron, Container, Spinner, Form } from 'react-bootstrap';
import Ticker from '../components/Ticker';
import Header from '../components/Header';
import Footer from '../components/Footer';
import http_post from '../scripts/post';
import LatoBold from '../components/LatoBold';
import CookieNotice from '../components/cookies-notice/src/index';
import '../styles/Index.css';

class IndexAnonymous extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stat1: <Spinner animation="border" variant="secondary" />,
            stat2: <Spinner animation="border" variant="secondary" />,
            stat3: <Spinner animation="border" variant="secondary" />
        };
    }

    componentDidMount() {
        http_post(`stats.php`).then((res) => {
            if (res.status === 200) {
                this.setState({
                    stat1: <p className="numbersHeader"><Ticker end={res.data.photos} duration={3} separator=" " /></p>,
                    stat2: <p className="numbersHeader"><Ticker end={res.data.memories} duration={3} separator=" " /></p>,
                    stat3: <p className="numbersHeader"><Ticker end={res.data.users} duration={3} separator=" " /></p>
                });
            } else {
                this.setState({
                    stat1: <p className="numbersHeader">—</p>,
                    stat2: <p className="numbersHeader">—</p>,
                    stat3: <p className="numbersHeader">—</p>,
                });
            }
        })
    }

    render() {
        return (
            <>
                <Header />
                <Container fluid>
                    <div className="colorControl">
                        <Row className="d-flex flex-wrap align-items-center" style={{ backgroundColor: "#E9ECEF" }}>
                            <Col sm={12} xl={6} className="colControl">
                                <Carousel style={{ width: "100%", height: "100%" }}>
                                    <Carousel.Item> <img className="imgCarousel" src="./files/carousel/1.png" /> </Carousel.Item>
                                    <Carousel.Item> <img className="imgCarousel" src="./files/carousel/2.png" /> </Carousel.Item>
                                    <Carousel.Item> <img className="imgCarousel" src="./files/carousel/3.png" /> </Carousel.Item>
                                    <Carousel.Item> <img className="imgCarousel" src="./files/carousel/4.png" /> </Carousel.Item>
                                    <Carousel.Item> <img className="imgCarousel" src="./files/carousel/5.png" /> </Carousel.Item>
                                    <Carousel.Item> <img className="imgCarousel" src="./files/carousel/7.png" /> </Carousel.Item>
                                </Carousel>
                            </Col>
                            <Col sm={12} xl={6} className="colControl">
                                <Jumbotron className="jumboControl">

                                    <h1><LatoBold>Odkrywajmy wspólnie historię <a style={{ color: '#f77f00' }}>Pomarańczarni!</a></LatoBold></h1>

                                    <p>
                                        Harcerkom i harcerzom Pomarańczarni od zawsze towarzyszył obiektyw aparatu.
                                        Kolejne lata historii naszego środowiska niestrudzenie dokumentowali harcerscy fotografowie i fotografki.
                                        Do przodu szła technika fotograficzna, a liczba zdjęć nieustannie rosła.
                                        Część z nich po drodze przepadła, ale ogromne zasoby fotograficznych pamiątek przechowywali ich autorzy, osoby sportretowane, kronikarze drużyn i osoby zgłębiające historię harcerek i harcerzy w pomarańczowych chustach.
                                        Dziś wszystkie pamiątki znajdą wspólne miejsce.
                                    </p>

                                    <hr />

                                    <p className="text-muted" >
                                        Z okazji setnych urodzin Pomarańczarni, Harcerska Fundacja Turystyczna “Pomarańczarni” uruchomiła Cyfrowe Archiwum - internetowe repozytorium zdjęć i dokumentów środowiska, obejmujące wszystkie jego drużyny i wszystkie lata działania.
                                        Do założenia konta zachęcamy przedstawicieli wszystkich pokoleń Pomarańczarni, a także sympatyków naszego Szczepu i jego historii.
                                        Niezależnie, czy szukasz historycznych archiwaliów, zdjęć znajomych z obozów w latach 80’, czy też relacji z ostatnich akcji Szczepu - to miejsce dla Ciebie!
                                </p>
                                    <hr />
                                    <Form.Row className="align-items-center">
                                        <Col xs="auto">
                                            <Button href="/logowanie" variant="primary" ><LatoBold>Zaloguj się</LatoBold></Button>
                                        </Col>
                                        <Col xs="auto">
                                            LUB
                                        </Col>
                                        <Col xs="auto">
                                            <Button href="/rejestracja" variant="primary"><LatoBold>Utwórz konto</LatoBold></Button>
                                        </Col>
                                    </Form.Row>

                                </Jumbotron>

                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} style={{ backgroundColor: "#f77f00", padding: "20px" }}>

                            </Col>
                        </Row>

                        <Row className="align-items-center numbersRow">
                            <Col sm={12} xl={9}>
                                <Row>
                                    <Col sm={12} xl={4}>
                                        {this.state.stat1}
                                        <p className="numbersText">skatalogowanych zdjęć</p>
                                    </Col>
                                    <Col sm={12} xl={4}>
                                        {this.state.stat2}
                                        <p className="numbersText">wspomnień i komentarzy</p>
                                    </Col>
                                    <Col sm={12} xl={4}>
                                        {this.state.stat3}
                                        <p className="numbersText">zarejestrowanych użytkowników</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} >
                                        <div className="text-left">
                                            Harcerska Fundacja Turystyczna "Pomarańczarni" 2020. Wszelkie prawa zastrzeżone.
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} xl={3} className="contactCol">
                                <p>
                                    <strong>Harcerska Fundacja Turystyczna "Pomarańczarni"</strong><br />
                                    ul. Myśliwiecka 6<br />
                                    00-469 Warszawa<br />
                                    NIP: 526-16-70-446<br />
                                    KRS: 0000211613<br />
                                    REGON: 010824220<br />
                                    fundacja@pomaranczarnia.org<br />
                                    {/*<Button style={{ marginTop: "2vh" }} variant="success">Wesprzyj nasze działania</Button>*/}
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <CookieNotice
                        acceptButtonLabel="OK"
                        readMoreButtonLabel="Polityka prywatności"
                        openInNewTab={true}
                        cookieTextLabel='Pliki cookies - Szanujemy Twoją prywatność. Informujemy, że w celu prawidłowego działania strony korzystamy z plików cookies zapisywanych na Twoim urządzeniu końcowym. Używamy cookies wymaganych oraz analitycznych - w celu zbierania statystyk o ruchu na stronie. Nie używamy cookies w celach marketingowych. Cookies możesz kontrolować w ustawieniach przeglądarki. Dalsze korzystanie ze strony bez zmiany ustawień oznacza zgodę na stosowanie plików cookies.'
                        reverseButtons={true}
                        borderRadius={12}
                        justifyContent="space-between"
                        maxWidth={9999}
                        cookieExpiration={365}
                    />
                </Container>

            </>
        )
    }
}

export default IndexAnonymous;