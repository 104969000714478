import React, { Component } from 'react';
import LoadingScreen from 'react-loading-screen'

class LoadingSpinner extends Component {
    render() {
        return (
            <LoadingScreen
                loading={this.props.loading}
                bgColor='#f1f1f1'
                spinnerColor='#f77f00'
                textColor='#676767'
                logoSrc='/img/icons/archiwum_logo_blank.png'
                text='Ładowanie...'
                style={{borderRadius: "unset"}}
            >
                {this.props.children}
            </LoadingScreen>
        )
    }
}

export default LoadingSpinner;