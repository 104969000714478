import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Button, Badge, Form, FormControl, Modal, Col, Toast, Row, ButtonGroup, ButtonToolbar, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import '../styles/AdminHeader.css';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LatoBold from './LatoBold';

function AdminHeader(props) {

    const cookies = new Cookies();
    const rank = cookies.get('rank');
    const nick = cookies.get('nick');
    const token = cookies.get('token');

    return (
        <Navbar fixed="top" collapseOnSelect expand="lg" style={{ backgroundColor: "#f77f00", marginBottom: "56px" }}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand href="/" style={{ color: "white" }}>
                <img
                    alt=""
                    src="https://archiwum.pomaranczarnia.org/img/icons/archiwum_logo_w_on_trans.png"
                    height="30px"
                    className="d-inline align-top"
                />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
                {
                    rank === "2" && (
                        <Nav className="mr-auto">
                            <Nav.Link href="/administrator">Podsumowanie</Nav.Link>
                            <Nav.Link href="/administrator/aktualnosci">Aktualności</Nav.Link>
                            <NavDropdown title="Zdjęcia" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/administrator/zdjecia/dodawanie"><strong>Dodaj zdjęcia</strong></NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia">Lista</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia/zgloszenia">Zgłoszenia</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/administrator/zdjecia/lata">Lata</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia/foldery">Foldery</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/administrator/zdjecia/tagi">Tagi</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia/druzyny">Drużyny</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    )
                }{
                    rank === "3" && (
                        <Nav className="mr-auto">
                            <Nav.Link href="/administrator">Podsumowanie</Nav.Link>
                            <Nav.Link href="/administrator/aktualnosci">Aktualności</Nav.Link>
                            <NavDropdown title="Użytkownicy" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/administrator/uzytkownicy">Lista</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/uzytkownicy/blokady">Blokady</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Komentarze" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/administrator/komentarze">Lista</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/komentarze/zgloszenia">Zgłoszenia</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/administrator/komentarze/slownik">Słownik filtrowania</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Zdjęcia" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/administrator/zdjecia/dodawanie"><strong>Dodaj zdjęcia</strong></NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia">Lista</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia/zgloszenia">Zgłoszenia</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/administrator/zdjecia/lata">Lata</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia/foldery">Foldery</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/administrator/zdjecia/tagi">Tagi</NavDropdown.Item>
                                <NavDropdown.Item href="/administrator/zdjecia/druzyny">Drużyny</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Administracja" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/administrator/zdarzenia">Zdarzenia</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/administrator/ustawienia">Ustawienia</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    )
                }

                <Nav>
                    &nbsp;
                    <Button href="/profil" variant="secondary"><LatoBold>{nick}</LatoBold></Button>
                    &nbsp;
                    <Button href="/wyloguj" variant="light"><LatoBold>Wyloguj</LatoBold></Button>
                    &nbsp;
                    <Button href="/" variant="danger"><FontAwesomeIcon icon={faSignOutAlt} /></Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}


export default AdminHeader;