import Cookies from 'universal-cookie';
import http_post from '../scripts/post';

function remove_cookies(cookies) {
    cookies.remove('uid', { path: '/' });
    cookies.remove('token', { path: '/' });
    cookies.remove('rank', { path: '/' });
    cookies.remove('nick', { path: '/' });
    cookies.remove('amentLogin', { path: '/' });
}

async function me() {
    const cookies = new Cookies();
    const uid = cookies.get('uid');
    const token = cookies.get('token');
    const nick = cookies.get('nick');
    const rank = cookies.get('rank');

    if (uid === undefined || token === undefined || nick === undefined || rank === undefined) {
        remove_cookies(cookies);
        return { response: 0, details: 'Sesja wygasła' };
    }

    const request = {
        uid: uid,
        token: token
    };

    const res = await http_post(`auth.php`, request);

    if (res.status === 200) {
        if (res.data.valid === 1) {
            if(cookies.get('amentLogin') === 'true') {
                let oneYearFromNow = new Date();
                oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                cookies.set('uid', res.data.uid, { path: '/', expires: oneYearFromNow });
                cookies.set('token', res.data.token, { path: '/', expires: oneYearFromNow });
                cookies.set('rank', res.data.rank, { path: '/', expires: oneYearFromNow });
                cookies.set('nick', res.data.nick, { path: '/', expires: oneYearFromNow });
                cookies.set('amentLogin', true, { path: '/', expires: oneYearFromNow });
            } else {
                cookies.set('uid', res.data.uid, { path: '/' });
                cookies.set('token', res.data.token, { path: '/' });
                cookies.set('rank', res.data.rank, { path: '/' });
                cookies.set('nick', res.data.nick, { path: '/' });
            }
            return { response: 1, rank: res.data.rank };
        } else {
            remove_cookies(cookies);
            return { response: 0, details: res.data.details };
        }
    } else {
        remove_cookies(cookies);
        return { response: -1, status: res.status, text: res.text };
    }
}

export default me;