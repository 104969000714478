import React from 'react';
import { Breadcrumb, Button, Navbar, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import '../styles/ImageBreadcrumbs.css';
import LatoBold from './LatoBold';

class ImageBreadcrumbs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        this.props.data.then((res) => {
            this.setState({ data: res.data[0] })
        });
    }

    render() {
        if (this.state.data === null)
            return <></>
        else
            return (

                <>
                    <Row>
                        <Col xl={12}>
                            <Navbar bg="light" className="breadcrumbNav">
                                <div class="mr-auto">
                                    <Button variant="outline-primary" disabled={this.state.data.id1 === null ? true : false} href={"/galeria/" + this.state.data.id1}>
                                        <FontAwesomeIcon style={{ marginLeft: "10px", marginRight: "10px" }} icon={faChevronLeft} /><span className="d-none d-md-inline"><LatoBold>Poprzednie</LatoBold></span> <span className="d-none d-lg-inline"><LatoBold>zdjęcie</LatoBold></span>
                                    </Button>
                                </div>
                                <div className="breadcrumbImage mx-auto order-0">
                                    <span className="fakeBreadcrumb d-none d-md-none d-lg-flex"><Breadcrumb.Item align="center" href="/galeria">Galeria</Breadcrumb.Item></span>
                                    <span className="fakeBreadcrumb d-none d-md-none d-lg-flex"><Breadcrumb.Item align="center" href={"/rok/"+this.state.data.year}>{this.state.data.year}</Breadcrumb.Item></span>
                                    <Breadcrumb.Item align="center" href={"/folder/"+this.state.data.fid}>{this.state.data.folder}</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{this.state.data.no} z {this.state.data.countF}</Breadcrumb.Item>
                                </div>
                                <div class="ml-auto">
                                    <Button variant="outline-primary" disabled={this.state.data.id2 === null ? true : false} href={"/galeria/" + this.state.data.id2}>
                                        <span className="d-none d-md-inline"><LatoBold>Następne</LatoBold></span> <span className="d-none d-lg-inline"><LatoBold>zdjęcie</LatoBold></span><FontAwesomeIcon style={{ marginLeft: "10px", marginRight: "10px" }} icon={faChevronRight} />
                                    </Button>
                                </div>
                            </Navbar>
                        </Col>
                    </Row>
                </>

            );
    }
}


export default ImageBreadcrumbs;