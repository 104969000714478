import React from "react";
import RequireLogin from '../components/RequireLogin';
import AdminHeader from '../components/AdminHeader';
import Ticker from '../components/Ticker';
import http_post from '../scripts/post';
import { Container, Row, Col, Jumbotron, Spinner } from 'react-bootstrap';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart, linearGradient } from 'recharts';
import { Chart } from "react-google-charts";
import Lato from '../components/Lato';

class AdminIndex extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stat1: <Spinner animation="border" variant="secondary" />,
            stat2: <Spinner animation="border" variant="secondary" />,
            stat3: <Spinner animation="border" variant="secondary" />,
            stat4: <Spinner animation="border" variant="secondary" />,
            logins: [],
            registers: []
        };
    }

    componentDidMount() {
        http_post(`stats.php?admin`).then((res) => {
            if (res.status === 200) {
                this.setState({
                    stat1: <p className="numbersHeader"><Ticker end={res.data.photos} duration={3} separator=" " /></p>,
                    stat2: <p className="numbersHeader"><Ticker end={res.data.memories} duration={3} separator=" " /></p>,
                    stat3: <p className="numbersHeader"><Ticker end={res.data.users} duration={3} separator=" " /></p>,
                    stat4: <p className="numbersHeader"><Ticker end={res.data.size * 0.000001} decimals={2} duration={3} separator=" " /></p>,
                    logins: res.data.logins,
                    registers: res.data.registers
                });
            } else {
                this.setState({
                    stat1: <p className="numbersHeader">—</p>,
                    stat2: <p className="numbersHeader">—</p>,
                    stat3: <p className="numbersHeader">—</p>,
                    stat4: <p className="numbersHeader">—</p>,
                });
            }
        })
    }

    render() {
        return (
            <RequireLogin level={2}>
                <AdminHeader />
                <Container>
                    <h2><Lato>Panel administratora</Lato></h2>
                    <Row>
                        <Col sm={12} xl={3}>
                            <Jumbotron style={{ backgroundColor: "white" }}>
                                {this.state.stat1}
                                <p>zdjęcia</p>
                            </Jumbotron>
                        </Col>
                        <Col sm={12} xl={3}>
                            <Jumbotron style={{ backgroundColor: "white" }}>
                                {this.state.stat2}
                                <p>komentarze</p>
                            </Jumbotron>
                        </Col>
                        <Col sm={12} xl={3}>
                            <Jumbotron style={{ backgroundColor: "white" }}>
                                {this.state.stat3}
                                <p>użytkownicy</p>
                            </Jumbotron>
                        </Col>
                        <Col sm={12} xl={3}>
                            <Jumbotron style={{ backgroundColor: "white" }}>
                                {this.state.stat4}
                                <p>GB zajętego miejsca</p>
                            </Jumbotron>
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                    <Row>
                        <Col sm={12} xl={12}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="AreaChart"
                                loader={<div className="text-center">
                                    <Spinner animation="grow" variant="warning" />
                                </div>}
                                data={this.state.logins}
                                options={{
                                    title: 'Statystyka logowania',
                                    hAxis: { title: 'Dni', titleTextStyle: { color: '#333' } },
                                    vAxis: { minValue: 0 },
                                    // For the legend to fit, we make the chart area smaller
                                    chartArea: { width: '70%', height: '70%' },
                                    // lineWidth: 25
                                }}
                                // For tests
                                rootProps={{ 'data-testid': '1' }}
                            />
                        </Col>
                        <Col className="mt-4" sm={12} xl={12}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="AreaChart"
                                loader={<div className="text-center">
                                    <Spinner animation="grow" variant="warning" />
                                </div>}
                                data={this.state.registers}
                                options={{
                                    title: 'Statystyka rejestracji',
                                    hAxis: { title: 'Dni', titleTextStyle: { color: '#333' } },
                                    vAxis: { minValue: 0 },
                                    // For the legend to fit, we make the chart area smaller
                                    chartArea: { width: '70%', height: '70%' },
                                    // lineWidth: 25
                                }}
                                // For tests
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </Col>
                    </Row>
                </Container>
            </RequireLogin>
        );
    }
}

class CustomizedAxisTick extends React.Component {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
            </g>
        );
    }
}

export default AdminIndex;