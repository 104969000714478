import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import http_post from '../scripts/post';
import Select from 'react-select';
import DatePicker from 'react-date-picker'
import MaskedFormControl from 'react-bootstrap-maskedinput';
import LatoBold from './LatoBold';
import Lato from './Lato';

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            textInput: "",
            startDate: null,
            endDate: null,
            multiSelect: null,
            page: 1,
            monthStart: 0,
            monthEnd: 0,
            yearStart: "",
            yearEnd: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.resetSearchForm = this.resetSearchForm.bind(this);

    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`tags.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ options: res.data });

                const searchData = cookies.get('searchData');

                if (searchData !== undefined) {
                    this.setState({
                        textInput: searchData.textInput,
                        startDate: searchData.startDate,
                        endDate: searchData.endDate,
                        multiSelect: searchData.multiSelect
                    }, () => {
                        cookies.remove('searchData', { path: '/' });
                        this.handlerSubmit(null);
                    });
                }

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=tags.php'
                });
            }
        })

    }

    setStartDate(date) {
        this.setState({ startDate: date })
    }

    setEndDate(date) {
        this.setState({ endDate: date })
    }

    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleChangeSelect(event) {
        event.preventDefault();
        switch (event.target.name) {
            default:
                this.setState({
                    [event.target.name]: Number(event.target.value),
                });
                break;
        }
    }

    handlerSubmit(event) {

        const { textInput, yearStart, yearEnd, monthStart, monthEnd, multiSelect } = this.state;

        if (textInput === '' && yearStart === null && monthStart === null && yearEnd === null && monthEnd === null &&  multiSelect === null) {
            toast.error("Uzupełnij co najmniej jedno pole!");
        } else if (textInput !== '' && textInput.length < 3) {
            toast.error("Wpisz co najmniej 3 litery.");
        } else if (textInput !== '' && textInput.length > 30) {
            toast.error("Wpisz co najwyżej 30 znaków.");
        } else {
            const cookies = new Cookies();

            const data = {
                textInput: textInput,
                multiSelect: multiSelect,
                yearStart: yearStart,
                monthStart: monthStart,
                yearEnd: yearEnd,
                monthEnd: monthEnd,
                page: 1
            }

            if (this.props.withRedirect) {
                cookies.set('searchData', { textInput: textInput, yearStart: yearStart, monthStart: monthStart, yearEnd: yearEnd, monthEnd: monthEnd, multiSelect: multiSelect, page: 1 }, { path: '/' });
                window.location.href = "/wyszukiwarka";
            } else {
                this.props.refreshFunction(data)
            }
        }

        if (event != null)
            event.preventDefault();
    }

    resetSearchForm() {
        this.setState({
            textInput: "",
            startDate: null,
            endDate: null,
            multiSelect: null,
            monthStart: 0,
            monthEnd: 0,
            yearStart: "",
            yearEnd: ""
        });
    }

    render() {


        return (
            <>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <ToastContainer />
                            <Card.Header><Lato>WYSZUKIWARKA ZDJĘĆ</Lato></Card.Header>
                            <Card.Body>
                                <Form noValidate onSubmit={this.handlerSubmit}>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Wyszukaj w treści</Form.Label>
                                                <Form.Control type="text" placeholder="Wyszukaj w treści" value={this.state.textInput} name="textInput" onChange={this.handleChange} />
                                                <Form.Text className="text-muted">
                                                    Znajdziemy słowa występujące w opisach zdjęć lub nazwach folderów.
                                            </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Wybierz tagi</Form.Label>
                                                <Select isMulti={true} isSearchable={true} value={this.state.multiSelect} placeholder={"Wybierz tagi..."} options={this.state.options} onChange={(dd) => this.setState({ multiSelect: dd })} noOptionsMessage={(dd) => { return "Nie znaleziono: " + dd.inputValue }} />
                                                <Form.Text className="text-muted">
                                                    Wybierz jeden lub więcej tagów.
                                            </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Od roku</Form.Label>
                                                    <MaskedFormControl type='text' name='yearStart' value={this.state.yearStart} placeholder="Wprowadź rok początkowy" onChange={this.handleChange} mask='1111' />
                                                    <Form.Text className="text-muted"> Wybierz zakres dat wykonania zdjęć. </Form.Text>
                                                </Form.Group>
                                            </Form.Row>
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Do roku</Form.Label>
                                                    <MaskedFormControl type='text' name='yearEnd' value={this.state.yearEnd} placeholder="Wprowadź rok końcowy" onChange={this.handleChange} mask='1111' />
                                                </Form.Group>
                                            </Form.Row>
                                        </Col>
                                    </Row>
                                    <div className="text-right">
                                        <Button className="mr-2" variant="secondary" onClick={() => { this.resetSearchForm() }}><LatoBold>Wyczyść</LatoBold></Button>
                                        <Button type="submit" variant="primary"><LatoBold>Szukaj</LatoBold></Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>

        )
    }

}


export default Search;