import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import Search from '../components/Search';
import { Row, Col, Navbar, Breadcrumb, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/Gallery.module.css';

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: null
        };
    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`gallery.php`, data).then((res) => {
            if (res.status === 200) {
                this.setState({ data: res.data, loading: false });
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=YearContainer&no=' + res.status + '&text=' + res.text + '&url=gallery.php'
                });
            }
        });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin>
                    <Header />
                    {
                        this.state.data !== null ? (
                            <Container className="container">
                                <Search withRedirect={true} />

                                {
                                    this.state.data.map((container) => {
                                        return (
                                            <>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col><Link className={styles.yearLink} to={"rok/" + container.year}>{container.year}</Link></Col>
                                                        </Row>
                                                        <Row className="align-iteams-center flex-wrap">
                                                            {
                                                                container.folders.map((folder) => {
                                                                    return (
                                                                        <Col xs={6} md={4} xl={2} className="colFolder" align="center">
                                                                            <Link to={"/folder/" + folder.id} className="colLink">
                                                                                <img src={folder.mini} className="foldersImg" />
                                                                                <div class="colLinkText">{folder.title}</div>
                                                                            </Link>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                        {
                                                            container.countFolders > 6 ?
                                                                (
                                                                    <div class="text-right">
                                                                        <Button href={"/rok/" + container.year} variant="warning" className="button" styles={{marginTop: "2px"}}>Przeglądaj wszystkie katalogi</Button>
                                                                    </div>
                                                                )
                                                                :
                                                                <></>
                                                        }
                                                    </Card.Body>
                                                </Card>

                                            </>
                                        )
                                    })
                                }
                            </Container>
                        ) : <></>
                    }
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default Gallery;