import React from "react";
import { Card, Button, Row, Col, Form, Spinner, Container, Table, Alert } from 'react-bootstrap';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RequireLogin from '../components/RequireLogin';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import ProfilePicturePicker from '../components/ProfilePicturePicker';
import '../styles/Profile.css';
import 'react-toastify/dist/ReactToastify.css';
import LatoBold from "../components/LatoBold";
import Lato from "../components/Lato";


class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            page: 0,
            liked: null,
            loadingShow: true,
            switch1: false,
            switch2: false,
            switch3: false,
            switch4: false,
            switch5: false,
            switch6: false,
            switch7: false,
            switch8: false,
            switch9: false,
            switch91: false,
            passwordInput: "",
            passwordInput2: "",
            passwordInput3: "",
            nickInput: "",
            nickOptions: null,
            displayedName: "",
            memberOf: null,
            myTeams: [],
            teamIdInput: "Wybierz drużynę...",
            teamNameInput: "",
            teamYearFromInput: "Wybierz rok...",
            teamYearByInput: "Wybierz rok początkowy...",
            teams: <option disabled>Wybierz...</option>,
            teamFormErrors: {
                teamNameInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                teamYearFromInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                teamYearByInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            image: null,
            formErrors: {
                passwordInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                passwordInput2: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                passwordInput3: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                nickInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                nickOptions: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                memberOf: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
        };

        this.handlerPage = this.handlerPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.showError = this.showError.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
        this.handleNickOptions = this.handleNickOptions.bind(this);
        this.displayName = this.displayName.bind(this);
        this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
        this.handleAddTeam = this.handleAddTeam.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.resetTeamForm = this.resetTeamForm.bind(this);
        this.resetTeamValid = this.resetTeamValid.bind(this);
        this.handleEditProfileSubmit = this.handleEditProfileSubmit.bind(this);
        this.handleMailingSubmit = this.handleMailingSubmit.bind(this);

    }

    componentDidMount() {
        this.updateProfile();
    }

    updateProfile() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        http_post(`profile.php`, { token: token }).then((res) => {
            if (res.status === 200) {

                shuffleArray(res.data.liked);
                const lik = res.data.liked.map((item) => {
                    return (
                        <Col xs={6} md={4} xl={2} className="colFolder" align="center">
                            <Link to={"/galeria/" + item.id} className="colLink">
                                <img src={item.mini} className="foldersImg" />
                                <div class="colLinkTextImg">{item.title}</div>
                            </Link>
                        </Col>
                    )
                })
                this.setState({ data: res.data, liked: lik, loading: false, loadingShow: false });

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Profile&no=' + res.status + '&text=' + res.text + '&url=profile.php'
                });
            }
        })
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        if (name == "nickInput") {
            this.setState({ [name]: value }, () => { this.displayName(this.state.nickOptions) });
            if (!value) if (this.state.nickOptions == "3" || this.state.nickOptions == "4") this.setState({ nickOptions: 1 }, () => { this.displayName("1") });
        } else {
            this.setState({ [name]: value });
        }


    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    showError(show = true, error) {

        const { resultError } = this.state;
        if (!show) resultError.status = show;
        else {
            resultError.status = show;
            resultError.info = error;
        }
        this.setState({ resultError: resultError, loadingShow: false });

    }

    handleChangePassword(event) {

        let valid = true;
        const { passwordInput, passwordInput2, passwordInput3, formErrors } = this.state;
        const passwordRegex = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);

        if (passwordInput <= 0) {
            formErrors.passwordInput.info = "Uzupełnij aktualne hasło!";
            formErrors.passwordInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.passwordInput.isValid = true;
            formErrors.passwordInput.isInvalid = false;
            if (passwordInput2 <= 0) {
                formErrors.passwordInput2.info = "Uzupełnij nowe hasło!";
                formErrors.passwordInput2.isInvalid = true;
                valid = false;
            } else {
                if (passwordRegex.test(passwordInput2)) {
                    formErrors.passwordInput2.isValid = true;
                    formErrors.passwordInput2.isInvalid = false;
                    if (passwordInput3 <= 0) {
                        formErrors.passwordInput3.info = "Powtórz nowe hasło!";
                        formErrors.passwordInput3.isInvalid = true;
                        valid = false;
                    } else {
                        if (passwordInput2 != passwordInput3) {
                            formErrors.passwordInput3.info = "Powtórzone nowe hasło nie jest identyczne!";
                            formErrors.passwordInput3.isInvalid = true;
                            valid = false;
                        } else {
                            formErrors.passwordInput3.isValid = true;
                            formErrors.passwordInput3.isInvalid = false;
                        }
                    }

                } else {
                    formErrors.passwordInput2.info = "Twoje nowe hasło nie spełnia wymagań złożoności!";
                    formErrors.passwordInput2.isInvalid = true;
                    valid = false;
                }
            }
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            this.setState({ loadingShow: true });
            const cookies = new Cookies();
            const token = cookies.get('token');

            const data = {
                token: token,
                oldPassword: passwordInput,
                newPassword: passwordInput2,
            }

            http_post(`password.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        toast.success("Hasło zostało zmienione!");
                        this.handlerPage(0);
                    } else if (res.data.valid === 0) {
                        toast.error("Nie udało się zmienić hasła!");
                        this.setState({ loadingShow: false });
                        this.resetValid();
                    }
                } else {
                    window.location.reload();
                }
            })

        } else event.stopPropagation();
        event.preventDefault();
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors
        })
    }

    handlerPage(page) {

        switch (page) {

            case 0:
                this.setState({ loadingShow: true }, () => { this.setState({ page: page }, () => { this.updateProfile() }) })
            case 1:
                this.resetValid();
                this.setState({ passwordInput: "", passwordInput2: "", passwordInput3: "" }, () => { this.setState({ page: page }); })
                break;
            case 2:
                const cookies = new Cookies();
                const token = cookies.get('token');
                const data = {
                    token: token
                }
                http_post(`profile_mailing.php`, data).then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            switch1: (res.data[0].n_all === "1" ? true : false),
                            switch2: (res.data[0].n1 === "1" ? true : false),
                            switch3: (res.data[0].n2 === "1" ? true : false),
                            switch4: (res.data[0].n3 === "1" ? true : false),
                            switch5: (res.data[0].n4 === "1" ? true : false),
                            switch6: (res.data[0].n5 === "1" ? true : false),
                            switch7: (res.data[0].n6 === "1" ? true : false),
                            switch8: (res.data[0].n7 === "1" ? true : false),
                            switch9: (res.data[0].n8 === "1" ? true : false)
                        }, () => { this.setState({ page: page, loadingShow: false }); })
                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Register&no=' + res.status + '&text=' + res.text + '&url=profile_mailing.php'
                        });
                    }
                })
            case 3:
                let displayedName;
                switch (this.state.data.display) {
                    case "0":
                        displayedName = this.state.data.firstName + " " + this.state.data.lastName;
                        break;
                    case "1":
                        displayedName = this.state.data.firstName + " " + this.state.data.lastName + " (" + this.state.data.maiden + ")"
                        break;
                    case "2":
                        displayedName = this.state.data.firstName + " " + this.state.data.lastName + " (" + this.state.data.nick + ")"
                        break;
                    case "3":
                        displayedName = this.state.data.nick;
                        break;
                }
                http_post(`teams.php`).then((res) => {
                    if (res.status === 200) {
                        let t = [];
                        t.push(<option disabled>Wybierz drużynę...</option>);
                        res.data.map((prop) => {
                            t.push(<option value={prop.id}>{prop.name}</option>);
                        })
                        let y1 = [];
                        y1.push(<option disabled>Wybierz rok...</option>)
                        for (var i = 1969; i <= (new Date().getFullYear()); i++) {
                            y1.push(<option value={i}>{i}</option>);
                        }
                        let y2 = [];
                        y2.push(<option disabled>Wybierz rok początkowy...</option>);
                        this.setState({ loading: false, teams: t, yearsFrom: y1, yearsBy: y2 });
                        this.resetTeamValid()
                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Register&no=' + res.status + '&text=' + res.text + '&url=teams.php'
                        });
                    }
                    this.setState({ nickOptions: parseInt(this.state.data.display) + 1, nickInput: this.state.data.nick, displayedName: displayedName, switch91: (this.state.data.newsletter == 0 ? false : true), memberOf: (this.state.data.teams.length != 0 ? "1" : "2"), myTeams: this.state.data.teams, image: this.state.data.avatar }, () => { this.setState({ page: page }); })
                })

                break;


        }



    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    handleNickOptions(event) {
        let id = event.target.value;
        this.resetValid();
        switch (id) {

            case "1":
                this.setState({ nickOptions: id }, () => { this.displayName(id) })
                break;
            case "2":
                if (this.state.data.maiden != "") {
                    this.setState({ nickOptions: id }, () => { this.displayName(id) })
                } else {
                    let { formErrors } = this.state;
                    formErrors.nickOptions.info = "Skontaktuj się z administratorem, by uzupełnić nazwisko rodowe!";
                    formErrors.nickOptions.isInvalid = true;
                    formErrors.nickOptions.isValid = false;
                    this.setState({ formErrors: formErrors, nickOptions: 0 });
                }
                break;
            case "3":
                this.displayName(id);
                break;
            case "4":
                this.displayName(id);
                break;
        }

    }

    displayName(id) {

        switch (id) {
            case "1":
                this.setState({ displayedName: this.state.data.firstName + " " + this.state.data.lastName })
                break;
            case "2":
                this.setState({ displayedName: this.state.data.firstName + " " + this.state.data.lastName + " (" + this.state.data.maiden + ")" })
                break;
            case "3":
                if (this.state.nickInput) {
                    this.setState({ displayedName: this.state.data.firstName + " " + this.state.data.lastName + " (" + this.state.nickInput + ")", nickOptions: id })
                } else {
                    let { formErrors } = this.state;
                    formErrors.nickInput.info = "Uzupełnij pseudoninm!";
                    formErrors.nickInput.isInvalid = true;
                    formErrors.nickInput.isValid = false;
                    this.setState({ formErrors: formErrors, nickOptions: 0 });
                }

                break;
            case "4":
                if (this.state.nickInput) {
                    this.setState({ displayedName: this.state.nickInput, nickOptions: id })
                } else {
                    let { formErrors } = this.state;
                    formErrors.nickInput.info = "Uzupełnij pseudoninm!";
                    formErrors.nickInput.isInvalid = true;
                    formErrors.nickInput.isValid = false;
                    this.setState({ formErrors: formErrors, nickOptions: 0 });
                }
                break;
        }

    }

    handleDeleteTeam(id) {

        const myTeams = this.state.myTeams;

        const updatedMyTeams = myTeams.filter((team) => team.id !== id);

        this.setState({ myTeams: updatedMyTeams })

    }

    handleAddTeam() {

        const { teamIdInput, teamNameInput, teamYearFromInput, teamYearByInput, teamFormErrors } = this.state;
        let valid = true;

        if (teamIdInput === "Wybierz drużynę...") {
            teamFormErrors.teamNameInput.info = "Wybierz drużynę!";
            teamFormErrors.teamNameInput.isInvalid = true;
            valid = false;
        } else {
            teamFormErrors.teamNameInput.isValid = true;
            teamFormErrors.teamNameInput.isInvalid = false;
        }

        if (teamYearFromInput === "Wybierz rok...") {
            teamFormErrors.teamYearFromInput.info = "Wybierz rok!";
            teamFormErrors.teamYearFromInput.isInvalid = true;
            valid = false;
        } else {
            teamFormErrors.teamYearFromInput.isValid = true;
            teamFormErrors.teamYearFromInput.isInvalid = false;
        }

        if (teamYearByInput === "Wybierz rok początkowy...") {
            teamFormErrors.teamYearByInput.info = "Wybierz rok początkowy!";
            teamFormErrors.teamYearByInput.isInvalid = true;
            valid = false;
        } else {

            if (teamYearByInput === "Wybierz rok...") {
                teamFormErrors.teamYearByInput.info = "Wybierz rok!";
                teamFormErrors.teamYearByInput.isInvalid = true;
                valid = false;
            } else {
                teamFormErrors.teamYearByInput.isValid = true;
                teamFormErrors.teamYearByInput.isInvalid = false;
            }

        }

        if (teamYearByInput !== "Wybierz rok początkowy..." && teamYearFromInput !== "Wybierz rok..." && teamYearByInput !== "Wybierz rok...") {

            if (teamYearFromInput > teamYearByInput) {
                teamFormErrors.teamYearFromInput.info = "Błędne daty!";
                teamFormErrors.teamYearFromInput.isInvalid = true;
                teamFormErrors.teamYearByInput.info = "Błędne daty!";
                teamFormErrors.teamYearByInput.isInvalid = true;
                valid = false;
            }

        }

        this.setState({ teamFormErrors: teamFormErrors });

        if (valid) {

            const myTeams = this.state.myTeams;
            const updatedMyTeams = myTeams.concat({ id: uuidv4(), tid: teamIdInput, teamName: teamNameInput, yearFrom: teamYearFromInput, yearBy: teamYearByInput });
            let y2 = [];
            y2.push(<option disabled>Wybierz rok początkowy...</option>);
            this.setState({
                myTeams: updatedMyTeams,
                yearsBy: y2
            })
            this.resetTeamForm();
        }

    }

    handleChangeSelect(event) {

        event.preventDefault();
        switch (event.target.name) {
            case "teamNameInput":
                this.setState({
                    teamIdInput: event.target.value,
                    teamNameInput: event.target.options[event.target.selectedIndex].text
                });
                break;
            case "teamYearFromInput":
                let y2 = [];
                y2.push(<option disabled>Wybierz rok...</option>)
                for (var i = event.target.value; i <= (new Date().getFullYear()); i++) {
                    y2.push(<option value={i}>{i}</option>);
                }
                y2.push(<option value="obecnie">obecnie</option>);
                this.setState({
                    [event.target.name]: event.target.value,
                    yearsBy: y2,
                    teamYearByInput: "Wybierz rok..."
                });
                break;
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
                break;
        }
    }

    resetTeamForm() {

        this.setState({ teamIdInput: "Wybierz drużynę...", teamYearByInput: "Wybierz rok początkowy...", teamYearFromInput: "Wybierz rok..." });
        this.resetTeamValid();

    }

    resetTeamValid() {

        let teamFormErrors = this.state.teamFormErrors;

        Object.values(teamFormErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            teamFormErrors: teamFormErrors
        })
    }

    handleEditProfileSubmit(event) {
        let valid = true;
        const { nickInput, nickOptions, memberOf, myTeams, image, formErrors, switch91, teamFormErrors } = this.state;
        this.resetValid();

        if (!nickInput) {
            if (nickOptions == 3 || nickOptions == 4) {
                formErrors.nickInput.info = "Uzupełnij imię!";
                formErrors.nickInput.isInvalid = true;
                valid = false;
            }
        } else {
            formErrors.nickInput.isValid = true;
            formErrors.nickInput.isInvalid = false;
            formErrors.nickOptions.isValid = true;
            formErrors.nickOptions.isInvalid = false;
        }

        if (memberOf == 1) {
            if (myTeams.length == 0) {
                teamFormErrors.teamNameInput.isValid = false;
                teamFormErrors.teamNameInput.isInvalid = true;
                teamFormErrors.teamNameInput.info = "Uzupełnij swoją przynależność!"
                valid = false;
            } else {
                formErrors.memberOf.isValid = true;
                formErrors.memberOf.isInvalid = false;
            }
        } else {
            formErrors.memberOf.isValid = true;
            formErrors.memberOf.isInvalid = false;
        }

        if (valid) {
            this.setState({ loadingShow: true });
            const cookies = new Cookies();
            const token = cookies.get('token');
            const thisRef = this;
            fetch(image).then(r => {
                r.blob().then(a => {
                    var reader = new FileReader();
                    reader.readAsDataURL(a);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        const data = {
                            token: token,
                            displayedName: nickOptions,
                            nick: nickInput,
                            member: memberOf,
                            teams: JSON.stringify(myTeams),
                            newsletter: switch91,
                            img: image === null ? '' : base64data
                        }

                        http_post(`profileUpdate.php`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Profil został zaktualizowany!");
                                    thisRef.handlerPage(0);
                                } else if (res.data.valid === 2) {
                                    const { teamFormErrors } = thisRef.state;
                                    toast.error("Błędnie określona przynależność do drużyn!");
                                    teamFormErrors.teamNameInput.isInvalid = true;
                                    teamFormErrors.teamNameInput.isValid = false;
                                    teamFormErrors.teamNameInput.info = "Błędnie określona przynależność do drużyn!";
                                    thisRef.setState({ loadingShow: false, teamFormErrors: teamFormErrors });
                                } else {
                                    window.location.reload();
                                }
                            } else {
                                window.location.reload();
                            }
                        })
                    }
                });
            });
        } else {
            toast.error("Uzupełnij poprawnie formularz!");
            event.stopPropagation();
        }

        this.setState({ formErrors: formErrors, teamFormErrors: teamFormErrors });

        event.preventDefault();
    }

    handleMailingSubmit(event) {

        this.setState({ loadingShow: true });
        const cookies = new Cookies();
        const token = cookies.get('token');

        const { switch1, switch2, switch3, switch4, switch5, switch6, switch7, switch8, switch9 } = this.state;

        const data = {
            token: token,
            n_all: switch1,
            n1: switch2,
            n2: switch3,
            n3: switch4,
            n4: switch5,
            n5: switch6,
            n6: switch7,
            n7: switch8,
            n8: switch9
        }

        http_post(`profileMailingUpdate.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    toast.success("Profil został zaktualizowany!");
                    this.handlerPage(0);
                } else {
                    toast.error("Błąd przesyłania danych!");
                    this.handlerPage(0);
                }
            } else {
                window.location.reload();
            }
        })
        event.preventDefault();
    }

    render() {
        if (this.state.data === null)
            return <RequireLogin></RequireLogin>
        else
            return (
                <LoadingSpinner loading={this.state.loading}>
                    <RequireLogin>
                        <Header />
                        <Container className="container">
                            <ToastContainer />
                            <Card className="card indexCard">
                                <Card.Header className="indexHeader"><Lato>PROFIL</Lato></Card.Header>

                                <Card.Body>
                                    <Row align="center">
                                        <Col sm="3" className="mb-2">
                                            <Button className="mediaBlock" variant="primary" onClick={() => { this.handlerPage(0) }}><LatoBold>Podgląd profilu</LatoBold></Button>
                                        </Col>
                                        <Col sm="3" className="mb-2">
                                            <Button className="mediaBlock" variant="primary" onClick={() => { this.handlerPage(3) }}><LatoBold>Edycja profilu</LatoBold></Button>
                                        </Col>
                                        <Col sm="3" className="mb-2">
                                            <Button className="mediaBlock" variant="primary" onClick={() => { this.handlerPage(1) }}><LatoBold>Zmiana hasła</LatoBold></Button>
                                        </Col>
                                        <Col sm="3" className="mb-2">
                                            <Button className="mediaBlock" variant="primary" onClick={() => { this.handlerPage(2) }}><LatoBold>Ustawienia powiadomień</LatoBold></Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {this.state.page == 0 &&
                                        <>
                                            <h2><Lato>Podgląd profilu</Lato></h2>
                                            {this.state.loadingShow ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="grow" variant="warning" />
                                                </div>
                                                :
                                                <>
                                                    <Row>
                                                        <Col align="center" className="mb-2" sm={12} md={4}>
                                                            <img src={this.state.data.avatar} />
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <Form.Label>Nazwa wyświetlana</Form.Label>
                                                                    <Card.Title>{this.state.data.name}</Card.Title>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Label>Nazwisko rodowe</Form.Label>
                                                                    <Card.Title>{(this.state.data.maiden === "" ? "-" : this.state.data.maiden)}</Card.Title>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Label>Adres e-mail</Form.Label>
                                                                    <Card.Title>{this.state.data.email}</Card.Title>
                                                                </Col>

                                                            </Row>
                                                        </Col>

                                                        <Col sm={12} md={4}>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <Form.Label>Rejestracja konta</Form.Label>
                                                                    <Card.Title>{this.state.data.register}</Card.Title>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Label>Pseudonim</Form.Label>
                                                                    <Card.Title>{(this.state.data.nick === "" ? "-" : this.state.data.nick)}</Card.Title>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Label>Newsletter</Form.Label>
                                                                    <Card.Title>{(this.state.data.newsletter === '0' ? "NIE" : "TAK")}</Card.Title>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {this.state.data.teams.length != 0 &&
                                                        <>
                                                            <hr />
                                                            <Card.Title>Przynależność do drużyn</Card.Title>
                                                            <Table striped bordered style={{ marginTop: "10px" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nazwa drużyny</th>
                                                                        <th>Zakres lat</th>
                                                                    </tr>
                                                                </thead>
                                                                {this.state.data.teams.map((team) => (
                                                                    <tr>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            {team.teamName}
                                                                        </td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            {team.yearFrom} - {(team.yearBy == null ? "obecnie" : team.yearBy)}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </Table>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    }

                                    {this.state.page == 1 &&
                                        <>
                                            <h2><Lato>Zmiana hasła</Lato></h2>
                                            <Form noValidate onSubmit={this.handleChangePassword}>
                                                {this.state.loadingShow ?
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Spinner animation="grow" variant="warning" />
                                                    </div>
                                                    :
                                                    <>
                                                        <Form.Group>
                                                            <Form.Label>Aktualne hasło</Form.Label>
                                                            <Form.Control isValid={this.state.formErrors.passwordInput.isValid} isInvalid={this.state.formErrors.passwordInput.isInvalid} onChange={this.handleChange} value={this.state.passwordInput} name="passwordInput" type="password" placeholder="Wprowadź hasło" />
                                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.passwordInput.info}</Form.Control.Feedback>
                                                            <Form.Text id="passwordHelpBlock" muted>
                                                                Twoje hasło musi zawierać minimum 8 znaków oraz jedną wielką literę, cyfrę i znak specjalny.
                                                            </Form.Text>
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.Label>Nowe hasło</Form.Label>
                                                            <Form.Control isValid={this.state.formErrors.passwordInput2.isValid} isInvalid={this.state.formErrors.passwordInput2.isInvalid} onChange={this.handleChange} value={this.state.passwordInput2} name="passwordInput2" type="password" placeholder="Wprowadź nowe hasło" />
                                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.passwordInput2.info}</Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.Label>Powtórz nowe hasło</Form.Label>
                                                            <Form.Control isValid={this.state.formErrors.passwordInput3.isValid} isInvalid={this.state.formErrors.passwordInput3.isInvalid} onChange={this.handleChange} value={this.state.passwordInput3} name="passwordInput3" type="password" placeholder="Powtórz nowe hasło" />
                                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.passwordInput3.info}</Form.Control.Feedback>
                                                        </Form.Group>

                                                        <div className="text-right">
                                                            <Button onClick={() => { this.handlerPage(0) }} className="mr-2" variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                                            <Button type="submit" disabled={this.state.loadingShow} variant="primary"><LatoBold>Zmień hasło</LatoBold></Button>
                                                        </div>
                                                    </>
                                                }
                                            </Form>
                                        </>
                                    }

                                    {this.state.page == 2 &&
                                        <>
                                            <h2><Lato>Ustawienia powiadomień</Lato></h2>
                                            {this.state.loadingShow ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="grow" variant="warning" />
                                                </div>
                                                :
                                                <>
                                                    <Form noValidate onSubmit={this.handleMailingSubmit}>
                                                        <Form.Group>
                                                            <Form.Check id="switch1" type="switch" label="Włącz powiadomienia e-mail" checked={this.state.switch1} onChange={this.handleSwitchChange(1)} />
                                                        </Form.Group>
                                                        {this.state.switch1 &&
                                                            <>
                                                                <Card.Text>Komentarze do zdjęć</Card.Text>
                                                                <Form.Group>
                                                                    <Form.Check id="switch2" type="switch" label="Powiadamiaj mnie, gdy ktoś także skomentuje zdjęcie" checked={this.state.switch2} onChange={this.handleSwitchChange(2)} />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Check id="switch3" type="switch" label="Powiadamian mnie, gdy ktoś odpowie na mój komentarz do zdjęcia" checked={this.state.switch3} onChange={this.handleSwitchChange(3)} />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Check id="switch4" type="switch" label="Powiadamiaj mnie, gdy ktoś także odpowie pod zdjęciem" checked={this.state.switch4} onChange={this.handleSwitchChange(4)} />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Check id="switch5" type="switch" label="Powiadamiaj mnie, gdy ktoś skomentuje zdjęcie, które mam w ulubionych" checked={this.state.switch5} onChange={this.handleSwitchChange(5)} />
                                                                </Form.Group>
                                                                <Card.Text>Komentarze do folderów</Card.Text>
                                                                <Form.Group>
                                                                    <Form.Check id="switch7" type="switch" label="Powiadamiaj mnie, gdy ktoś także skomentuje folder" checked={this.state.switch7} onChange={this.handleSwitchChange(7)} />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Check id="switch8" type="switch" label="Powiadamiaj mnie, gdy ktoś odpowie na mój komentarz do folderu" checked={this.state.switch8} onChange={this.handleSwitchChange(8)} />
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Form.Check id="switch9" type="switch" label="Powiadamiaj mnie, gdy ktoś także odpowie pod folderem" checked={this.state.switch9} onChange={this.handleSwitchChange(9)} />
                                                                </Form.Group>
                                                                <Card.Text>Inne</Card.Text>
                                                                <Form.Group>
                                                                    <Form.Check id="switch6" type="switch" label="Powiadamiaj mnie, gdy zostaną dodane nowe zdjęcia, które mogą mnie zainteresować" checked={this.state.switch6} onChange={this.handleSwitchChange(6)} />
                                                                </Form.Group>
                                                            </>}
                                                        <div className="text-right">
                                                            <Button onClick={() => { this.handlerPage(0) }} className="mr-2" variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                                            <Button type="submit" disabled={this.state.loadingShow} variant="primary"><LatoBold>Zapisz ustawienia</LatoBold></Button>
                                                        </div>
                                                    </Form>
                                                </>}
                                        </>

                                    }

                                    {this.state.page == 3 &&
                                        <>
                                            <Card.Title>Edycja profilu</Card.Title>
                                            <Alert variant="danger">Zmiana <strong>imienia, nazwisk lub adresu e-mail</strong> wymaga kontaktu z administratorami apliakcji (<a href="mailto:archiwum@pomaranczarnia.org">archiwum@pomaranczarnia.org</a>).</Alert>
                                            <Form noValidate onSubmit={this.handleEditProfileSubmit}>
                                                <Form.Row>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Form.Label>Nazwa wyświetlana:</Form.Label>
                                                            <h3>{this.state.displayedName}</h3>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Form.Label>Wyświetlanie nazwy użytkownika</Form.Label>
                                                            <Form.Control isValid={this.state.formErrors.nickOptions.isValid} isInvalid={this.state.formErrors.nickOptions.isInvalid} value={this.state.nickOptions} onChange={this.handleNickOptions} as="select" defaultValue="0">
                                                                <option value="0" disabled>Wybierz...</option>
                                                                <option value="1">Imię i nazwisko</option>
                                                                <option value="2">Imię i nazwisko (Nazwisko rodowe)</option>
                                                                <option value="3">Imię i nazwisko (Pseudonim)</option>
                                                                <option value="4">Pseudonim</option>
                                                            </Form.Control>
                                                            <Form.Text muted>
                                                                Wybierz w jaki sposób będziesz podpisywany/na pod komentarzami.
                                                            </Form.Text>
                                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.nickOptions.info}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Form.Label>Pseudonim</Form.Label>
                                                            <Form.Control isValid={this.state.formErrors.nickInput.isValid} isInvalid={this.state.formErrors.nickInput.isInvalid} onChange={this.handleChange} value={this.state.nickInput} name="nickInput" type="text" placeholder="Wprowadź pseudonim" />
                                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.nickInput.info}</Form.Control.Feedback>
                                                            <Form.Text id="passwordHelpBlock" muted>
                                                                Pole opcjonalne.
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>

                                                <Form.Group>
                                                    <Form.Check id="91" type="switch" label="Chcę otrzymywać wiadomości e-mailowe dotyczące działań Szczepu i Fundacji Pomarańczarni (newsletter)." checked={this.state.switch91} onChange={this.handleSwitchChange(91)} />
                                                </Form.Group>

                                                <hr />
                                                <Card.Title>Przynależność do drużyn</Card.Title>
                                                <Form.Row>
                                                    <Col sm>
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Czy byłeś/aś członkiem Pomarańczarni?</Form.Label>
                                                            <Form.Control isValid={this.state.formErrors.memberOf.isValid} isInvalid={this.state.formErrors.memberOf.isInvalid} value={this.state.memberOf} as="select" defaultValue="0" onChange={() => { this.setState({ memberOf: (this.state.memberOf == "1" ? "2" : "1") }) }}>
                                                                <option value="0" disabled>Wybierz...</option>
                                                                <option value="1">TAK</option>
                                                                <option value="2">NIE</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.memberOf.info}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                {this.state.memberOf == "1" &&
                                                    <>
                                                        <Form.Row>
                                                            <Col lg>
                                                                <Form.Group>
                                                                    <Form.Label>Przynależność do drużyny</Form.Label>
                                                                    <Form.Control isValid={this.state.teamFormErrors.teamNameInput.isValid} isInvalid={this.state.teamFormErrors.teamNameInput.isInvalid} as="select" defaultValue="Wybierz drużynę..." value={this.state.teamIdInput} name="teamNameInput" onChange={this.handleChangeSelect}>
                                                                        {this.state.teams}
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">{this.state.teamFormErrors.teamNameInput.info}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg>
                                                                <Form.Group>
                                                                    <Form.Label>Od</Form.Label>
                                                                    <Form.Control isValid={this.state.teamFormErrors.teamYearFromInput.isValid} isInvalid={this.state.teamFormErrors.teamYearFromInput.isInvalid} as="select" defaultValue="Wybierz rok..." value={this.state.teamYearFromInput} name="teamYearFromInput" onChange={this.handleChangeSelect}>
                                                                        {this.state.yearsFrom}
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">{this.state.teamFormErrors.teamYearFromInput.info}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg>
                                                                <Form.Group>
                                                                    <Form.Label>Do</Form.Label>
                                                                    <Form.Control isValid={this.state.teamFormErrors.teamYearByInput.isValid} isInvalid={this.state.teamFormErrors.teamYearByInput.isInvalid} as="select" defaultValue="Wybierz rok początkowy..." value={this.state.teamYearByInput} name="teamYearByInput" onChange={this.handleChangeSelect}>
                                                                        {this.state.yearsBy}
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">{this.state.teamFormErrors.teamYearByInput.info}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>

                                                            <Button variant="primary" className="mb-0" onClick={this.handleAddTeam}><LatoBold>Zapisz drużynę</LatoBold></Button>

                                                        </Form.Row>
                                                        <Form.Text id="passwordHelpBlock" muted>
                                                            Wprowadź swoją przynależność do drużyn Pomarańczarni, by zobaczyć dopasowane do Ciebie materiały archiwalne.
                                                        </Form.Text>
                                                        {this.state.myTeams.length > 0 ?
                                                            <>
                                                                <Table striped bordered style={{ marginTop: "10px" }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Nazwa drużyny</th>
                                                                            <th>Zakres lat</th>
                                                                            <th>Opcje</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {this.state.myTeams.map((team) => (
                                                                        <tr>
                                                                            <td style={{ verticalAlign: "middle" }}>
                                                                                {team.teamName}
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle" }}>
                                                                                {team.yearFrom} - {(team.yearBy ? team.yearBy : "obecnie")}
                                                                            </td>
                                                                            <td style={{ verticalAlign: "middle" }}>
                                                                                <Button variant="outline-danger" className="mb-0" onClick={() => this.handleDeleteTeam(team.id)}>Usuń</Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </Table>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                        }

                                                    </>
                                                }
                                                <hr />
                                                <Card.Title>Zdjęcie profilowe</Card.Title>
                                                <Form.Row>
                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label>Podgląd zdjęcia profilowego</Form.Label>
                                                            <div align="center">
                                                                {
                                                                    this.state.image === null &&
                                                                    <div style={{ width: 173, height: 182, padding: 20, border: 'thin dashed black' }}>
                                                                        <FontAwesomeIcon icon={faUser} size="9x" />
                                                                    </div>
                                                                }{
                                                                    this.state.image !== null &&
                                                                    <div onClick={() => { this.setState({ image: null }) }} className="profileDiv" style={{ width: 173, height: 182, border: 'thin dashed black' }}>
                                                                        <img className="profileImg" width={171} height={180} src={this.state.image} />
                                                                        <div class="middle">
                                                                            <div class="centered textProfile">
                                                                                <FontAwesomeIcon icon={faTrash} size="7x" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <Form.Text muted>
                                                                Dodawanie zdjęcia profilowego jest nieobowiązkowe.
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label>Wybierz zdjęcie profilowe</Form.Label>
                                                            <ProfilePicturePicker thisRef={this} />
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                <div className="text-right">
                                                    <Button onClick={() => { this.handlerPage(0) }} className="mr-2" variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                                    <Button type="submit" disabled={this.state.loadingShow} variant="primary"><LatoBold>Zapisz zmiany</LatoBold></Button>
                                                </div>
                                            </Form>
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                            {this.state.page === 0 && Array.isArray(this.state.liked) && this.state.liked.length ?
                                (
                                    <Card className="card indexCard">
                                        <Card.Header className="indexHeader">MOJE ULUBIONE</Card.Header>
                                        <Card.Body>
                                            <Row className="align-iteams-center flex-wrap">
                                                {this.state.liked}
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )
                                :
                                (
                                    <></>
                                )
                            }

                        </Container>

                    </RequireLogin>
                </LoadingSpinner >
            );
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export default Profile;