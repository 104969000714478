import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import http_post from '../scripts/post';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import Viewer from 'react-viewer';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom'
import '../styles/ImageView.css';
import 'react-toastify/dist/ReactToastify.css';
import LikeButton from './LikeButton';
import Tags from './Tags';
import LatoBold from './LatoBold';
import Lato from './Lato';

function nl2br(arr) {
    return arr.split('\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>
    })
}

class ImageView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            imageId: props.id,
            visible: false,
            show: false,
            reportInput: "",
            formErrors: {
                reportInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            loadingShow: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetValid = this.resetValid.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    resetValid() {
        let formErrors = this.state.formErrors;
        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })
        this.setState({
            formErrors: formErrors,
            reportInput: ""
        })
    }

    handleSubmit(event) {

        let valid = true;
        const { reportInput, imageId, formErrors } = this.state;

        if (reportInput <= 0) {
            formErrors.reportInput.info = "Uzupełnij treść zgłoszenia!";
            formErrors.reportInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.reportInput.isValid = true;
            formErrors.reportInput.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {
            this.setState({ loadingShow: true });
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                report: reportInput,
                image: imageId,
                token: token
            }

            http_post(`report.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ loadingShow: false, show: false });
                        toast.success("Twoje zgłoszenie zostało przesłane!");
                        this.resetValid();
                    }
                } else {
                    window.location.reload();
                }
            })

        } else {

            toast.error("Uzupełnij poprawnie formularz!");
            event.stopPropagation();
        }

        event.preventDefault();

    }

    componentDidMount() {
        this.props.data.then((res) => {
            this.setState({ data: res.data[0] })
        });

        const cookies = new Cookies();
        const openImage = cookies.get('openImage');
        if (openImage !== undefined) {
            this.setState({ visible: true });
            cookies.remove('openImage', { path: '/' });
        }
    }

    renderToolbar(defaultToolbarConfigs, history) {
        let result = [defaultToolbarConfigs[0], defaultToolbarConfigs[1]];

        if (this.state.data.id1 !== null) {
            const p1 = result.push(defaultToolbarConfigs[2]);
            result[p1 - 1].onClick = () => {
                const cookies = new Cookies();
                cookies.set('openImage', true, { path: '/' });
                window.location.href = "/galeria/" + this.state.data.id1;
            };
        }

        if (this.state.data.id2 !== null) {
            const p2 = result.push(defaultToolbarConfigs[4]);
            result[p2 - 1].onClick = () => {
                const cookies = new Cookies();
                cookies.set('openImage', true, { path: '/' });
                window.location.href = "/galeria/" + this.state.data.id2;
            };
        }

        result.push(defaultToolbarConfigs[6]);
        return result;
    }

    render() {
        if (this.state.data === null)
            return <></>
        else
            return (

                <>
                    <Row className="imageViewRow">
                        <Col xs={12} lg={8} className="d-flex align-items-center fakeCard">
                            <Card>
                                <Card.Body>
                                    <div className="viewerDiv" onClick={() => { this.setState({ visible: true }) }}>
                                        <img src={'https://archiwum.pomaranczarnia.org/photos/' + (this.state.data.filename)} className="viewerImg" />
                                        <div class="middle">
                                            <div class="centered text">
                                                <FontAwesomeIcon icon={faExpandAlt} size="7x" />
                                            </div>
                                        </div>
                                    </div>
                                    <Viewer
                                        visible={this.state.visible}
                                        onClose={() => { this.setState({ visible: false }) }}
                                        images={[{ src: 'https://archiwum.pomaranczarnia.org/photos/' + (this.state.data.filename), alt: this.state.data.folder + ' (' + this.state.data.no + ')' }]}
                                        zIndex="2002"
                                        showTotal={false}
                                        customToolbar={(defaultToolbarConfigs) => this.renderToolbar(defaultToolbarConfigs, this.props.history)}
                                        zoomSpeed="0.15"
                                        noImgDetails={true}
                                        noNavbar={true}
                                        className="viewerImgClass"
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4}>
                            <Card >
                                <Card.Header><Lato>INFORMACJE</Lato></Card.Header>
                                <Card.Body>
                                    <LikeButton id={this.state.imageId} likes={this.state.data.reactions} like={this.state.data.is_liked} />
                                    <Card.Title>Opis</Card.Title>
                                    <Card.Text>
                                        {nl2br(this.state.data.description)}
                                    </Card.Text>
                                    <Card.Title>Data wykonania</Card.Title>
                                    <Card.Text>
                                        {(this.state.data.day === null ? '' : this.state.data.day + '.') + (this.state.data.month === null ? '' : m(this.state.data.month) + '.') + this.state.data.year}
                                    </Card.Text>
                                    <Card.Title>Tagi</Card.Title>
                                    <Card.Text>
                                        <Tags tags={this.state.data.tags} />
                                    </Card.Text>
                                    <hr />
                                    <Row>
                                        <Col xl={6}>
                                            <Card.Title>Data dodania</Card.Title>
                                            <Card.Text>
                                                {this.state.data.added}
                                            </Card.Text>
                                        </Col>
                                        <Col xl={6} className="text-right align-items-center d-flex">
                                            <Button align="center" onClick={() => { this.setState({ show: true }) }} className="reportButton" variant="outline-danger"><LatoBold>Zgłoś zdjęcie</LatoBold></Button>
                                        </Col>
                                        <Modal show={this.state.show} onHide={() => { this.setState({ show: false }) }}>
                                            <Form noValidate onSubmit={this.handleSubmit}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title><Lato>Zgłoś zdjęcie</Lato></Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {this.state.loadingShow ?
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Spinner animation="border" variant="secondary" />
                                                        </div>
                                                        :
                                                        <>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Label>Treść zgłoszenia</Form.Label>
                                                                <Form.Control isValid={this.state.formErrors.reportInput.isValid} isInvalid={this.state.formErrors.reportInput.isInvalid} onChange={this.handleChange} value={this.state.reportInput} name="reportInput" as="textarea" rows="3" placeholder="Wprowadź treść zgłoszenia" />
                                                                <Form.Text muted>
                                                                    Zauważyłaś/zauważyłeś że zdjęcie ma błędy opis lub datę? Uważasz, że z jakiś powodów nie powinno znajdować się w Cyfrowym Archiwum Pomarańczarni? - zgłoś je Administratorom serwisu.
                                                            </Form.Text>
                                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.reportInput.info}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </>
                                                    }
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="outline-primary" onClick={() => { this.setState({ show: false }) }}><LatoBold>Anuluj</LatoBold></Button>
                                                    <Button disabled={this.state.loadingShow} type="submit" variant="primary"><LatoBold>Wyślij</LatoBold></Button>
                                                </Modal.Footer>
                                            </Form>
                                        </Modal>
                                    </Row>
                                </Card.Body>


                            </Card>
                        </Col>
                    </Row>
                </>

            )
    }

}

function m(month) {
    if (month.toString().length === 1)
        return '0' + month.toString();
    else
        return month.toString();
}

export default withRouter(ImageView);