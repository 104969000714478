import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, TableHeaderColumn } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/AdminNews.module.css';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import heic2any from "heic2any";
import { FileDrop } from 'react-file-drop';
import imageCompression from 'browser-image-compression';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

const { SearchBar } = Search;

class AdminNews extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "added",
            sortOrder: "asc",
            url: "",
            description: "",
            valid: "",
            img: null,
            loadingShow: false,
            formErrors: {
                url: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                description: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                valid: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                img: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            show: false,
            modalDelete: {
                label: "",
                value: null
            }
        };

        this.inputFile = React.createRef(null);
        this.refreshData = this.refreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    componentDidMount() {
        this.refreshDataManually();
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }

        http_post(`newsTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.news.map((row) => {
                    row.added = row.added.substring(0, 10)
                    row.photo = <img className={styles.photo} src={'https://archiwum.pomaranczarnia.org/photos/' + row.img} />
                    row.button = <>
                        <Button variant="primary" className="mr-1" href={row.url} disabled={row.url === ''}><FontAwesomeIcon icon={faExternalLinkAlt} /></Button>
                        <Button variant="danger" onClick={() => { this.handleDelete(row.id, row.img) }}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminTags&no=' + res.status + '&text=' + res.text + '&url=newsTable.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleDelete(value, img) {
        this.setState({
            modalDelete: {
                value: value,
                img: img
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteNews.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false }, () => { toast.success("Poprawnie usunięto aktualność!") });
                    this.refreshDataManually('pagination');
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć aktualności!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            description: "",
            url: "",
            valid: "",
            img: null

        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        setTimeout(() => {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                value: row.id,
                label: newValue
            }
            if (oldValue === newValue) {
                done(false);
            } else {
                http_post(`editNews.php`, data).then((res) => {
                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            toast.success("Poprawnie zmieniono aktualność!")
                            if (res.data.rr) {
                                done(false);
                                this.refreshDataManually('pagination');
                            } else {
                                done();
                            }
                        } else {
                            toast.error("Nie udało się zmienić aktualności!")
                            done(false);
                        }
                    } else {
                        toast.error("Błąd transmisji danych!")
                        done(false);
                    }
                })
            }
        }, 0);
        return { async: true };
    }

    handleSubmit(event) {

        let valid2 = true;
        const { description, url, valid, img, formErrors } = this.state;

        if (description <= 0) {
            formErrors.description.isValid = true;
            formErrors.description.isInvalid = false;
        } else {
            formErrors.description.isValid = true;
            formErrors.description.isInvalid = false;
        }

        if (url <= 0) {
            formErrors.url.isValid = true;
            formErrors.url.isInvalid = false;
        } else {
            formErrors.url.isValid = true;
            formErrors.url.isInvalid = false;
        }

        if (valid === "" || valid.length < 10) {
            formErrors.valid.info = "Wprowadź poprawną datę!";
            formErrors.valid.isValid = false;
            formErrors.valid.isInvalid = true;
        } else {
            formErrors.valid.isValid = true;
            formErrors.valid.isInvalid = false;
        }

        if (img === null) {
            formErrors.img.info = "Wgraj zdjęcie aktualności!";
            formErrors.img.isValid = false;
            formErrors.img.isInvalid = true;
        } else {
            formErrors.img.isValid = true;
            formErrors.img.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid2) {

            this.setState({ loadingShow: true })

            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                description: description,
                img: img,
                url: url,
                valid: valid
            }
            http_post(`newNews.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie dodano aktualność!") });
                        this.refreshDataManually('pagination');
                        this.resetValid();
                        this.setState({ loadingShow: false })
                    } else {
                        this.setState({ show: false, loadingShow: false }, () => { toast.error("Nie udało się dodać aktualności!") });
                    }
                } else {
                    this.setState({ show: false, loadingShow: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })


        } else event.stopPropagation();

        event.preventDefault();
    }

    readFile1 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        })
    }

    readFile2 = event => {
        const blob2 = window.btoa(event.target.result);
        const blob = b64toBlob(blob2, 'image/heic');
        const thisRef = this.props.thisRef;
        heic2any({ blob }).then((conversionResult) => {
            const url = URL.createObjectURL(conversionResult);
            this.setState({ uploadingInProgress: false, img: url });
        }).catch((e) => {
            toast.error(e);
        });
    }

    onClick() {
        this.inputFile.current.value = null;
        this.inputFile.current.click();
    }

    async onFileChange(e) {
        if (e.files && e.files.length > 0) {
            this.setState({ uploadingInProgress: true });

            if (validate(e.files[0])) {
                let file = e.files[0];

                if (file.name.toLowerCase().split('.').pop() === 'heic') {
                    var reader = new FileReader();
                    reader.addEventListener('load', this.readFile2);
                    reader.readAsBinaryString(file);
                } else {
                    const options = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }

                    try {
                        const fileComp = await imageCompression(file, options);
                        let imageDataUrl = await this.readFile1(fileComp);
                        this.setState({ uploadingInProgress: false, img: imageDataUrl });
                    } catch (error) {
                        toast.error(error);
                    }
                }
            }
        } else {
            toast.error("Błąd przesyłania");
        }
    }

    render() {
        const columns = [{
            dataField: 'photo',
            text: 'Zdjęcie',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'description',
            text: 'Opis',
            sort: true,
            editable: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editCellStyle: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'added',
            text: 'Data dodania',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '150px' };
            }
        }, {
            dataField: 'valid',
            text: 'Data wygaśnięcia',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '180px' };
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '160px' };
            }
        }];

        const defaultSorted = [{
            dataField: 'added',
            order: 'asc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={2}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>AKTUALNOŚCI</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Dodaj aktualność</Lato></Card.Title>
                                    {this.state.loadingShow ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <Form noValidate onSubmit={this.handleSubmit}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Opis</Form.Label>
                                                <Form.Control isValid={this.state.formErrors.description.isValid} isInvalid={this.state.formErrors.description.isInvalid} onChange={this.handleChange} value={this.state.description} name="description" type="text" placeholder="Wprowadź opis aktualności" />
                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.description.info}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Adres linku do przekierowania</Form.Label>
                                                <Form.Control isValid={this.state.formErrors.url.isValid} isInvalid={this.state.formErrors.url.isInvalid} onChange={this.handleChange} value={this.state.url} name="url" type="text" placeholder="Wprowadź link do przekierowania" />
                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.url.info}</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Data zakończenia</Form.Label>
                                                <MaskedFormControl type='text' name='valid' value={this.state.valid} placeholder="Wprowadź datę wygaśnięcia" onChange={this.handleChange} mask='1111-11-11' />
                                                <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.valid.isValid} isInvalid={this.state.formErrors.valid.isInvalid} />
                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.valid.info}</Form.Control.Feedback>
                                                <Form.Text className="text-muted">Data w formacie: <strong>RRRR-MM-DD</strong>.</Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmail">
                                                {
                                                    this.state.uploadingInProgress ?
                                                        <div className="text-center">
                                                            <Spinner animation="grow" variant="warning" />
                                                        </div>
                                                        :
                                                        <Container
                                                            fluid
                                                            style={{ cursor: 'pointer', backgroundColor: '#EBEDED', padding: 40, height: 182, border: 'thin dashed black' }}
                                                            onClick={() => this.onClick()}
                                                        >

                                                            <FileDrop
                                                                onDrop={(files, event) => { this.inputFile.current.value = null; this.onFileChange({ files: files }); }}
                                                            >
                                                                Wybierz lub upuść zdjęcia tutaj
                                                                <img style={{ width: 100 }} src="./../../img/icons/drop_icon.svg" />
                                                                <Form.Text muted>Akceptowane formaty: jpg, png, bmp, jpeg, heic</Form.Text>
                                                            </FileDrop>
                                                            <input type='file' id='file' multiple ref={this.inputFile} onChange={(e) => this.onFileChange(e.target)} accept=".jpg,.png,.bmp,.jpeg,.heic" style={{ display: 'none' }} />
                                                        </Container>
                                                }
                                                <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.img.isValid} isInvalid={this.state.formErrors.img.isInvalid} />
                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.img.info}</Form.Control.Feedback>
                                                {
                                                    this.state.img !== null &&
                                                    <Form.Group className="mt-2">
                                                        <Form.Label>Pogląd wgranego zdjęcia</Form.Label><br />
                                                        <img style={{ width: "600px" }} src={this.state.img} />
                                                    </Form.Group>
                                                }
                                            </Form.Group>
                                            <Button disabled={this.state.loadingShow} type="submit" variant="primary" block><LatoBold>Dodaj aktualność</LatoBold></Button>
                                        </Form>
                                    }
                                    <hr className="mt-4" />
                                    <Card.Title className="mt-4"><Lato>Lista aktualności</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.news}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak aktualności!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Usuwanie aktualności</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć wybraną aktualność?</p>
                                    <p><img className={styles.photo} src={"https://archiwum.pomaranczarnia.org/photos/" + this.state.modalDelete.img} /></p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

function isFileImage(file) {
    const acceptedImageTypes = ['jpeg', 'png', 'bmp', 'jpg', 'heic'];

    return file && acceptedImageTypes.includes(file.name.toLowerCase().split('.').pop());
}

function validate(input) {
    if (input) {
        if (isFileImage(input)) {
            return true;
        } else {
            toast.error("Niewłaściwy format obrazka");
            return false;
        }
    } else {
        toast.error("Błąd przesyłania obrazka");
        return false;
    }
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export default AdminNews;