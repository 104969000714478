import React, { useState } from "react";
import CountUp from "react-countup";
import '../styles/Lato.css';
import VisibilitySensor from "react-visibility-sensor";

const Ticker = ({ className, ...rest }) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    return (
        <CountUp {...rest} start={viewPortEntered ? null : 0}>
            {({ countUpRef }) => {
                return (
                    <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={isVisible => {
                            if (isVisible) {
                                setViewPortEntered(true);
                            }
                        }}
                        delayedCall
                    >
                        <strong><p class="lato-font" ref={countUpRef} /></strong>
                    </VisibilitySensor>
                );
            }}
        </CountUp>
    );
};

export default Ticker;