import axios from 'axios';

async function http_post(url, arr = []) {
    var bodyFormData = new FormData();

    for (const property in arr) {
        bodyFormData.append(property, arr[property]);
    }

    let result;

    try {
        result = await axios({
            method: 'post',
            url: 'https://archiwum.pomaranczarnia.org/api/'+url,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        if (result.status === 200) {
            result = { status: 200, data: result.data };
        } else {
            result = { status: result.status, text: result.statusText };
        }
    } catch (error) {
        result = { status: '?', text: error.toString() };
    } finally {
        return result;
    }
}

export default http_post;