import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import { type } from 'jquery';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import LatoBold from './LatoBold';

class NewComment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pid: props.id,
            type: props.type,
            answerComment: props.answerComment,
            refreshFunction: props.refreshFunction,
            commentInput: "",
            formErrors: {
                commentInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            loadingShow: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetValid = this.resetValid.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    resetValid() {
        let formErrors = this.state.formErrors;
        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })
        this.setState({
            formErrors: formErrors,
            commentInput: ""
        })
    }

    handleSubmit(event) {
        const cookies = new Cookies();
        const token = cookies.get('token');

        let valid = true;
        const { commentInput, pid, type, answerComment, formErrors } = this.state;

        if (commentInput <= 0) {
            formErrors.commentInput.info = "Uzupełnij treść komentarza!";
            formErrors.commentInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.commentInput.isValid = true;
            formErrors.commentInput.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {
            this.setState({ loadingShow: true });
            const data = {
                pid: pid,
                type: type,
                comment: commentInput,
                answer: answerComment,
                token: token
            }

            http_post(`newComment.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ loadingShow: false, show: false });
                        toast.success("Twój komentarz został opublikowany!");
                        this.resetValid();
                        this.props.refreshFunction(res.data.comment);
                    } else if (res.data.valid === 2) {
                        this.setState({ loadingShow: false, show: false });
                        toast.error("Twój komentarz został zablokowany ze względu na nieodpowiednią treść!");
                        this.resetValid();
                    }
                } else {
                    window.location.reload();
                }
            })

        } else {

            toast.error("Uzupełnij poprawnie formularz!");
            event.stopPropagation();
        }

        event.preventDefault();

    }

    render() {

        return (

            <>
                <Form noValidate onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        {this.state.answerComment === null ?
                            <Form.Label>Nowy komentarz</Form.Label>
                            :
                            <Form.Label>Nowa odpowiedź</Form.Label>
                        }

                        {this.state.loadingShow ?
                            <>
                                <Form.Text className="text-center">
                                    <Spinner animation="grow" variant="warning" />
                                </Form.Text>
                            </>
                            :
                            <>
                                <Form.Control isValid={this.state.formErrors.commentInput.isValid} isInvalid={this.state.formErrors.commentInput.isInvalid} onChange={this.handleChange} value={this.state.commentInput} name="commentInput" as="textarea" rows="2" placeholder={(this.state.type === "image" ? "Wiesz coś o tym zdjęciu? Podziel się swoimi wspomnieniami!" : "Wiesz coś o tym folderze? Podziel się swoimi wspomnieniami!")} />
                                <Form.Control.Feedback type="invalid">{this.state.formErrors.commentInput.info}</Form.Control.Feedback>
                            </>
                        }
                    </Form.Group>
                    <div className="text-right">
                        <Button type="submit" disabled={this.state.loadingShow} variant="primary">
                            {this.state.answerComment === null ?
                                <LatoBold>Opublikuj komentarz</LatoBold>
                                :
                                <LatoBold>Opublikuj odpowiedź</LatoBold>
                            }
                        </Button>
                    </div>
                </Form>
            </>

        )
    }

}


export default NewComment;