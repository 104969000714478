import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Button, Badge, Form, FormControl, Modal, Col, Toast, Row, ButtonGroup, ButtonToolbar, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import '../styles/Header.css';
import styles from '../styles/Header.module.css';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import LatoBold from '../components/LatoBold';

function Header(props) {

    const cookies = new Cookies();
    const rank = cookies.get('rank');
    const nick = cookies.get('nick');
    const token = cookies.get('token');

    const [searchInput, setInput] = useState("");
    const [show, setShow] = useState(false);
    const [badge, setBadge] = useState(0);
    const [notifications, setNotifications] = useState(null);



    const markAsRead = (nid) => {
        http_post(`notifications_delete.php`, { token: token, nid: nid }).then((res) => {
            if (res.status === 200) {
                refreshNotifications();
            } else {
                props.history.push({
                    pathname: '/error',
                    search: '?page=Header&no=' + res.status + '&text=' + res.text + '&url=notifications_delete.php'
                });
            }
        })
    }

    function dismissToast(e) {
        // now this part stops the click from propagating
        if (!e) var e = window.event;
        e.cancelBubble = true;
        //if (e.stopPropagation) e.stopPropagation();
    }

    const refreshNotifications = () => {
        http_post(`notifications.php`, { token: token }).then((res) => {
            if (res.status === 200) {

                let result;

                if (Array.isArray(res.data) && res.data.length) {
                    result = res.data.map((notification) => {

                        let link = "";
                        if (notification.type === "1") link = "/folder/" + notification.fid_pid;
                        else link = "/galeria/" + notification.fid_pid;

                        if (notification.cid) link = link + "/" + notification.cid;

                        return (
                            <>
                                <Toast className={styles.toast} >
                                    <Row onClick={() => { markAsRead(notification.id); window.location.href = link; }} className={styles.toastLink}>
                                        <Col className={classNames('d-flex', 'align-items-center', styles.toastImgCol)} xs="auto">
                                            <img src={notification.mini} className={styles.imgToast} alt="" />
                                        </Col>
                                        <Col className={styles.toastCol}>
                                            <div class="toast-header">
                                                <strong class="mr-auto">{notification.title}</strong>
                                                <small>{notification.added}</small>
                                                <button type="button" class="close ml-2 mb-1" data-dismiss="toast" onClick={(event) => { markAsRead(notification.id); event.stopPropagation(); }}><span aria-hidden="true">×</span>
                                                    <span class="sr-only">Zamknij</span>
                                                </button>
                                            </div>
                                            <Toast.Body className={styles.toastBody}>{notification.description}</Toast.Body>

                                        </Col>
                                    </Row>
                                </Toast>
                            </>
                        )
                    })
                } else {
                    result = <p>Brak powiadomień</p>
                }

                setBadge(res.data.length);
                setNotifications(result);

            } else {
                props.history.push({
                    pathname: '/error',
                    search: '?page=Header&no=' + res.status + '&text=' + res.text + '&url=notifications.php'
                });
            }
        })
    }

    const handleClose = () => setShow(false);
    const handleShow = () => refreshNotifications();

    const handlerSearch = (event) => {
        if (searchInput === '') toast.error("Uzupełnij pole wyszukiwania!");
        else if (searchInput.length < 3) toast.error("Wprowadź przynajmniej 3 znaki!");
        else {
            cookies.set('searchData', { textInput: searchInput, startDate: null, endDate: null, multiSelect: null }, { path: '/' });
            window.location.href = "/wyszukiwarka";
        }

        event.preventDefault();
    }

    useEffect(() => {
        if (token !== undefined) {
            http_post(`notifications_badge.php`, { token: token }).then((res) => {
                if (res.status === 200) {
                    setBadge(res.data);
                }
            })
        }
    }, []);

    useEffect(() => {
        if (notifications !== null)
            setShow(true);
    }, [notifications]);

    return (
        <Navbar fixed="top" collapseOnSelect expand="lg" style={{ backgroundColor: "#f77f00", marginBottom: "56px" }}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand href="/" style={{ color: "white" }}>
                <img
                    alt=""
                    src="https://archiwum.pomaranczarnia.org/img/icons/archiwum_banner_long_trans.png"
                    height="30px"
                    className="d-none d-sm-inline align-top"
                />
                <img
                    alt=""
                    src="https://archiwum.pomaranczarnia.org/img/icons/archiwum_logo_w_on_trans.png"
                    height="30px"
                    className="d-inline d-sm-none align-top"
                />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">


                {rank === undefined ?
                    <Nav className="mr-auto"></Nav>
                    :
                    <Nav className="mr-auto"></Nav>
                }

                <Nav>
                    {rank === undefined ?
                        <Button variant="secondary" href="/logowanie"><LatoBold>Zaloguj się</LatoBold></Button>
                        :
                        <>
                            <Form noValidate onSubmit={(event) => { handlerSearch(event) }}>
                                <ButtonToolbar>
                                    <InputGroup className="mr-1">
                                        <FormControl type="text" onChange={(event) => { setInput(event.target.value) }} placeholder="Wyszukaj zdjęcia" />
                                    </InputGroup>
                                    <ButtonGroup className="mr-2" aria-label="First group">
                                        <Button type="submit" variant="dark"><LatoBold>Szukaj</LatoBold></Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Form>
                            &nbsp;
                            <Button href="/profil" variant="secondary">
                            <LatoBold>{nick}</LatoBold>
                            </Button>
                            &nbsp;
                            <Button variant="secondary" onClick={handleShow}>
                            <LatoBold>Powiadomienia <Badge variant="light">{badge}</Badge></LatoBold>
                            </Button>
                            &nbsp;
                            <Button href="/wyloguj" variant="light">
                            <LatoBold>Wyloguj</LatoBold>
                            </Button>
                            {rank !== "1" &&
                                <>
                                    &nbsp;
                                    <Button href="/administrator" variant="danger">
                                        <FontAwesomeIcon icon={faTools}  />
                                    </Button>
                                </>
                            }
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Powiadomienia</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{notifications}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}><LatoBold>Zamknij</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}


export default Header;