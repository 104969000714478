import React from "react";
import { Container, Spinner } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfo, faFolder } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

const { SearchBar } = Search;

const months = [
    { value: 1, label: "Styczeń" },
    { value: 2, label: "Luty" },
    { value: 3, label: "Marzec" },
    { value: 4, label: "Kwiecień" },
    { value: 5, label: "Maj" },
    { value: 6, label: "Czerwiec" },
    { value: 7, label: "Lipiec" },
    { value: 8, label: "Sierpień" },
    { value: 9, label: "Wrzesień" },
    { value: 10, label: "Październik" },
    { value: 11, label: "Listopad" },
    { value: 12, label: "Grudzień" }
];

const months2 = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień"
]

class AdminPhotos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "label",
            sortOrder: "asc",
            newFolderInput: "",
            descriptionInput: "",
            folderYearInput: null,
            miniOption: 0,
            miniUploadingInProgress: false,
            miniDefault: null,
            miniPhoto: null,
            modal_miniOption: 0,
            modal_miniUploadingInProgress: false,
            modal_miniPhoto: null,
            modal_miniSelectOptions: [],
            modal_miniSelected: null,
            editDescriptionInput: "",
            editMiniOption: 0,
            formErrors: {
                newFolderInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                descriptionInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                miniOption: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                folderYearInput: {
                    isValid: false,
                    isInvalid: true,
                    info: ""
                },
                editDescriptionInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                editMiniOption: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                yearSelect: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                folderSelect: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            show: false,
            modalDelete: {
                mini: null,
                value: null
            },
            modalPreview: {
                value: null,
                loading: true
            },
            modalFolder: {
                value: null,
                loading: true
            },
            yearSelect: null,
            folderSelect: null,
            tagSelect: null,
            multiSelect: null,
            descriptionSelect: "",
            monthSelect: null,
            daySelect: null,
            yearInherited: "2012",
            onSelect: []
        };

        this.refreshData = this.refreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
        this.handleChangeFolder = this.handleChangeFolder.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
        this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    componentDidMount() {
        this.refreshDataManually();

        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`years.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ years: res.data });

            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step1&no=' + res.status + '&text=' + res.text + '&url=years.php'
                });
            }
        })

        http_post(`tags.php`, data).then((res) => {
            if (res.status === 200) {
                this.setState({ tags: res.data });
            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step2&no=' + res.status + '&text=' + res.text + '&url=tags.php'
                });
            }
        })

    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }



        http_post(`photosTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.photos.map((row) => {
                    row.photo = <><Link to={"/galeria/" + row.value} className="colLink"><img src={row.mini} /></Link></>;
                    row.button = <>
                        <Button variant="secondary" onClick={() => { this.handleChangeFolder([row.value]) }}><FontAwesomeIcon icon={faFolder} /></Button>
                        <Button className="ml-1" variant="primary" onClick={() => { this.handlePreview([row.value]) }}><FontAwesomeIcon icon={faInfo} /></Button>
                        <Button className="ml-1" variant="danger" onClick={() => { this.handleDelete([row.value], row.mini) }}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>;
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=photosTable.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleClose2() {

        this.setState({ show2: !this.state.show2, modal_miniPhoto: null })
        this.resetValid();
    }

    handleClose3() {

        this.setState({ show3: !this.state.show3 })
    }

    handleDelete(value, mini) {
        this.setState({
            modalDelete: {
                value: value,
                mini: mini
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    handlePreview(value) {
        this.setState({
            modalPreview: {
                loading: true
            }
        }, () => {

            this.setState({ show2: true }, () => {

                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    value: value
                }

                http_post(`photoTableModal.php?details`, data).then((res) => {
                    if (res.status === 200) {

                        this.setState({
                            tagSelect: res.data.tags,
                            descriptionSelect: res.data.description,
                            yearInherited: res.data.year,
                            monthSelect: (res.data.month === null ? null : { value: parseInt(res.data.month), label: months2[res.data.month - 1] }),
                            daySelect: (res.data.day === null ? null : { value: res.data.day, label: res.data.day }),
                            modalPreview: {
                                loading: false,
                                value: value
                            }
                        }, () => {

                            if (this.state.monthSelect !== null) this.getDays();

                        })

                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=photoTabelModal.php'
                        });
                    }
                })
            })
        })
    }

    handleChangeFolder(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value[0]
        }
        http_post(`photoTableModal.php?directory`, data).then((res) => {
            if (res.status === 200) {

                this.setState({
                    show3: true,
                    yearSelect: {
                        label: res.data.year,
                        value: res.data.yearId,
                        hidden: res.data.yearHidden
                    }

                }, () => {
                    this.getFolders();
                    this.setState({
                        modalFolder: {
                            loading: false,
                            value: value
                        },
                        folderSelect: {
                            label: res.data.folder,
                            value: res.data.folderId,
                            hidden: res.data.folderHidden
                        }
                    })
                })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminPhotos&no=' + res.status + '&text=' + res.text + '&url=photoTableModal.php'
                });
            }
        })
    }

    getFolders() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            year: this.state.yearSelect.value
        }
        http_post(`folders.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ folders: res.data });

            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step1&no=' + res.status + '&text=' + res.text + '&url=folders.php'
                });
            }
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: JSON.stringify(value)
        }
        http_post(`deletePhoto.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false, onSelect: [] }, () => { toast.success("Poprawnie usunięto zdjęcie!") });
                    this.refreshDataManually('pagination');
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć zdjęcia!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    handleChangeSelect(event) {

        event.preventDefault();
        switch (event.target.name) {
            default:
                this.setState({
                    [event.target.name]: Number(event.target.value),
                });
                break;
        }
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            newFolderInput: "",
            descriptionInput: "",
            folderYearInput: null,
            miniOption: 0,
            miniPhoto: null,
            descriptionInput: ""
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        switch (column.dataField) {
            case "hidden":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (oldValue === newValue) {
                        done(false);
                    } else {
                        http_post(`editPhoto.php?hidden`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono widoczność!")
                                    if (res.data.rr) {
                                        done(false);
                                        this.refreshDataManually('pagination');
                                    } else {
                                        done();
                                    }
                                } else {
                                    toast.error("Nie udało się zmienić widoczności!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }

                }, 0);
                break;
        }
        return { async: true };
    }

    handleSubmit(event) {

        let valid = true;
        const { newFolderInput, descriptionInput, miniOption, formErrors, folderYearInput, miniPhoto } = this.state;

        if (folderYearInput === null) {
            formErrors.folderYearInput.info = "Wybierz rok!";
            formErrors.folderYearInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.folderYearInput.isValid = true;
            formErrors.folderYearInput.isInvalid = false;
        }

        if (newFolderInput <= 0) {
            formErrors.newFolderInput.info = "Wprowadź nazwę folderu!";
            formErrors.newFolderInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.newFolderInput.isValid = true;
            formErrors.newFolderInput.isInvalid = false;
        }

        if (descriptionInput <= 0) {
            formErrors.descriptionInput.info = "Wprowadź opis folderu!";
            formErrors.descriptionInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.descriptionInput.isValid = true;
            formErrors.descriptionInput.isInvalid = false;
        }

        if (miniOption === 0) {
            formErrors.miniOption.info = "Wybierz opcje miniatury!";
            formErrors.miniOption.isInvalid = true;
            valid = false;
        } else {

            if (miniOption === "2") {
                if (miniPhoto === null) {
                    formErrors.miniOption.info = "Wgraj zdjęcie do miniatury!";
                    formErrors.miniOption.isInvalid = true;
                    valid = false;
                } else {
                    formErrors.miniOption.isValid = true;
                    formErrors.miniOption.isInvalid = false;
                }

            } else {
                formErrors.miniOption.isValid = true;
                formErrors.miniOption.isInvalid = false;
            }
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                label: newFolderInput,
                desc: descriptionInput,
                miniOption: miniOption,
                year: folderYearInput.value,
                image: miniPhoto
            }

            http_post(`newFolder.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie dodano folder!") });
                        this.refreshDataManually();
                        this.resetValid();
                    } else if (res.data.valid === 2) {
                        this.setState({ show: false }, () => { toast.error("Folder o tej nazwie w przypisanym roku już istnieje!") });
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się dodać folderu!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })


        } else event.stopPropagation();

        event.preventDefault();
    }

    updateDetails(value) {
        let valid = true;
        const { tagSelect, descriptionSelect, monthSelect, daySelect } = this.state;

        if (valid) {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                pid: JSON.stringify(value),
                tags: (tagSelect === null ? JSON.stringify([]) : JSON.stringify(tagSelect)),
                description: descriptionSelect,
                month: (monthSelect === null ? null : monthSelect.value),
                day: (daySelect === null ? null : daySelect.value)
            }

            http_post(`photosTableModalEdit.php?details`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false, onSelect: [] }, () => { toast.success("Poprawnie edytowano szczegóły!") });
                        this.refreshDataManually();
                        this.handleClose2();
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się edytować szczegółów!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.success("Błąd transmisji danych!") });
                }
            })
        }

    }

    updateFolder(value) {

        let valid = true;
        const { formErrors, yearSelect, folderSelect } = this.state;

        if (yearSelect === null) {
            formErrors.yearSelect.info = "Wybierz rok!";
            formErrors.yearSelect.isInvalid = true;
            valid = false;
        } else {
            formErrors.yearSelect.isValid = true;
            formErrors.yearSelect.isInvalid = false;
        }

        if (folderSelect === null) {
            formErrors.folderSelect.info = "Wybierz folder!";
            formErrors.folderSelect.isInvalid = true;
            valid = false;
        } else {
            formErrors.folderSelect.isValid = true;
            formErrors.folderSelect.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });
        if (valid) {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                pid: JSON.stringify(value),
                fid: folderSelect.value,
                cid: yearSelect.value
            }
            http_post(`photosTableModalEdit.php?directory`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false, onSelect: [] }, () => { toast.success("Poprawnie edytowano szczegóły!") });
                        this.refreshDataManually();
                        this.handleClose3();
                    } else {
                        this.setState({ show: false }, () => { toast.success("Nie udało się edytować szczegółów!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.success("Błąd transmisji danych!") });
                }
            })
        }

    }

    handleOnSelect(row, isSelect) {

        let tab = this.state.onSelect;
        let newTab = [];

        if (isSelect) {
            tab.push(row.value)
            this.setState({ onSelect: tab })
        } else {
            newTab = tab.filter((item) => item !== row.value)
            this.setState({ onSelect: newTab })
        }

    }

    handleOnSelectAll(isSelect, rows) {
        if (isSelect) {
            let sel = [...this.state.onSelect];
            for (let i in rows) {
                sel.push(rows[i].value);
            }
            this.setState({ onSelect: sel })
        } else {
            let sel = [...this.state.onSelect];
            for (let i in rows) {
                if (sel.includes(rows[i].value))
                    sel.splice(sel.indexOf(rows[i].value), 1);
            }
            this.setState({ onSelect: sel })
        }
    }

    getDays() {
        if (this.state.monthSelect === null) {
            this.setState({ days: [] });
        } else {
            const m = this.state.monthSelect.value;
            let days;
            if (m === 1 || m === 3 || m === 5 || m === 7 || m === 8 || m === 10 || m === 12)
                days = 31;
            else if (m === 2)
                days = (parseInt(this.state.yearInherited) % 4 === 0 && parseInt(this.state.yearInherited) % 100 !== 0 ? 29 : 28);
            else
                days = 30;
            let result = [];
            for (let i = 1; i <= days; i++)
                result.push({ value: i, label: i });
            this.setState({ days: result });
        }
    }

    render() {



        const columns = [{
            dataField: 'photo',
            text: 'Zdjęcie',
            sort: true,
            editorStyle: {
                textAlign: "center"
            },
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '171px' };
            }
        }, {
            dataField: 'folder',
            text: 'Folder',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'added',
            text: 'Data dodania',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '160px' };
            }
        }, {
            dataField: 'year',
            text: 'Przypisany rok',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '160px' };
            }
        }, {
            dataField: 'hidden',
            text: 'Ukryty',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editCellStyle: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: [{
                    value: 'TAK',
                    label: 'TAK'
                }, {
                    value: 'NIE',
                    label: 'NIE'
                }]
            },
            headerStyle: (colum, colIndex) => {
                return { width: '120px' };
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '150px' };
            }
        }];

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            selectColumnStyle: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            selected: this.state.onSelect,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        const defaultSorted = [{
            dataField: 'label',
            order: 'asc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        const customStyles = {
            control: base => ({
                ...base,
                height: 150
            }),
            control: (provided, state) => ({
                ...provided,
                height: 150
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: 150
            })

        }

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={2}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>ZDJĘCIA</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Lista zdjęć</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.photos}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <Row>
                                                        <Col sm={12} lg={6}>
                                                            Zaznaczone:
                                                        <Button className="ml-1" disabled={this.state.onSelect.length === 0} variant="secondary" onClick={() => { this.handleChangeFolder(this.state.onSelect) }}><FontAwesomeIcon icon={faFolder} /></Button>
                                                            <Button disabled={this.state.onSelect.length === 0} className="ml-1" variant="primary" onClick={() => { this.handlePreview(this.state.onSelect) }}><FontAwesomeIcon icon={faInfo} /></Button>
                                                            <Button disabled={this.state.onSelect.length === 0} className="ml-1" variant="danger" onClick={() => { this.handleDelete(this.state.onSelect, null) }}><FontAwesomeIcon icon={faTrash} /></Button>
                                                        &nbsp;(zaznaczonych: <strong>{this.state.onSelect.length}</strong>)
                                                        </Col>
                                                        <Col sm={12} lg={6} className="text-right">
                                                            Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                        </Col>
                                                    </Row>

                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak zdjęć!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                        selectRow={selectRow}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Usuwanie zdjęcia</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć wybrane zdjęcie?</p>
                                    {this.state.modalDelete.mini === null ?
                                        <>
                                            <p>Zaznaczonych elementów: {this.state.modalDelete.value === null ? '0' : this.state.modalDelete.value.length}</p>
                                        </>
                                        :
                                        <>
                                            <img src={this.state.modalDelete.mini} />
                                        </>
                                    }

                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.show2} onHide={() => { this.handleClose2() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Szczegóły zdjęcia</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    {this.state.modalPreview.loading ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Tagi</Form.Label>
                                                    <Select isMulti={true} isSearchable={true} value={this.state.tagSelect} placeholder={"Wybierz tagi..."} options={this.state.tags} onChange={(dd) => this.setState({ tagSelect: dd, step3visited: false })} noOptionsMessage={(dd) => {
                                                        if (dd.inputValue !== "") {
                                                            return "Nie znaleziono: " + dd.inputValue
                                                        } else {
                                                            return "Brak tagów!";
                                                        }
                                                    }} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Opis</Form.Label>
                                                    <Form.Control as="textarea" rows="3" value={this.state.descriptionSelect} name="descriptionSelect" onChange={this.handleChange} placeholder="Wprowadź opis..." />
                                                </Form.Group>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Rok</Form.Label>
                                                        <Form.Control value={this.state.yearInherited} disabled />
                                                        <Form.Text className="text-muted">Rok dziedziczony jest z wybranego folderu.</Form.Text>
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Miesiąc</Form.Label>
                                                        <Select isClearable={true} isMulti={false} isSearchable={false} value={this.state.monthSelect} placeholder={"Wybierz..."} options={months} onChange={(dd) => this.setState({ monthSelect: dd, daySelect: null }, () => this.getDays())} />
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Dzień</Form.Label>
                                                        <Select isClearable={true} isMulti={false} isSearchable={false} value={this.state.daySelect} placeholder={"Wybierz..."} options={this.state.days} onChange={(dd) => this.setState({ daySelect: dd })} noOptionsMessage={(dd) => {
                                                            if (dd.inputValue !== "") {
                                                                return "Nie znaleziono: " + dd.inputValue
                                                            } else {
                                                                return "Najpierw wybierz miesiąc!";
                                                            }
                                                        }} />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>



                                        </>
                                    }
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose2() }} variant="outline-primary">Anuluj</Button>
                                    <Button disabled={this.state.modalPreview.loading} onClick={() => { this.updateDetails(this.state.modalPreview.value) }} variant="primary"><LatoBold>Zapisz</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.show3} onHide={() => { this.handleClose3() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Ścieżka zdjęcia</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    {this.state.modalFolder.loading ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Rok</Form.Label>
                                                    <Select isMulti={false} isSearchable={true} value={this.state.yearSelect} placeholder={"Wybierz rok..."} options={this.state.years} onChange={(dd) => this.setState({ yearSelect: dd, folderSelect: null }, () => this.getFolders())} noOptionsMessage={(dd) => {
                                                        if (dd.inputValue !== "") {
                                                            return "Nie znaleziono: " + dd.inputValue
                                                        } else {
                                                            return "Brak lat!";
                                                        }
                                                    }} />
                                                    <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.yearSelect.isValid} isInvalid={this.state.formErrors.yearSelect.isInvalid} />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.yearSelect.info}</Form.Control.Feedback>
                                                    <Form.Text className="text-muted">
                                                        Wybierz rok, z którego zostaną wyświetlone dostępne foldery.
                                                    </Form.Text>
                                                    {
                                                        this.state.yearSelect !== null &&
                                                        <Form.Text className="text-muted">
                                                            Wybrany kontener z rokiem jest <b>{this.state.yearSelect.hidden === '1' ? 'ukryty' : 'opublikowany'}</b>.
                                                    </Form.Text>
                                                    }
                                                </Form.Group>
                                                {
                                                    this.state.yearSelect !== null &&
                                                    (
                                                        this.state.folders === null ?
                                                            <Spinner animation="grow" variant="warning" />
                                                            :
                                                            <>
                                                                <Form.Group>
                                                                    <Form.Label>Folder</Form.Label>
                                                                    <Select isMulti={false} isSearchable={true} value={this.state.folderSelect} placeholder={"Wybierz folder..."} options={this.state.folders} onChange={(dd) => this.setState({ folderSelect: dd })} noOptionsMessage={(dd) => {
                                                                        if (dd.inputValue !== "") {
                                                                            return "Nie znaleziono: " + dd.inputValue
                                                                        } else {
                                                                            return "Brak folderów!";
                                                                        }
                                                                    }} />
                                                                    <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.folderSelect.isValid} isInvalid={this.state.formErrors.folderSelect.isInvalid} />
                                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.folderSelect.info}</Form.Control.Feedback>
                                                                    <Form.Text className="text-muted">
                                                                        Wybierz folder do którego zostaną wgrane zdjęcia.
                                                </Form.Text>
                                                                    {
                                                                        this.state.folderSelect !== null &&
                                                                        <Form.Text className="text-muted">
                                                                            Wybrany folder jest <b>{this.state.folderSelect.hidden === '1' ? 'ukryty' : 'opublikowany'}</b>.
                                                    </Form.Text>
                                                                    }
                                                                </Form.Group>
                                                            </>

                                                    )
                                                }
                                            </Form>


                                        </>
                                    }
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose3() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.updateFolder(this.state.modalFolder.value) }} variant="primary"><LatoBold>Zapisz</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner >
        );
    }
}

export default AdminPhotos;