import React from "react";
import { FileDrop } from 'react-file-drop';
import imageCompression from 'browser-image-compression';
import { ToastContainer, toast } from 'react-toastify';
import heic2any from "heic2any";
import VisibilitySensor from "react-visibility-sensor";
import { Row, Col, Navbar, Breadcrumb, Card, Button, Container, Tabs, Tab, Form, ProgressBar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.inputFile = React.createRef(null);

        this.state = {
            progress: 0,
            showPreview: false,
            imgPreview: false
        };
    }

    edit(what, val, index) {
        let result = this.props.thisRef.state.step3images;
        for (let i = 0; i < result.length; i++) {
            if (i === index) {
                if (what === 'tags') {
                    result[i].tags = val;
                } else if (what === 'month') {
                    result[i].month = val;
                    result[i].day = null;
                    if (val === null)
                        result[i].daysOptions = [];
                    else
                        result[i].daysOptions = this.getDays(val.value);
                } else if (what === 'day') {
                    result[i].day = val;
                } else if (what === 'hidden') {
                    result[i].hidden = val;
                }
            }
        }
        this.props.thisRef.setState({ step3images: result });
    }

    setProgress(event, i, l) {
        let p;
        if (event.lengthComputable)
            p = parseInt(((event.loaded / event.total) * 100) / l + (i / l * 100), 10);
        else
            p = parseInt(i / l * 100, 10);
        this.setState({ progress: p });

        if (i === l - 1) {
            this.props.thisRef.setState({ step3loading: false });
        }
    }

    readFile1 = (file, i, l) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.addEventListener('progress', (event) => this.setProgress(event, i, l));
            reader.readAsDataURL(file);
        })
    }

    readFile2 = event => {
        const blob2 = window.btoa(event.target.result);
        const blob = b64toBlob(blob2, 'image/heic');
        const thisRef = this.props.thisRef;
        heic2any({ blob }).then((conversionResult) => {
            const url = URL.createObjectURL(conversionResult);
            const item = { tags: this.props.thisRef.state.step2tags, description: React.createRef(null), month: this.props.thisRef.step2month, day: this.props.thisRef.step2day, daysOptions: (this.props.thisRef.state.step2month === null ? [] : this.getDays(this.props.thisRef.state.step2month.value)), hidden: this.props.thisRef.state.step2hidden, src: url };
            thisRef.setState({ step3images: [...thisRef.state.step3images, item], step3imagesCount: parseInt(thisRef.state.step3imagesCount) + 1 });
        }).catch((e) => {
            toast.error(e);
        });
    }

    onClick() {
        this.inputFile.current.value = null;
        this.inputFile.current.click();
        this.setState({ progress: 0 });
    }

    async onFileChange(e) {
        if (e.files && e.files.length > 0) {
            if (this.props.thisRef.state.step3imagesCount + e.files.length > 50) {
                toast.error("Można wgrać maksymalnie 50 zdjęć na raz!")
            } else {
                if (!this.props.thisRef.state.step3loading) {
                    this.props.thisRef.setState({ step3loading: true });
                }

                for (let i = 0; i < e.files.length; i++) {
                    if (validate(e.files[i], i)) {
                        let file = e.files[i];

                        if (file.name.toLowerCase().split('.').pop() === 'heic') {
                            var reader = new FileReader();
                            reader.addEventListener('load', this.readFile2);
                            reader.addEventListener('progress', (event) => this.setProgress(event, i, e.files.length));
                            reader.readAsBinaryString(file);
                        } else {
                            const options = {
                                maxSizeMB: 3,
                                maxWidthOrHeight: 1920,
                                useWebWorker: true
                            }

                            try {
                                const fileComp = await imageCompression(file, options);
                                let imageDataUrl = await this.readFile1(fileComp, i, e.files.length);
                                const item = { tags: this.props.thisRef.state.step2tags, description: React.createRef(null), month: this.props.thisRef.state.step2month, day: this.props.thisRef.state.step2day, daysOptions: (this.props.thisRef.state.step2month === null ? [] : this.getDays(this.props.thisRef.state.step2month.value)), hidden: this.props.thisRef.state.step2hidden, src: imageDataUrl };
                                this.props.thisRef.setState({ step3images: [...this.props.thisRef.state.step3images, item], step3imagesCount: parseInt(this.props.thisRef.state.step3imagesCount) + 1 });
                            } catch (error) {
                                toast.error(error);
                            }
                        }
                    }
                }
            }
        } else {
            toast.error("Błąd przesyłania");
        }
    }

    removePhoto(index) {
        let arr = this.props.thisRef.state.step3images;
        arr.splice(index, 1);
        this.props.thisRef.setState({ step3images: arr, step3imagesCount: parseInt(this.props.thisRef.state.step3imagesCount) - 1 });
    }

    showPreview(index) {
        if (this.state.showPreview) this.setState({ showPreview: false, imgPreview: index });
        else this.setState({ imgPreview: index }, () => { this.setState({ showPreview: true }) });
    }

    getDays(m) {
        let days;
        if (m === 1 || m === 3 || m === 5 || m === 7 || m === 8 || m === 10 || m === 12)
            days = 31;
        else if (m === 2)
            days = (parseInt(this.props.thisRef.state.step1year.label) % 4 === 0 && parseInt(this.props.thisRef.state.step1year.label) % 100 !== 0 ? 29 : 28);
        else
            days = 30;

        let result = [];
        for (let i = 1; i <= days; i++)
            result.push({ value: i, label: i });
        return result;
    }

    render() {

        const months = [
            { value: 1, label: "Styczeń" },
            { value: 2, label: "Luty" },
            { value: 3, label: "Marzec" },
            { value: 4, label: "Kwiecień" },
            { value: 5, label: "Maj" },
            { value: 6, label: "Czerwiec" },
            { value: 7, label: "Lipiec" },
            { value: 8, label: "Sierpień" },
            { value: 9, label: "Wrzesień" },
            { value: 10, label: "Październik" },
            { value: 11, label: "Listopad" },
            { value: 12, label: "Grudzień" }
        ];

        return (
            <>
                <Modal size="lg" show={this.state.showPreview} onHide={() => { this.showPreview() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Podgląd zdjęcia</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            this.props.thisRef.state.step3images[this.state.imgPreview] === undefined ?
                                <>Nie znaleziono zdjęcia</>
                                :
                                <img style={{ width: "100%" }} src={this.props.thisRef.state.step3images[this.state.imgPreview].src} />
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => { this.showPreview() }} variant="secondary">Zamknij</Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer />
                <VisibilitySensor
                    onChange={isVisible => {
                        if (isVisible) {
                            this.props.cf();
                        }
                    }}
                    delayedCall
                >
                    <>
                        <Card.Title>Ścieżka wgrywania</Card.Title>
                        <Breadcrumb>
                            <Breadcrumb.Item>Galeria</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.props.thisRef.state.step1year !== null ? this.props.thisRef.state.step1year.label : ""}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.props.thisRef.state.step1folder !== null ? this.props.thisRef.state.step1folder.label : ""}</Breadcrumb.Item>
                        </Breadcrumb>
                        {!this.props.thisRef.state.step3loading &&
                            <>

                                <Card.Title>Wybierz zdjęcia</Card.Title>
                                <Container
                                    fluid
                                    style={{ cursor: 'pointer', backgroundColor: '#EBEDED', padding: 40, height: 182, border: 'thin dashed black' }}
                                    onClick={() => this.onClick()}
                                >

                                    <FileDrop
                                        onDrop={(files, event) => { this.inputFile.current.value = null; this.onFileChange({ files: files }); }}
                                    >
                                        Wybierz lub upuść zdjęcia tutaj
                                <img style={{ width: 100 }} src="./../../img/icons/drop_icon.svg" />
                                        <Form.Text muted>Akceptowane formaty: jpg, png, bmp, jpeg, heic</Form.Text>
                                    </FileDrop>
                                    <input type='file' id='file' multiple ref={this.inputFile} onChange={(e) => this.onFileChange(e.target)} accept=".jpg,.png,.bmp,.jpeg,.heic" style={{ display: 'none' }} />
                                </Container>
                            </>
                        }
                        {
                            this.props.thisRef.state.step3loading &&
                            <>
                                Trwa wgrywanie zdjęć...
                                <ProgressBar style={{ height: "50px" }} animated striped label={`${this.state.progress}%`} now={this.state.progress} />
                            </>
                        }
                        {
                            (this.props.thisRef.state.step3imagesCount > 0 && this.props.thisRef.state.step3loading === false) &&
                            <>
                                <Card.Title className="mt-2">Wgrane zdjęcia ({this.props.thisRef.state.step3imagesCount} z 50)</Card.Title>
                                <Row>
                                    {
                                        this.props.thisRef.state.step3images.map((photo, index) => {
                                            return <>
                                                <Col xl={12} key={index + 'a'}>
                                                    <Row key={index + 'b'}>
                                                        <Col lg={3} key={index + 'c'}>
                                                            <Col lg={12} key={index + 'cdd'}>
                                                                <img src={photo.src} className="commentImg" key={index + 'd'} />
                                                            </Col>
                                                            <Col className="mt-2" lg={12} key={index + 'cddd'}>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id={index + 'ad'}
                                                                    key={index + 'ad'}
                                                                    label="Ukryj zdjęcie"
                                                                    checked={photo.hidden}
                                                                    onChange={(dd) => this.edit('hidden', dd.target.checked, index)}
                                                                />
                                                            </Col>
                                                            <Col className="mt-2" lg={12} key={index + 'ae'}>
                                                                <Button key={index + 'af'} onClick={() => this.showPreview(index)} ><FontAwesomeIcon icon={faSearch} /></Button> <Button variant="danger" key={index + 'dd'} onClick={() => this.removePhoto(index)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                            </Col>
                                                        </Col>
                                                        <Col lg={9} key={index + 'e'}>
                                                            <Row key={index + 'f'}>
                                                                <Col lg={12} key={index + 'g'}>
                                                                    <Form.Group key={index + 'h'}>
                                                                        <Form.Label key={index + 'i'}>Tagi</Form.Label>
                                                                        <Select key={index + 'j'} isMulti={true} isSearchable={true} value={photo.tags} placeholder={"Wybierz tagi..."} options={this.props.thisRef.state.step2tagsPossible} onChange={(dd) => this.edit('tags', dd, index)} noOptionsMessage={(dd) => {
                                                                            if (dd.inputValue !== "") {
                                                                                return "Nie znaleziono: " + dd.inputValue
                                                                            } else {
                                                                                return "Brak tagów!";
                                                                            }
                                                                        }} />
                                                                    </Form.Group>
                                                                    <Form.Group key={index + 'l'}>
                                                                        <Form.Label key={index + 'm'}>Opis</Form.Label>
                                                                        <Form.Control key={index + 'n'} as="textarea" rows="3" defaultValue={this.props.thisRef.state.step2description.current === null ? '' : this.props.thisRef.state.step2description.current.value} ref={photo.description} placeholder="Wprowadź opis..." />
                                                                    </Form.Group>
                                                                    <Form.Row key={index + 'p'}>
                                                                        <Form.Group key={index + 'w'} as={Col}>
                                                                            <Form.Label key={index + 'x'}>Miesiąc</Form.Label>
                                                                            <Select key={index + 'y'} isClearable={true} isMulti={false} isSearchable={false} value={photo.month} placeholder={"Wybierz miesiąc..."} options={months} onChange={(dd) => this.edit('month', dd, index)} />
                                                                        </Form.Group>

                                                                        <Form.Group key={index + 'z'} as={Col}>
                                                                            <Form.Label key={index + 'aa'}>Dzień</Form.Label>
                                                                            <Select key={index + 'ab'} isClearable={true} isMulti={false} isSearchable={false} value={photo.day} placeholder={"Wybierz dzień..."} options={photo.daysOptions} onChange={(dd) => this.edit('day', dd, index)} noOptionsMessage={(dd) => {
                                                                                if (dd.inputValue !== "") {
                                                                                    return "Nie znaleziono: " + dd.inputValue
                                                                                } else {
                                                                                    return "Najpierw wybierz miesiąc!";
                                                                                }
                                                                            }} />
                                                                        </Form.Group>
                                                                    </Form.Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                </Col>
                                            </>
                                        })
                                    }
                                </Row>
                            </>
                        }
                    </>
                </VisibilitySensor>
            </>
        );
    }
}

function isFileImage(file) {
    const acceptedImageTypes = ['jpeg', 'png', 'bmp', 'jpg', 'heic'];

    return file && acceptedImageTypes.includes(file.name.toLowerCase().split('.').pop());
}

function validate(input, i) {
    if (input) {
        if (isFileImage(input)) {
            return true;
        } else {
            toast.error("Niewłaściwy format obrazka nr " + (i + 1));
            return false;
        }
    } else {
        toast.error("Błąd przesyłania obrazka");
        return false;
    }
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export default Step3;