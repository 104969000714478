import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import '../styles/LikeButton.css';
import LatoBold from './LatoBold';

class LikeButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageId: props.id,
            likes: props.likes,
            like: props.like,
            disabled: false
        };

        this.handleLike = this.handleLike.bind(this);

    }

    handleLike(event) {
        if (!this.state.disabled) {
            this.setState({ disabled: true });
            const cookies = new Cookies();
            const token = cookies.get('token');

            const { likes, like, imageId } = this.state;
            if (like) {

                const data = {
                    token: token,
                    liked: 'false',
                    image: imageId
                }

                http_post(`like.php`, data).then((res) => {

                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            this.setState({
                                likes: parseInt(likes) - 1,
                                like: false
                            })
                            toast.info("Zdjęcie usunięte z ulubionych!");
                            setTimeout(() => { this.setState({ disabled: false }); }, 3000);
                            //dodawanie plusa
                        } else {
                            toast.info("Wystąpił błąd. Proszę spróbować później.");
                            this.setState({ disabled: false });
                        }
                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=LikeButton&no=' + res.status + '&text=' + res.text + '&url=like.php'
                        });
                    }
                })

            } else {

                const data = {
                    token: token,
                    liked: 'true',
                    image: imageId
                }

                http_post(`like.php`, data).then((res) => {

                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            this.setState({
                                likes: parseInt(likes) + 1,
                                like: true
                            })
                            toast.info("Zdjęcie dodane do ulubionych!");
                            setTimeout(() => { this.setState({ disabled: false }); }, 3000);
                            //usuwanie plusa
                        } else {
                            toast.info("Wystąpił błąd. Proszę spróbować później.");
                            this.setState({ disabled: false });
                        }
                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=LikeButton&no=' + res.status + '&text=' + res.text + '&url=like.php'
                        });
                    }
                })
            }
        }
        event.preventDefault();

    }

    render() {

        return (
            <>
                {this.state.like ?
                    <Button disabled={this.state.disabled} onClick={this.handleLike} style={{ marginBottom: "15px" }} variant="like" block>
                        {
                            this.state.disabled ? <Spinner animation="border" variant="primary" size="sm" /> : <LatoBold>Usuń z ulubionych <Badge pill variant="primary">{this.state.likes}</Badge></LatoBold>
                        }
                    </Button>
                    :
                    <Button disabled={this.state.disabled} onClick={this.handleLike} style={{ marginBottom: "15px" }} variant="outline-like" block>
                        {
                            this.state.disabled ? <Spinner animation="border" variant="primary" size="sm" /> : <LatoBold>Dodaj do ulubionych <Badge pill variant="primary">{this.state.likes}</Badge></LatoBold>
                        }
                    </Button>
                }
            </>
        )
    }

}


export default LikeButton;