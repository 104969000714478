import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, TableHeaderColumn } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/AdminUsers.module.css';
import Select from 'react-select';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

function nl2br(arr) {
    return arr.split('\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>
    })
}

const { SearchBar } = Search;

class AdminBans extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "label",
            sortOrder: "asc",
            user: null,
            reason: "",
            dateTo: "",
            formErrors: {
                user: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                reason: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                dateTo: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            show: false,
            modalDelete: {
                label: "",
                value: null
            }
        };

        this.refreshData = this.refreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    componentDidMount() {

        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`users.php`, data).then((res) => {
            if (res.status === 200) {

                let tab = [];

                for (let i in res.data) {
                    tab.push({ value: res.data[i].id, label: res.data[i].name + " (" + res.data[i].email + ")" })
                }

                this.setState({
                    userOptions: tab,
                    user: null
                })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=users.php'
                });
            }
        })

        this.refreshDataManually();
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }

        http_post(`bansTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.bans.map((row) => {
                    row.photo = <img className={styles.avatar} src={row.avatar} />
                    row.name = nl2br(row.name)
                    row.dateTo = (row.dateTo === null ? "nigdy" : row.dateTo.substring(0, row.dateTo.length - 9))
                    row.dateFrom = row.dateFrom.substring(0, row.dateFrom.length - 9);
                    row.button = <Button variant="danger" onClick={() => { this.handleDelete(row.id) }}><FontAwesomeIcon icon={faTrash} /></Button>

                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminTags&no=' + res.status + '&text=' + res.text + '&url=tags.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleDelete(value, label) {
        this.setState({
            modalDelete: {
                value: value,
                label: label
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteBan.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false }, () => { toast.success("Poprawnie usunięto blokadę!") });
                    this.refreshDataManually('pagination');
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć blokady!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            user: null,
            reason: "",
            dateTo: ""
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        setTimeout(() => {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                value: row.value,
                label: newValue
            }
            if (newValue === "") {
                toast.warning("Nazwa tagu nie może być pusta!")
                done(false);
            } else if (oldValue === newValue || '#' + newValue === oldValue) {
                done(false);
            } else {
                http_post(`editTags.php`, data).then((res) => {
                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            toast.success("Poprawnie zmieniono nazwę!")
                            if (res.data.rr) {
                                done(false);
                                this.refreshDataManually('pagination');
                            } else {
                                done();
                            }
                        } else if (res.data.valid === 2) {
                            toast.error("Tag o tej nazwie już istnieje!")
                            done(false);
                        } else if (res.data.valid === 3) {
                            toast.error("Wprowadź nazwę tagu bez znaku #")
                            done(false);
                        } else {
                            toast.error("Nie udało się zmienić nazwy!")
                            done(false);
                        }
                    } else {
                        toast.error("Błąd transmisji danych!")
                        done(false);
                    }
                })
            }
        }, 0);
        return { async: true };
    }

    handleSubmit(event) {

        let valid = true;
        const { user, reason, dateTo, formErrors } = this.state;

        if (user === null || user === []) {
            formErrors.user.info = "Wybierz użytkownika!";
            formErrors.user.isInvalid = true;
            valid = false;
        } else {
            formErrors.user.isValid = true;
            formErrors.user.isInvalid = false;
        }

        if (reason == "") {
            formErrors.reason.info = "Wprowadź powód!";
            formErrors.reason.isInvalid = true;
            valid = false;
        } else {
            formErrors.reason.isValid = true;
            formErrors.reason.isInvalid = false;
        }


        this.setState({ formErrors: formErrors });

        if (valid) {

            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                uid: user.value,
                reason: reason,
                dateTo: (dateTo === "" ? null : dateTo)
            }
            http_post(`newBan.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie dodano blokadę!") });
                        this.refreshDataManually();
                        this.resetValid();
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się dodać blokady!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })


        } else event.stopPropagation();

        event.preventDefault();
    }

    render() {
        const columns = [{
            dataField: 'photo',
            text: 'Awatar',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '125px' };
            }
        }, {
            dataField: 'name',
            text: 'Użytkownik',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'reason',
            text: 'Powód',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'dateFrom',
            text: 'Rozpoczęcie',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'dateTo',
            text: 'Zakończenie',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '150px' };
            }
        }];

        const defaultSorted = [{
            dataField: 'name',
            order: 'asc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        const customStyles = {
            control: base => ({
                ...base,
                height: 120
            }),
            control: (provided, state) => ({
                ...provided,
                height: 120
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                height: 100
            })

        }

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={3}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>BLOKADY</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Dodaj blokadę</Lato></Card.Title>
                                    <Form noValidate onSubmit={this.handleSubmit}>
                                        <Form.Group>
                                            <Form.Label>Użytkownik</Form.Label>
                                            <Select isSearchable={true} value={this.state.user} placeholder={"Wybierz użytkownika..."} options={this.state.userOptions} isClearable={true} onChange={(dd) => this.setState({ user: dd })} noOptionsMessage={(dd) => {
                                                if (dd.inputValue !== "") {
                                                    return "Nie znaleziono: " + dd.inputValue
                                                } else {
                                                    return "Brak użytkowników!";
                                                }
                                            }} />
                                            <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.user.isValid} isInvalid={this.state.formErrors.user.isInvalid} />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.user.info}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Powód</Form.Label>
                                            <Form.Control isValid={this.state.formErrors.reason.isValid} isInvalid={this.state.formErrors.reason.isInvalid} onChange={this.handleChange} value={this.state.reason} name="reason" as="textarea" rows="3" placeholder="Wprowadź powód blokady" />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.reason.info}</Form.Control.Feedback>
                                            <Form.Text className="text-muted">Powiadomienie o blokadzie wraz z powodem zostanie przesłane na adres e-mail użytkownika.</Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Data zakończenia</Form.Label>

                                            <MaskedFormControl type='text' name='dateTo' value={this.state.dateTo} placeholder="Wprowadź datę zakończenia blokady" onChange={this.handleChange} mask='1111-11-11' />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.dateTo.info}</Form.Control.Feedback>
                                            <Form.Text className="text-muted">Data w formacie: <strong>RRRR-MM-DD</strong>. Pozostawienie pustej daty oznacza blokadę do momentu ręcznego usunięcia.</Form.Text>
                                        </Form.Group>
                                        <Button disabled={this.state.loadingShow} type="submit" variant="primary" block><LatoBold>Dodaj blokadę</LatoBold></Button>
                                    </Form>
                                    <hr className="mt-4" />
                                    <Card.Title className="mt-4"><Lato>Lista blokad</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.bans}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak blokad!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Usuwanie blokady</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć blokadę użytkownika?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="secondary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default AdminBans;