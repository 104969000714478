import React, { useState } from "react";
import { Container, Form, Spinner, Button, Alert } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import http_post from '../scripts/post';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom';
import '../styles/Login.css';
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from '../components/LoadingSpinner';
import me from '../scripts/me';
import qs from 'qs';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Activation.css';
import Footer from "../components/Footer";
import Lato from '../components/Lato';

class Activation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activateStatus: true,
            loading2: true
        };

    }

    componentDidMount() {
        me().then((self) => {
            if (self.response === 1) {
                this.props.history.push('/');
            } else {
                const data = {
                    hash: this.props.match.params.hash
                }

                http_post(`activate.php`, data).then((res) => {

                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            this.setState({ loading2: false });
                        } else {
                            this.setState({ loading2: false, activateStatus: false });
                        }
                    } else {
                        this.props.history.push('/logowanie');
                    }
                })
            }
        });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading2}>
                <Header />
                <Container>
                    <h2><Lato>Aktywacja konta</Lato></h2>

                    {this.state.activateStatus ?
                        <>
                            <Alert variant="success">
                                <Alert.Heading>Konto zostało aktywowane</Alert.Heading>
                                <hr />
                                <p className="mb-0">
                                    Twoje konto zostało aktywowane. Teraz możesz przejść do logowania i zalogować się na swoje konto.
                                </p>
                            </Alert>
                            <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Przejdź do logowania</Link></Form.Text>
                        </>
                        :
                        <>
                            <Alert variant="danger">
                                <Alert.Heading>Link jest błędny lub dokonano już aktywacji konta</Alert.Heading>
                                <hr />
                                <p className="mb-0">
                                    Upewnij się, że poprawnie otworzyłeś link przesłany w wiadomości e-mail.
                                </p>
                            </Alert>
                            <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Przejdź do logowania</Link></Form.Text>
                        </>

                    }
                </Container>
                
            </LoadingSpinner >
        );
    }
}

export default Activation;