import React from "react";
import { Container, Spinner } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, TableHeaderColumn } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTags } from '@fortawesome/free-solid-svg-icons';
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import Select from 'react-select';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

const { SearchBar } = Search;

class AdminYears extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "label",
            sortOrder: "asc",
            newTeamInput: "",
            formErrors: {
                newYearInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            show: false,
            modalDelete: {
                label: "",
                value: null
            },
            modalPreview: {
                label: "",
                value: null,
                loading: true
            },
            multiSelect: null
        };

        this.refreshData = this.refreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    componentDidMount() {
        this.refreshDataManually();
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }

        http_post(`yearsTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.years.map((row) => {
                    row.button = <><Button variant="danger" onClick={() => { this.handleDelete(row.value, row.label) }}><FontAwesomeIcon icon={faTrash} /></Button></>
                    {
                        row.hidden === "0" ?
                            row.hidden2 = <>TAK</>
                            :
                            row.hidden2 = <>NIE</>
                    }
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=yearsTable.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleClose2() {
        this.setState({ show2: !this.state.show2 })
    }

    handleDelete(value, label) {
        this.setState({
            modalDelete: {
                value: value,
                label: label
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    handlePreview(value, label) {
        this.setState({
            modalPreview: {
                value: value,
                label: label,
                loading: true
            }
        }, () => {
            this.setState({ show2: true }, () => {

                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    value: value
                }

                http_post(`teams_tags.php`, data).then((res) => {
                    if (res.status === 200) {
                        this.setState({ multiSelect: res.data }, () => {
                            this.setState({
                                modalPreview: {
                                    value: value,
                                    label: label,
                                    loading: false
                                }
                            })
                        });
                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=teams_tags.php'
                        });
                    }
                })
            })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteYear.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false }, () => { toast.success("Poprawnie usunięto rok!") });
                    this.refreshDataManually();
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć roku!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            newYearInput: ""
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        switch (column.dataField) {
            case "label":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (newValue === "") {
                        toast.warning("Rok nie może być pusty!")
                        done(false);
                    } else if (oldValue === newValue || '#' + newValue === oldValue) {
                        done(false);
                    } else {
                        http_post(`editYear.php`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono rok!")
                                    if (res.data.rr) {
                                        done(false);
                                        this.refreshDataManually('pagination');
                                    } else {
                                        done();
                                    }
                                } else if (res.data.valid === 2) {
                                    toast.error("Ten rok już istnieje!")
                                    done(false);
                                } else {
                                    toast.error("Nie udało się zmienić roku!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }
                }, 0);
                break;
            case "hidden":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (oldValue === newValue) {
                        done(false);
                    } else {
                        http_post(`editYear.php?hidden`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono widoczność!")
                                    if (res.data.rr) {
                                        done(false);
                                        this.refreshDataManually('pagination');
                                    } else {
                                        done();
                                    }
                                } else {
                                    toast.error("Nie udało się zmienić widoczności!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }
                }, 0);
                break;
        }

        return { async: true };
    }

    handleSubmit(event) {

        let valid = true;
        const { newYearInput, formErrors } = this.state;

        if (newYearInput <= 0) {
            formErrors.newYearInput.info = "Wprowadź nazwę drużyny!";
            formErrors.newYearInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.newYearInput.isValid = true;
            formErrors.newYearInput.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                label: newYearInput
            }
            http_post(`newYear.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie dodano rok!") });
                        this.refreshDataManually();
                        this.resetValid();
                    } else if (res.data.valid === 2) {
                        this.setState({ show: false }, () => { toast.error("Rok o tej nazwie już istnieje!") });
                    } else if (res.data.valid === 3) {
                        this.setState({ show: false }, () => { toast.error("Wprowadzona wartość nie jest liczbą!") });
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się dodać roku!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })


        } else event.stopPropagation();

        event.preventDefault();
    }

    render() {
        const columns = [{
            dataField: 'label',
            text: 'Rok',
            sort: true,
            editorStyle: {
                textAlign: "center"
            },
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'folders',
            text: 'Przypisane foldery',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'photos',
            text: 'Przypisane zdjęcia',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'hidden',
            text: 'Ukryty',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: [{
                    value: 'TAK',
                    label: 'TAK'
                }, {
                    value: 'NIE',
                    label: 'NIE'
                }]
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }];

        const defaultSorted = [{
            dataField: 'label',
            order: 'asc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={2}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>LATA</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Dodaj rok</Lato></Card.Title>
                                    <Form noValidate onSubmit={this.handleSubmit}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Rok</Form.Label>
                                            <Form.Control isValid={this.state.formErrors.newYearInput.isValid} isInvalid={this.state.formErrors.newYearInput.isInvalid} onChange={this.handleChange} value={this.state.newYearInput} name="newYearInput" type="text" placeholder="Wprowadź rok" />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.newYearInput.info}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Button disabled={this.state.loadingShow} type="submit" variant="primary" block><LatoBold>Dodaj rok</LatoBold></Button>
                                    </Form>
                                    <hr className="mt-4" />
                                    <Card.Title className="mt-4">Lista lat</Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.years}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Rok" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak lat!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Usuwanie roku</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć rok <strong>{this.state.modalDelete.label}</strong>?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default AdminYears;