import React, { useState } from "react";
import { Container, Form, Spinner, Button, Alert } from 'react-bootstrap';
import http_post from '../scripts/post';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom';
import '../styles/Login.css';
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from '../components/LoadingSpinner';
import me from '../scripts/me';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Recovery.css';
import Footer from "../components/Footer";
import LatoBold from "../components/LatoBold";
import Lato from "../components/Lato";

class Recovery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            emailInput: "",
            formErrors: {
                emailInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            loadingShow: false,
            loading2: true,
            recoveryProcess: false,
            resultError: {
                status: false,
                info: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    showError(show = true, error) {

        const { resultError } = this.state;
        if (!show) resultError.status = show;
        else {
            resultError.status = show;
            resultError.info = error;
        }
        this.setState({ resultError: resultError, loadingShow: false });

    }

    handleSubmit(event) {

        let valid = true;
        const { emailInput, passwordInput, formErrors, amentLoginInput } = this.state;
        const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
        this.showError(false);

        if (emailInput <= 0) {
            formErrors.emailInput.info = "Uzupełnij adres e-mail!";
            formErrors.emailInput.isInvalid = true;
            valid = false;
        } else {

            if (emailRegex.test(emailInput)) {
                formErrors.emailInput.isValid = true;
                formErrors.emailInput.isInvalid = false;
            } else {
                formErrors.emailInput.info = "Adres e-mail jest niepoprawny!";
                formErrors.emailInput.isInvalid = true;
                valid = false;
            }

        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            this.setState({ loadingShow: true });
            const data = {
                email: emailInput
            }

            http_post(`recovery.php`, data).then((res) => {

                if (res.status === 200) {

                    this.setState({
                        loadingShow: false,
                        recoveryProcess: true
                    })
                    this.resetValid();

                } else {

                    this.setState({
                        loadingShow: false
                    })
                    this.resetValid();

                    toast.error("Błąd transmisji danych!");

                }

            })

        } else event.stopPropagation();


        event.preventDefault();

    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            emailInput: ""
        })
    }

    componentDidMount() {
        me().then((self) => {
            if (self.response === 1) {
                this.props.history.push('/');
            } else {
                this.setState({ loading2: false });
            }
        });
    }

    render() {

        return (
            <LoadingSpinner loading={this.state.loading2}>
                <Header />
                <Container className="loginForm">
                    <ToastContainer />
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <h2><Lato>Odzyskiwanie hasła</Lato></h2>
                        {this.state.loadingShow ?
                            <div style={{ textAlign: 'center' }}>
                                <Spinner animation="border" className="loadingSpinner" variant="secondary" />
                            </div>
                            :
                            <>
                                {this.state.recoveryProcess ?
                                    <>
                                        <Alert variant="success">
                                            <Alert.Heading>Rozpoczęto proces resetowania hasła</Alert.Heading>
                                            <hr />
                                            <p className="mb-0">
                                                Jeżeli w systemie istnieje konto przypisane do podanego adresu e-mail, to wkrótce otrzymasz na ten adres wiadomość z instrukcją resetowania hasła.
                                            </p>
                                        </Alert>
                                        <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Wróć do logowania</Link></Form.Text>
                                    </>
                                    :
                                    <>
                                        <Form.Group>
                                            <Form.Label>Adres e-mail</Form.Label>
                                            <Form.Control isValid={this.state.formErrors.emailInput.isValid} isInvalid={this.state.formErrors.emailInput.isInvalid} onChange={this.handleChange} value={this.state.emailInput} name="emailInput" type="email" placeholder="Wprowadź adres e-mail" />
                                            <Form.Control.Feedback type="invalid">{this.state.formErrors.emailInput.info}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Button disabled={this.state.loadingShow} type="submit" variant="primary" size="lg" block>
                                            <LatoBold>Przypomnij hasło</LatoBold>
                                        </Button>

                                        <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Wróć do logowania</Link></Form.Text>
                                    </>
                                }
                            </>

                        }
                    </Form>
                </Container>
                
            </LoadingSpinner>
        );
    }
}

export default Recovery;