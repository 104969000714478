import React from 'react';
import './App.css';
import './index.css';
import Index from './screens/index';
import Login from './screens/login';
import Error from './screens/error';
import Recovery from './screens/recovery';
import Logout from './screens/logout';
import Register from './screens/register';
import Contact from './screens/contact';
import Profile from './screens/profile';
import Image from './screens/image';
import YearContainer from './screens/yearContainer';
import Folder from './screens/folder';
import SearchScreen from './screens/searchScreen';
import Gallery from './screens/gallery';
import Tag from './screens/tag';
import RecoveryHash from './screens/recoveryHash';
import Activation from './screens/activation';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminIndex from './screens/adminIndex';
import AdminNewPhoto from './screens/adminNewPhoto';
import AdminTags from './screens/adminTags';
import AdminTeams from './screens/adminTeams';
import AdminYears from './screens/adminYears';
import AdminFolders from './screens/adminFolders';
import AdminPhotos from './screens/adminPhotos';
import AdminPhotoReport from './screens/adminPhotoReport';
import AdminComments from './screens/adminComments';
import AdminCommentReport from './screens/adminCommentReport';
import AdminDictionary from './screens/adminDictionary';
import AdminUsers from './screens/adminUsers';
import AdminBans from './screens/adminBans';
import AdminNews from './screens/adminNews';
import AdminLogs from './screens/adminLogs';
import AdminSettings from './screens/adminSettings';
import Documents from './screens/documents';

function App() {

    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    return (
        <div className="App">
            <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet"></link>
            <Router>
                <Switch>
                    <Route path='/' exact component={Index} />
                    <Route path='/logowanie' exact component={Login} />
                    <Route path='/kontakt' exact component={Contact} />
                    <Route path='/dokumenty' exact component={Documents} />
                    <Route path='/profil' exact component={Profile} />
                    <Route path='/odzyskiwanie' exact component={Recovery} />
                    <Route path='/odzyskiwanie/:hash' exact component={RecoveryHash} />
                    <Route path='/aktywacja/:hash' exact component={Activation} />
                    <Route path='/rejestracja' exact component={Register} />
                    <Route path='/error' exact component={Error} />
                    <Route path='/wyloguj' exact component={Logout} />
                    <Route path='/tag/:tag' exact component={Tag} />
                    <Route path='/rok/:year' exact component={YearContainer} />
                    <Route path='/folder/:id' exact component={Folder} />
                    <Route path='/folder/:id/:comment' exact component={Folder} />
                    <Route path='/galeria' exact component={Gallery} />
                    <Route path='/galeria/:id' exact component={Image} />
                    <Route path='/galeria/:id/:comment' exact component={Image} />
                    <Route path='/wyszukiwarka' exact component={SearchScreen} />
                    <Route path='/administrator' exact component={AdminIndex} />
                    <Route path='/administrator/aktualnosci' exact component={AdminNews} />
                    <Route path='/administrator/zdjecia' exact component={AdminPhotos} />
                    <Route path='/administrator/zdjecia/dodawanie' exact component={AdminNewPhoto} />
                    <Route path='/administrator/zdjecia/tagi' exact component={AdminTags} />
                    <Route path='/administrator/zdjecia/druzyny' exact component={AdminTeams} />
                    <Route path='/administrator/zdjecia/lata' exact component={AdminYears} />
                    <Route path='/administrator/zdjecia/foldery' exact component={AdminFolders} />
                    <Route path='/administrator/zdjecia/zgloszenia' exact component={AdminPhotoReport} />
                    <Route path='/administrator/komentarze' exact component={AdminComments} />
                    <Route path='/administrator/komentarze/zgloszenia' exact component={AdminCommentReport} />
                    <Route path='/administrator/komentarze/slownik' exact component={AdminDictionary} />
                    <Route path='/administrator/uzytkownicy' exact component={AdminUsers} />
                    <Route path='/administrator/uzytkownicy/blokady' exact component={AdminBans} />
                    <Route path='/administrator/zdarzenia' exact component={AdminLogs} />
                    <Route path='/administrator/ustawienia' exact component={AdminSettings} />
                    <Route path='*' exact component={Login} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;