import React from "react";
import Cookies from 'universal-cookie';
import Header from '../components/Header';
import http_post from '../scripts/post';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.cookies = new Cookies();
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.history.push('/');
        }, 2000);

        http_post(`logout.php`, { token: this.cookies.get('token') });

        this.cookies.remove('token', { path: '/' });
    }

    render() {
        this.cookies.remove('rank', { path: '/' });
        this.cookies.remove('nick', { path: '/' });
        this.cookies.remove('uid', { path: '/' });
        this.cookies.remove('amentLogin', { path: '/' });

        return (
            <>
                <Header />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100vw', top: '0px', bottom: '0px' }}>
                    <div>
                        <h1>Wylogowano poprawnie</h1>
                        <h3>Za chwilę nastąpi przekierowanie</h3>
                    </div>
                </div>
            </>
        );
    }
}

export default Logout;