import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal, Spinner, Table } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, TableHeaderColumn } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfo, faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import styles from '../styles/AdminUsers.module.css';
import Select from 'react-select';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

function nl2br(arr) {
    return arr.split('\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>
    })
}


const { SearchBar } = Search;

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "name",
            sortOrder: "asc",
            firstName: "",
            lastName: "",
            nick: "",
            maiden: "",
            email: "",
            nickOptions: 0,
            displayedName: "",
            uid: null,
            rank: null,
            formErrors: {
                firstName: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                lastName: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                nickOptions: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                nick: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                maiden: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                email: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                },
                rank: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }

            },
            show: false,
            modalDelete: {
                label: "",
                value: null
            },
            modalPreview: {
                label: "",
                value: null,
                loading: true
            },
            modalRank: {
                value: null,
                loading: true
            },
            recoveryButtonLoading: false,
            previewEdit: false
        };

        this.refreshData = this.refreshData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
        this.handleNickOptions = this.handleNickOptions.bind(this);
        this.displayName = this.displayName.bind(this);

    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        switch (name) {
            case "nick":
                this.setState({ [name]: value }, () => { this.displayName(this.state.nickOptions) });
                if (!value) if (this.state.nickOptions == "3" || this.state.nickOptions == "4") if (this.state.firstName !== "" || this.state.lastName !== "") this.setState({ nickOptions: "1" }, () => { this.displayName("1") });
                else this.setState({ nickOptions: "0" }, () => { this.displayName("dd") });
                break;
            case "firstName":
                this.setState({ [name]: value }, () => { this.displayName(this.state.nickOptions) });
                if (!value) this.setState({ nickOptions: "0" }, () => { this.displayName("dd") });
                break;
            case "lastName":
                this.setState({ [name]: value }, () => { this.displayName(this.state.nickOptions) });
                if (!value) this.setState({ nickOptions: "0" }, () => { this.displayName("dd") });
                break;
            default:
                this.setState({ [name]: value });
                break;

        }

    }

    componentDidMount() {
        this.refreshDataManually();
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }

        http_post(`usersTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.users.map((row) => {
                    row.photo = <img className={styles.avatar} src={row.avatar} />
                    row.button = <>
                        <Button variant="secondary" onClick={() => { this.handleRank(row.id) }}><FontAwesomeIcon icon={faKey} /></Button>
                        <Button className="ml-1" variant="primary" onClick={() => { this.handlePreview(row.id) }}><FontAwesomeIcon icon={faInfo} /></Button>
                        <Button className="ml-1" variant="danger" onClick={() => { this.handleDelete(row.id, row.name) }}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                    row.name = nl2br(row.name)
                    
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminTags&no=' + res.status + '&text=' + res.text + '&url=tags.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleClose2() {
        this.setState({ show2: !this.state.show2 })
        this.resetValid();
    }

    handleClose4() {
        this.setState({ show3: !this.state.show3, rank: null, ranks: [] })
        this.resetValid()
    }

    handleClose3(status = false) {
        if (status) {
            this.handlePreview(this.state.uid);
            this.resetValid();
        } else {
            this.setState({ previewEdit: !this.state.previewEdit })
            this.resetValid();
        }

    }

    handleDelete(value, label) {
        this.setState({
            modalDelete: {
                value: value,
                label: label
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteUser.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false }, () => { toast.success("Poprawnie usunięto użytkownika!") });
                    this.refreshDataManually('pagination');
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć użytkownika!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    editRank(value) {

        let valid = true;
        const { rank, formErrors } = this.state;

        if (rank === null || rank === []) {
            formErrors.rank.info = "Określ uprawnienia!";
            formErrors.rank.isInvalid = true;
            valid = false;
        } else {
            formErrors.rank.isValid = true;
            formErrors.rank.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            const cookies = new Cookies();
            const token = cookies.get('token');

            const data = {
                token: token,
                uid: value,
                rank: rank.value
            }

            http_post(`editRank.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Poprawnie zmieniono uprawnienia!") });
                        this.handleClose4();
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się zmienić uprawnień!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })
        }
    }

    handlePreview(value) {
        this.setState({
            modalPreview: {
                loading: true
            },
            previewEdit: false
        }, () => {

            this.setState({ show2: true }, () => {

                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    uid: value
                }

                http_post(`adminProfile.php`, data).then((res) => {
                    if (res.status === 200) {

                        this.setState({
                            modalPreview: {
                                loading: false,
                                value: value,
                                data: res.data
                            }
                        })

                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=photoTabelModal.php'
                        });
                    }
                })
            })
        })
    }

    handleRank(value) {
        this.setState({
            modalRank: {
                loading: true
            }
        }, () => {

            this.setState({ show3: true }, () => {

                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    uid: value
                }

                http_post(`rank.php`, data).then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            rank: { value: res.data.rank.value, label: res.data.rank.label },
                            ranks: res.data.ranks,
                            modalRank: {
                                loading: false,
                                uid: value
                            }
                        })
                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=rank.php'
                        });
                    }
                })
            })
        })
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            firstName: "",
            lastName: "",
            nickOptions: "0",
            email: "",
            maiden: "",
            nick: "",
            avatar: null,
            uid: null
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        setTimeout(() => {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                value: row.id,
                label: newValue
            }
            if (oldValue === newValue) {
                done(false);
            } else {
                http_post(`editUser.php`, data).then((res) => {
                    if (res.status === 200) {
                        if (res.data.valid === 1) {
                            toast.success("Poprawnie zmieniono stan użytkownika!")
                            if (res.data.rr) {
                                done(false);
                                this.refreshDataManually('pagination');
                            } else {
                                done();
                            }
                        } else {
                            toast.error("Nie udało się zmienić stanu użytkownika!")
                            done(false);
                        }
                    } else {
                        toast.error("Błąd transmisji danych!")
                        done(false);
                    }
                })
            }
        }, 0);
        return { async: true };
    }

    handleSubmit() {

        let valid = true;
        const { firstName, lastName, email, maiden, nick, nickOptions, avatar, uid, formErrors } = this.state;
        const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

        if (firstName === "") {
            formErrors.firstName.info = "Wprowadź imię!";
            formErrors.firstName.isInvalid = true;
            valid = false;
        } else {
            formErrors.firstName.isValid = true;
            formErrors.firstName.isInvalid = false;
        }

        if (lastName === "") {
            formErrors.lastName.info = "Wprowadź imię!";
            formErrors.lastName.isInvalid = true;
            valid = false;
        } else {
            formErrors.lastName.isValid = true;
            formErrors.lastName.isInvalid = false;
        }

        if (nickOptions === "0") {
            formErrors.nickOptions.info = "Określ nazwę wyświetlaną!";
            formErrors.nickOptions.isInvalid = true;
            valid = false;
        } else {
            formErrors.nickOptions.isValid = true;
            formErrors.nickOptions.isInvalid = false;
        }

        if (email === "") {
            formErrors.email.info = "Wprowadź adres e-mail!";
            formErrors.email.isInvalid = true;
            valid = false;
        } else {
            if (emailRegex.test(email)) {
                formErrors.email.isValid = true;
                formErrors.email.isInvalid = false;
            } else {
                formErrors.email.info = "Adres e-mail jest niepoprawny!";
                formErrors.email.isInvalid = true;
                valid = false;
            }
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                uid: uid,
                firstName: firstName,
                lastName: lastName,
                display: nickOptions,
                email: email,
                maiden: maiden,
                nick: nick,
                avatar: (avatar === null ? true : false)
            }
            http_post(`adminEditProfile.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ show: false }, () => { toast.success("Pomyślnie edytowano użytkownika!") });
                        this.refreshDataManually('pagination');
                        this.handleClose3(true);
                    } else {
                        this.setState({ show: false }, () => { toast.error("Nie udało się edytować użytkownika!") });
                    }
                } else {
                    this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })


        }
    }

    handleReset(id) {
        this.setState({ recoveryButtonLoading: true }, () => {
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                token: token,
                uid: id
            }
            http_post(`usersRecovery.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ recoveryButtonLoading: false }, () => { toast.success("Poprawnie zresetowano hasło użytkownika!") });
                    } else {
                        this.setState({ recoveryButtonLoading: false }, () => { toast.error("Nie udało się zresetować hasła użytkownika!") });
                    }
                } else {
                    this.setState({ recoveryButtonLoading: false }, () => { toast.error("Błąd transmisji danych!") });
                }
            })

        })

    }

    handleEdit(id) {

        this.setState({
            previewEdit: true,
            firstName: this.state.modalPreview.data.firstName,
            lastName: this.state.modalPreview.data.lastName,
            avatar: this.state.modalPreview.data.avatar,
            displayedName: this.state.modalPreview.data.name,
            nickOptions: this.state.modalPreview.data.display,
            nick: this.state.modalPreview.data.nick,
            maiden: this.state.modalPreview.data.maiden,
            email: this.state.modalPreview.data.email,
            uid: id
        })

    }

    handleNickOptions(event) {
        let id = event.target.value;
        switch (id) {
            case "1":
                this.setState({ nickOptions: id }, () => { this.displayName(id) })
                break;
            case "2":
                if (this.state.maiden != "") {
                    this.setState({ nickOptions: id }, () => { this.displayName(id) })
                } else {
                    toast.error("Użytkownik nie ma zdefiniowanego nazwiska rodowego!")
                    this.setState({ nickOptions: "0" });
                }
                break;
            case "3":
                this.displayName(id);
                break;
            case "4":
                this.displayName(id);
                break;
        }
    }

    displayName(id) {

        switch (id) {

            case "1":
                this.setState({ displayedName: this.state.firstName + " " + this.state.lastName })
                break;
            case "2":
                this.setState({ displayedName: this.state.firstName + " " + this.state.lastName + " (" + this.state.maiden + ")" })
                break;
            case "3":
                if (this.state.nick) {
                    this.setState({ displayedName: this.state.firstName + " " + this.state.lastName + " (" + this.state.nick + ")", nickOptions: "3" })
                } else {
                    toast.error("Użytkownik nie ma zdefiniowanego pseudonimu!")
                    this.setState({ nickOptions: "0" });
                }
                break;
            case "4":
                if (this.state.nick) {
                    this.setState({ displayedName: this.state.nick, nickOptions: "4" })
                } else {
                    toast.error("Użytkownik nie ma zdefiniowanego pseudonimu!")
                    this.setState({ nickOptions: "0" });
                }
                break;
            case "dd":
                this.setState({ displayedName: "-" })
                break;
        }

    }

    render() {
        const columns = [{
            dataField: 'photo',
            text: 'Awatar',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '125px' };
            }
        }, {
            dataField: 'name',
            text: 'Imię',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'email',
            text: 'Adres e-mail',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'lastseen',
            text: 'Logowanie',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'active',
            text: 'Aktywny',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: [{
                    value: 'TAK',
                    label: 'TAK'
                }, {
                    value: 'NIE',
                    label: 'NIE'
                }]
            },
            editCellStyle: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '120px' };
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '150px' };
            }
        }];

        const defaultSorted = [{
            dataField: 'name',
            order: 'asc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={3}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>UŻYTKOWNICY</Lato></Card.Header>
                                <Card.Body>

                                    <Card.Title><Lato>Lista zarejestrowanych użytkowników</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.users}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak słów!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show3} onHide={() => { this.handleClose4() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Uprawnienia użytkownika</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    {this.state.modalRank.loading ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <>
                                            <Form.Group>
                                                <Form.Label>Uprawnienia</Form.Label>
                                                <Select isSearchable={true} value={this.state.rank} placeholder={"Wybierz uprawnienia..."} options={this.state.ranks} isClearable={true} onChange={(dd) => this.setState({ rank: dd })} noOptionsMessage={(dd) => {
                                                    if (dd.inputValue !== "") {
                                                        return "Nie znaleziono: " + dd.inputValue
                                                    } else {
                                                        return "Brak uprawnień!";
                                                    }
                                                }} />
                                                <Form.Control style={{ display: "none" }} isValid={this.state.formErrors.rank.isValid} isInvalid={this.state.formErrors.rank.isInvalid} />
                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.rank.info}</Form.Control.Feedback>
                                            </Form.Group>
                                        </>
                                    }
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose4() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.editRank(this.state.modalRank.uid) }} variant="primary"><LatoBold>Zapisz</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Usuwanie użytkownika</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć użytkownika <strong>{this.state.modalDelete.label}</strong>?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal size="lg" show={this.state.show2} onHide={() => { this.handleClose2() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Szczegóły użytkownika</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    {this.state.modalPreview.loading ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <>
                                            {!this.state.previewEdit ?
                                                <>
                                                    <Row>
                                                        <Col align="center" className="mb-2" sm={12} md={4}>
                                                            <img src={this.state.modalPreview.data.avatar} />
                                                        </Col>
                                                        <Col sm={12} md={8}>
                                                            <Row>
                                                                <Col xl="6">
                                                                    <Form.Label>Imię</Form.Label>
                                                                    <Card.Title>{this.state.modalPreview.data.firstName}</Card.Title>
                                                                </Col>
                                                                <Col xl="6">
                                                                    <Form.Label>Nazwisko</Form.Label>
                                                                    <Card.Title>{this.state.modalPreview.data.lastName}</Card.Title>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Label>Nazwa wyświetlana</Form.Label>
                                                                    <Card.Title>{this.state.modalPreview.data.name}</Card.Title>
                                                                </Col>

                                                                <Col xl="6">
                                                                    <Form.Label>Rejestracja konta</Form.Label>
                                                                    <Card.Title>{this.state.modalPreview.data.register}</Card.Title>
                                                                </Col>

                                                                <Col xl="6">
                                                                    <Form.Label>Ostatnie logowanie</Form.Label>
                                                                    <Card.Title>{this.state.modalPreview.data.lastseen}</Card.Title>
                                                                </Col>

                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col xl="4">
                                                            <Form.Label>Adres e-mail</Form.Label>
                                                            <Card.Title>{this.state.modalPreview.data.email}</Card.Title>
                                                        </Col>
                                                        <Col xl="4">
                                                            <Form.Label>Nazwisko rodowe</Form.Label>
                                                            <Card.Title>{(this.state.modalPreview.data.maiden === "" ? "-" : this.state.modalPreview.data.maiden)}</Card.Title>
                                                        </Col>
                                                        <Col xl="4">
                                                            <Form.Label>Pseudonim</Form.Label>
                                                            <Card.Title>{(this.state.modalPreview.data.nick === "" ? "-" : this.state.modalPreview.data.nick)}</Card.Title>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="4">
                                                            <Form.Label>Newsletter</Form.Label>
                                                            <Card.Title>{(this.state.modalPreview.data.newsletter === '0' ? "NIE" : "TAK")}</Card.Title>
                                                        </Col>
                                                        <Col xl="4">
                                                            <Form.Label>Aktywny</Form.Label>
                                                            <Card.Title>{(this.state.modalPreview.data.active === "1" ? "TAK" : "NIE")}</Card.Title>
                                                        </Col>
                                                        <Col xl="4">
                                                            <Form.Label>Przynależność do Pomarańczarni</Form.Label>
                                                            <Card.Title>{this.state.modalPreview.data.teams.length > 0 ? "TAK" : "NIE"}</Card.Title>
                                                        </Col>
                                                    </Row>
                                                    {this.state.modalPreview.data.teams.length > 0 &&
                                                        <>
                                                            <hr />
                                                            <Table striped bordered style={{ marginTop: "10px" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nazwa drużyny</th>
                                                                        <th>Zakres lat</th>
                                                                    </tr>
                                                                </thead>
                                                                {this.state.modalPreview.data.teams.map((team) => (
                                                                    <tr>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            {team.teamName}
                                                                        </td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            {team.yearFrom} - {(team.yearBy ? team.yearBy : "obecnie")}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </Table>
                                                        </>}
                                                </>
                                                :
                                                <>
                                                    <Row>
                                                        <Col align="center" className="mb-2" sm={12} md={4}>
                                                            <div align="center">
                                                                {
                                                                    this.state.avatar === null &&
                                                                    <div style={{ width: 173, height: 182, padding: 20, border: 'thin dashed black' }}>
                                                                        <FontAwesomeIcon icon={faUser} size="9x" />
                                                                    </div>
                                                                }{
                                                                    this.state.avatar !== null &&
                                                                    <div onClick={() => { this.setState({ avatar: null }) }} className="profileDiv" style={{ width: 173, height: 182, border: 'thin dashed black' }}>
                                                                        <img className="profileImg" width={171} height={180} src={this.state.avatar} />
                                                                        <div class="middle">
                                                                            <div class="centered textProfile">
                                                                                <FontAwesomeIcon icon={faTrash} size="7x" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} md={8}>
                                                            <Row>
                                                                <Col xl="6">
                                                                    <Form.Group>
                                                                        <Form.Label>Imię</Form.Label>
                                                                        <Form.Control isValid={this.state.formErrors.firstName.isValid} isInvalid={this.state.formErrors.firstName.isInvalid} onChange={this.handleChange} value={this.state.firstName} name="firstName" type="text" placeholder="Wprowadź imię" />
                                                                        <Form.Control.Feedback type="invalid">{this.state.formErrors.firstName.info}</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xl="6">
                                                                    <Form.Group>
                                                                        <Form.Label>Nazwisko</Form.Label>
                                                                        <Form.Control isValid={this.state.formErrors.lastName.isValid} isInvalid={this.state.formErrors.lastName.isInvalid} onChange={this.handleChange} value={this.state.lastName} name="lastName" type="text" placeholder="Wprowadź nazwisko" />
                                                                        <Form.Control.Feedback type="invalid">{this.state.formErrors.lastName.info}</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Label>Nazwa wyświetlana</Form.Label>
                                                                    <Card.Title>{this.state.displayedName}</Card.Title>
                                                                </Col>
                                                                <Col xl="12">
                                                                    <Form.Group>
                                                                        <Form.Label>Wyświetlanie nazwy użytkownika</Form.Label>
                                                                        <Form.Control isValid={this.state.formErrors.nickOptions.isValid} isInvalid={this.state.formErrors.nickOptions.isInvalid} value={this.state.nickOptions} onChange={this.handleNickOptions} as="select" defaultValue="0">
                                                                            <option value="0" disabled>Wybierz...</option>
                                                                            <option value="1">Imię i nazwisko</option>
                                                                            <option value="2">Imię i nazwisko (Nazwisko rodowe)</option>
                                                                            <option value="3">Imię i nazwisko (Pseudnonim)</option>
                                                                            <option value="4">Pseudonim</option>
                                                                        </Form.Control>
                                                                        <Form.Control.Feedback type="invalid">{this.state.formErrors.nickOptions.info}</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>

                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col xl="4">
                                                            <Form.Group>
                                                                <Form.Label>Adres e-mail</Form.Label>
                                                                <Form.Control isValid={this.state.formErrors.email.isValid} isInvalid={this.state.formErrors.email.isInvalid} onChange={this.handleChange} value={this.state.email} name="email" type="text" placeholder="Wprowadź adres e-mail" />
                                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.email.info}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl="4">
                                                            <Form.Group>
                                                                <Form.Label>Nazwisko rodowe</Form.Label>
                                                                <Form.Control isValid={this.state.formErrors.maiden.isValid} isInvalid={this.state.formErrors.maiden.isInvalid} onChange={this.handleChange} value={this.state.maiden} name="maiden" type="text" placeholder="Wprowadź nazwisko rodowe" />
                                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.maiden.info}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl="4">
                                                            <Form.Group>
                                                                <Form.Label>Pseudonim</Form.Label>
                                                                <Form.Control isValid={this.state.formErrors.nick.isValid} isInvalid={this.state.formErrors.nick.isInvalid} onChange={this.handleChange} value={this.state.nick} name="nick" type="text" placeholder="Wprowadź pseudonim" />
                                                                <Form.Control.Feedback type="invalid">{this.state.formErrors.nick.info}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </>
                                    }
                                </Modal.Body>

                                <Modal.Footer>
                                    {this.state.previewEdit ?
                                        <>
                                            <Button onClick={() => { this.handleClose3() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                            <Button onClick={() => this.handleSubmit()} disabled={this.state.modalPreview.loading} variant="primary"><LatoBold>Zapisz</LatoBold></Button>
                                        </>
                                        :
                                        <>
                                            <Button onClick={() => { this.handleClose2() }} variant="outline-primary"><LatoBold>Zamknij</LatoBold></Button>
                                            <Button disabled={this.state.recoveryButtonLoading} onClick={() => { this.handleReset(this.state.modalPreview.value) }} variant="secondary"><LatoBold>{this.state.recoveryButtonLoading ? <Spinner animation="grow" size="sm" /> : "Zresetuj hasło"}</LatoBold></Button>
                                            <Button disabled={this.state.modalPreview.loading} onClick={() => { this.handleEdit(this.state.modalPreview.value) }} variant="primary"><LatoBold>Edytuj</LatoBold></Button>
                                        </>}

                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default AdminUsers;