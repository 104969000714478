import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal, ListGroup, Spinner } from 'react-bootstrap';
import http_post from '../scripts/post';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lato from '../components/Lato';

class AdminSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            register: false,
            closed: false,
            show: true
        };


    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token
        }

        http_post(`settings.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ loading: false, register: (res.data.register === "0" ? false : true), closed: (res.data.closed === "0" ? false : true) });
                
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminTags&no=' + res.status + '&text=' + res.text + '&url=settings.php'
                });
            }
        })
    }

    handleUpdate() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            register: (this.state.register === true ? "1" : "0"),
            closed: (this.state.closed === true ? "1" : "0")
        }

        this.setState({ show: false }, () => {
            http_post(`settings.php`, data).then((res) => {
                if (res.status === 200) {
                    this.setState({ register: (res.data.register === "0" ? false : true), closed: (res.data.closed === "0" ? false : true) }, () => this.setState({ show: true }));
                    toast.success("Poprawnie zmieniono ustawienia aplikacji!")
                } else {
                    this.props.history.push({
                        pathname: '/error',
                        search: '?page=adminTags&no=' + res.status + '&text=' + res.text + '&url=settings.php'
                    });
                }
            })
        });

    }


    render() {


        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={3}>
                    <Header />
                    <Container className="container">
                        <ToastContainer />
                        <Card>
                            <Card.Header><Lato>USTAWIENIA</Lato></Card.Header>
                            <Card.Body>
                                <Card.Title><Lato>Ustawienia aplikacji</Lato></Card.Title>
                                {this.state.show ?
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <Form.Check id="s1" label="Przerwa techniczna" type="switch" checked={this.state.closed} onChange={() => this.setState({ closed: !this.state.closed }, () => this.handleUpdate())} />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Form.Check id="s2" label="Rejestrowanie kont" type="switch" checked={this.state.register} onChange={() => this.setState({ register: !this.state.register }, () => this.handleUpdate())} />
                                        </ListGroup.Item>
                                    </ListGroup>
                                    :
                                    <div className="text-center">
                                        <Spinner animation="grow" variant="primary" />
                                    </div>
                                }
                            </Card.Body>
                        </Card>

                    </Container>
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default AdminSettings;