import React from 'react';
import { Container, Form, Modal, Button, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FileDrop } from 'react-file-drop';
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";
import Cropper from 'react-easy-crop';
import '../styles/ProfilePicturePicker.css';

class ProfilePicturePicker extends React.Component {
    constructor(props) {
        super(props);
        this.inputFile = React.createRef(null);

        this.state = {
            modal: false,
            imageSrc: null,
            zoom: 1,
            crop: { x: 0, y: 0 },
            aspect: 171 / 180,
            croppedAreaPixels: null,
        }
    }

    showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels,
                0
            );
            this.props.thisRef.setState({ image: croppedImage });
        } catch (e) {
            toast.error(e);
        }
    }

    onCropChange = crop => {
        this.setState({ crop });
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels: croppedAreaPixels });
    }

    onClick() {
        this.inputFile.current.value = null;
        this.inputFile.current.click();
        this.setState({ imageSrc: null, zoom: 1 });
    }

    readFile1 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        })
    }

    readFile2 = event => {
        const blob2 = window.btoa(event.target.result);
        const blob = b64toBlob(blob2, 'image/heic');
        const thisRef = this;
        heic2any({ blob }).then((conversionResult) => {
            var url = URL.createObjectURL(conversionResult);
            thisRef.setState({ imageSrc: url });
        }).catch((e) => {
            toast.error(e);
        });
    }

    async onFileChange(e) {
        if (e.files && e.files.length > 0) {
            if (validate(e)) {
                let file = e.files[0];
                this.setState({ modal: true });

                if (file.name.toLowerCase().split('.').pop() === 'heic') {
                    var reader = new FileReader();
                    reader.addEventListener('load', this.readFile2);
                    reader.readAsBinaryString(file);
                } else {
                    const options = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }

                    try {
                        const fileComp = await imageCompression(file, options);
                        let imageDataUrl = await this.readFile1(fileComp);
                        this.setState({ imageSrc: imageDataUrl });
                    } catch (error) {
                        toast.error(error);
                    }
                }
            }
        } else {
            toast.error("Błąd przesyłania obrazka");
        }
    }

    render() {
        return (
            <>
                <ToastContainer />
                <Container
                    fluid
                    style={{ cursor: 'pointer', backgroundColor: '#EBEDED', padding: 40, height: 182, border: 'thin dashed black' }}
                    onClick={() => this.onClick()}
                >
                    <FileDrop
                        onDrop={(files, event) => { this.setState({ zoom: 1 }); this.inputFile.current.value = null; this.onFileChange({ files: files }); }}
                    >
                        Wybierz lub upuść obrazek tutaj
                        <img style={{ width: 100 }} src="./img/icons/drop_icon.svg" />
                        <Form.Text muted>Maksymalny rozmiar pliku: 7 MB<br/>Akceptowane formaty: jpg, png, bmp, jpeg, heic</Form.Text>
                    </FileDrop>
                    <input type='file' id='file' ref={this.inputFile} onChange={(e) => this.onFileChange(e.target)} accept=".jpg,.png,.bmp,.jpeg,.heic" style={{ display: 'none' }} />
                </Container>

                <Modal show={this.state.modal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Przytnij zdjęcie profilowe
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '50vw', height: '50vh' }}>
                            {this.state.imageSrc === null ?
                                <Spinner animation="grow" variant="warning" />
                                :
                                <Cropper
                                    image={this.state.imageSrc}
                                    zoom={this.state.zoom}
                                    crop={this.state.crop}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                />
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            Powiększenie: <input type="range" min="1" max="3" step="0.05" defaultValue="0" onChange={event => this.setState({ zoom: event.target.value })} /> {Math.round((this.state.zoom - 1) * 100)}%
                        </div>
                        <Button onClick={() => { this.showCroppedImage(); this.setState({ modal: false }) }}>Zapisz i zamknij</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

function isFileImage(file) {
    const acceptedImageTypes = ['jpeg', 'png', 'bmp', 'jpg', 'heic'];

    return file && acceptedImageTypes.includes(file.name.toLowerCase().split('.').pop());
}

function validate(input) {
    if (input.files[0]) {
        if (isFileImage(input.files[0])) {
            if (input.files[0].size > 7000000) {
                toast.error("Maksymalny rozmiar zdjęcia to 7 MB");
                return false;
            } else {
                return true;
            }
        } else {
            toast.error("Niewłaściwy format obrazka");
            return false;
        }
    } else {
        toast.error("Błąd przesyłania obrazka");
        return false;
    }
}

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
    })
}

export default ProfilePicturePicker;