import React, { useState } from "react";
import { Container, Form, Spinner, Button, Col, Row } from 'react-bootstrap';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import '../styles/Login.css';
import me from '../scripts/me';
import LoadingSpinner from '../components/LoadingSpinner';
import 'react-toastify/dist/ReactToastify.css';
import LatoBold from "../components/LatoBold";
import Lato from "../components/Lato";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            emailInput: "",
            passwordInput: "",
            switch1: false,
            formErrors: {
                emailInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                passwordInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            registerNotAllowed: true,
            loadingShow: false,
            loading2: true,
            resultError: {
                status: false,
                info: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    showError(show = true, error) {

        const { resultError } = this.state;
        if (!show) resultError.status = show;
        else {
            resultError.status = show;
            resultError.info = error;
        }
        this.setState({ resultError: resultError, loadingShow: false });

    }

    handleSubmit(event) {

        let valid = true;
        const { emailInput, passwordInput, formErrors, switch1 } = this.state;
        const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
        this.showError(false);

        if (emailInput <= 0) {
            formErrors.emailInput.info = "Uzupełnij adres e-mail!";
            formErrors.emailInput.isInvalid = true;
            valid = false;
        } else {

            if (emailRegex.test(emailInput)) {
                formErrors.emailInput.isValid = true;
                formErrors.emailInput.isInvalid = false;
            } else {
                formErrors.emailInput.info = "Adres e-mail jest niepoprawny!";
                formErrors.emailInput.isInvalid = true;
                valid = false;
            }

        }

        if (passwordInput <= 0) {
            formErrors.passwordInput.info = "Uzupełnij hasło!";
            formErrors.passwordInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.passwordInput.isValid = true;
            formErrors.passwordInput.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {

            this.setState({ loadingShow: true });
            const data = {
                email: emailInput,
                password: passwordInput,
                amentLogin: switch1
            }

            http_post(`login.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        const cookies = new Cookies();
                        if (data.amentLogin) {
                            let oneYearFromNow = new Date();
                            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                            cookies.set('uid', res.data.uid, { path: '/', expires: oneYearFromNow });
                            cookies.set('token', res.data.token, { path: '/', expires: oneYearFromNow });
                            cookies.set('rank', res.data.rank, { path: '/', expires: oneYearFromNow });
                            cookies.set('nick', res.data.nick, { path: '/', expires: oneYearFromNow });
                            cookies.set('amentLogin', true, { path: '/', expires: oneYearFromNow });
                        } else {
                            cookies.set('uid', res.data.uid, { path: '/' });
                            cookies.set('token', res.data.token, { path: '/' });
                            cookies.set('rank', res.data.rank, { path: '/' });
                            cookies.set('nick', res.data.nick, { path: '/' });
                        }
                        window.location.reload();
                    } else if (res.data.valid === 0) {

                        toast.error("Nieprawidłowe dane logowania!");
                        this.setState({ loadingShow: false });
                        this.resetValid();

                    } else if (res.data.valid === 2) {

                        toast.error("Konto nie zostało aktywowane. Sprawdź swoją skrzynkę email.");
                        this.setState({ loadingShow: false });
                        this.resetValid();

                    } else if (res.data.valid === 3) {

                        toast.error(res.data.details);      //zbanowany, tresc przesylana z backendu (powod, do kiedy, itd)
                        this.setState({ loadingShow: false });
                        this.resetValid();

                    } else if (res.data.valid === 4) {

                        toast.error("Trwa przerwa techniczna. Proszę spróbować ponownie później.");
                        this.setState({ loadingShow: false });
                        this.resetValid();

                    } else {

                        window.location.reload();

                    }
                } else {

                    window.location.reload();

                }
            })

        } else event.stopPropagation();


        event.preventDefault();

    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors,
            emailInput: "",
            passwordInput: "",
            switch1: false
        })
    }

    componentDidMount() {
        me().then((self) => {
            if (self.response === 1) {
                const cookies = new Cookies();
                const red = cookies.get('redirect');
                if (red === undefined) {
                    this.cookies.remove('redirect', { path: '/' });
                    this.props.history.push('/');
                } else {
                    this.props.history.push(red);
                }
            } else {
                this.setState({ loading2: false });
            }
        });

        http_post(`registerAllowed.php`).then((res) => {
            if (res.status === 200) {
                if (res.data.value === "1")
                    this.setState({ registerNotAllowed: false });
            }
        });
    }

    render() {

        return (
            <LoadingSpinner loading={this.state.loading2}>
                <Header />
                <Container className="loginForm">
                    <ToastContainer />
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <h2><Lato>Zaloguj się</Lato></h2>
                        {this.state.loadingShow ?
                            <div style={{ textAlign: 'center' }}>
                                <Spinner animation="border" variant="secondary" />
                            </div>
                            :
                            <>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Adres e-mail</Form.Label>
                                    <Form.Control isValid={this.state.formErrors.emailInput.isValid} isInvalid={this.state.formErrors.emailInput.isInvalid} onChange={this.handleChange} value={this.state.emailInput} name="emailInput" type="email" placeholder="Wprowadź adres e-mail" />
                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.emailInput.info}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Hasło</Form.Label>
                                    <Form.Control isValid={this.state.formErrors.passwordInput.isValid} isInvalid={this.state.formErrors.passwordInput.isInvalid} onChange={this.handleChange} value={this.state.passwordInput} name="passwordInput" type="password" placeholder="Wprowadź hasło" />
                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.passwordInput.info}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Text className="text-muted text-right"><Link to="/odzyskiwanie" className="linkRoute">Zapomniane hasło?</Link></Form.Text>

                                <Form.Group>
                                    <Form.Check id="c" type="switch" label="Nie wylogowuj mnie" checked={this.state.switch1} onChange={this.handleSwitchChange(1)} />
                                </Form.Group>

                                <Button disabled={this.state.loadingShow} type="submit" variant="primary" size="lg" block>
                                    <LatoBold>Zaloguj się</LatoBold>
                                </Button>
                                <Form.Text className="text-muted text-center orText">Nie masz konta?</Form.Text>
                                <Button disabled={this.state.loadingShow || this.state.registerNotAllowed} href="rejestracja" variant="outline-primary" size="lg" block>
                                    <LatoBold>Utwórz konto</LatoBold>
                                </Button>
                                {
                                    this.state.registerNotAllowed &&
                                    <Form.Text className="text-muted text-center orText">Rejestracja nowych kont czasowo wyłączona przez administratora.</Form.Text>
                                }
                            </>
                        }
                    </Form>
                </Container>
                <Footer />
            </LoadingSpinner>
        );
    }
}

export default Login;