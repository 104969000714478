import React from "react";
import Cookies from 'universal-cookie';
import http_post from '../../scripts/post';
import VisibilitySensor from "react-visibility-sensor";
import { Row, Col, Navbar, Breadcrumb, Card, Button, Container, Tabs, Tab, Form, Spinner, Modal } from 'react-bootstrap';
import Select from 'react-select';

class Step2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsDays: null,
            loading1: true,
            loading2: true,
            showModal: false
        };

        this.defaultValue = '';
    }

    refreshTags() {
        this.setState({ loading1: true, loading2: true, optionTags: null, optionDays: null });
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }
        const data2 = {
            token: token,
            fid: (this.props.thisRef.state.step1folder === null ? '0' : this.props.thisRef.state.step1folder.value)
        }

        http_post(`tags.php`, data).then((res) => {
            if (res.status === 200) {
                this.props.thisRef.setState({ step2tagsPossible: res.data }, () => this.setState({ loading1: false }));
            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step2&no=' + res.status + '&text=' + res.text + '&url=tags.php'
                });
            }
        })

        http_post(`folderDescription.php`, data2).then((res) => {
            if (res.status === 200) {
                if (this.props.thisRef.state.step2description.current === null)
                    this.defaultValue = res.data[0].description;
                else
                    this.props.thisRef.state.step2description.current.value = res.data[0].description;
                this.setState({ loading2: false });
            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step2&no=' + res.status + '&text=' + res.text + '&url=folderDescription.php'
                });
            }
        })

    }

    getDays() {
        if (this.props.thisRef.state.step2month === null) {
            this.setState({ optionsDays: [] });
        } else {
            const m = this.props.thisRef.state.step2month.value;
            let days;
            if (m === 1 || m === 3 || m === 5 || m === 7 || m === 8 || m === 10 || m === 12)
                days = 31;
            else if (m === 2)
                days = (parseInt(this.props.thisRef.state.step1year.label) % 4 === 0 && parseInt(this.props.thisRef.state.step1year.label) % 100 !== 0 ? 29 : 28);
            else
                days = 30;

            let result = [];
            for (let i = 1; i <= days; i++)
                result.push({ value: i, label: i });
            this.setState({ optionsDays: result });
        }
    }

    render() {

        const months = [
            { value: 1, label: "Styczeń" },
            { value: 2, label: "Luty" },
            { value: 3, label: "Marzec" },
            { value: 4, label: "Kwiecień" },
            { value: 5, label: "Maj" },
            { value: 6, label: "Czerwiec" },
            { value: 7, label: "Lipiec" },
            { value: 8, label: "Sierpień" },
            { value: 9, label: "Wrzesień" },
            { value: 10, label: "Październik" },
            { value: 11, label: "Listopad" },
            { value: 12, label: "Grudzień" }
        ];

        return (
            <>
                <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Uwaga!</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        Na tym etapie edycja jakichkolwiek pól w tym kroku spowoduje <b>nadpisanie</b> danych wprowadzonych w kroku 3.
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => { this.setState({ showModal: false }) }} variant="danger">Zamknij</Button>
                    </Modal.Footer>
                </Modal>
                <VisibilitySensor
                    onChange={isVisible => {
                        if (isVisible) {
                            if (!this.props.thisRef.state.step2visited) {
                                this.refreshTags();
                                this.props.thisRef.setState({ step2visited: true });
                            }
                            if (this.props.thisRef.state.step3visited) {
                                this.setState({ showModal: true });
                            }
                        }
                    }}
                    delayedCall
                >
                    <>
                        {
                            this.state.loading1 || this.state.loading2 ?
                                <Spinner animation="grow" variant="warning" />
                                :
                                <>
                                    <Card.Title>Wspólne właściwości</Card.Title>
                                    <Form.Group>
                                        <Form.Label>Tagi</Form.Label>
                                        <Select isMulti={true} isSearchable={true} value={this.props.thisRef.state.step2tags} placeholder={"Wybierz tagi..."} options={this.props.thisRef.state.step2tagsPossible} onChange={(dd) => this.props.thisRef.setState({ step2tags: dd, step3visited: false })} noOptionsMessage={(dd) => {
                                            if (dd.inputValue !== "") {
                                                return "Nie znaleziono: " + dd.inputValue
                                            } else {
                                                return "Brak tagów!";
                                            }
                                        }} />
                                        <Form.Text className="text-muted">
                                            Określ wspólne tagi wszystkich zdjęć.
                                    </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Opis</Form.Label>
                                        <Form.Control as="textarea" rows="3" defaultValue={this.defaultValue} ref={this.props.thisRef.state.step2description} onChange={() => { if (this.props.thisRef.state.step3visited) this.props.thisRef.setState({ step3visited: false }) }} placeholder="Wprowadź opis..." />
                                        <Form.Text className="text-muted">
                                            Określ wspólny opis wszystkich zdjęć (domyślnie dziedziczony z opisu folderu).
                                    </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check
                                            type="switch"
                                            id="dd2"
                                            label="Ukryj wszystkie wgrywane zdjęcia"
                                            checked={this.props.thisRef.step2hidden}
                                            onChange={(dd) => this.props.thisRef.setState({ step2hidden: dd.target.checked, step3visited: false })}
                                        />
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Rok</Form.Label>
                                            <Form.Control value={this.props.thisRef.state.step1year === null ? '?' : this.props.thisRef.state.step1year.label} disabled />
                                            <Form.Text className="text-muted">Rok dziedziczony jest z wybranego folderu.</Form.Text>
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Miesiąc</Form.Label>
                                            <Select isClearable={true} isMulti={false} isSearchable={false} value={this.props.thisRef.state.step2month} placeholder={"Wybierz miesiąc..."} options={months} onChange={(dd) => this.props.thisRef.setState({ step2month: dd, step3visited: false, step2day: null }, () => this.getDays())} />
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                            <Form.Label>Dzień</Form.Label>
                                            <Select isClearable={true} isMulti={false} isSearchable={false} value={this.props.thisRef.state.step2day} placeholder={"Wybierz dzień..."} options={this.state.optionsDays} onChange={(dd) => this.props.thisRef.setState({ step2day: dd, step3visited: false })} noOptionsMessage={(dd) => {
                                                if (dd.inputValue !== "") {
                                                    return "Nie znaleziono: " + dd.inputValue
                                                } else {
                                                    return "Najpierw wybierz miesiąc!";
                                                }
                                            }} />
                                        </Form.Group>
                                    </Form.Row>
                                </>
                        }
                    </>
                </VisibilitySensor>
            </>
        );
    }
}

export default Step2;