import React from "react";
import { Container, Card, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import Header from '../components/Header';
import Search from '../components/Search';
import RequireLogin from '../components/RequireLogin';
import ImageBreadCrumbs from '../components/ImageBreadcrumbs';
import ImageView from '../components/ImageView';
import SimiliarContent from '../components/SimiliarContent';
import Comments from "../components/Comments.js";
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import Pagination from '../components/PaginationSearch';
import LoadingSpinner from '../components/LoadingSpinner';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Image.css';
import { faBreadSlice } from "@fortawesome/free-solid-svg-icons";

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

class SearchScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: !true,
            textInput: "",
            yearStart: "",
            yearEnd: "",
            multiSelect: null,
            page: 1,
            sort: 0

        };

        this.updateContent = this.updateContent.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handlerPage = this.handlerPage.bind(this);

    }

    updateContent(data) {

        if (!data) {

            if (this.state.textInput === '' && this.state.yearStart === "" && this.state.yearEnd === "" && this.state.multiSelect === null) {
                toast.error("Uzupełnij co najmniej jedno pole!");
            } else if (this.state.textInput !== '' && this.state.textInput.length < 3) {
                toast.error("Wpisz co najmniej 3 litery.");
            } else if (this.state.textInput !== '' && this.state.textInput.length > 30) {
                toast.error("Wpisz co najwyżej 30 znaków.");
            } else {
                this.setState({ loading: true });
                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    text: this.state.textInput,
                    yearStart: this.state.yearStart,
                    yearEnd: this.state.yearEnd,
                    tags: this.state.multiSelect === null ? JSON.stringify([]) : JSON.stringify(this.state.multiSelect),
                    sort: this.state.sort,
                    page: this.state.page
                }

                http_post(`search.php`, data).then((res) => {
                    if (res.status === 200) {
                        this.setState({ data: res.data, loading: false });
                    } else {
                        toast.error("Błąd wyszukiwania.");
                    }
                })
            }
        } else {
            this.setState({ textInput: data.textInput, yearStart: data.yearStart, yearEnd: data.yearEnd, multiSelect: data.multiSelect, page: data.page }, () => {

                
                if (this.state.textInput === '' && this.state.yearStart === "" && this.state.yearEnd === "" && this.state.multiSelect === null) {
                    toast.error("Uzupełnij co najmniej jedno pole!");
                } else if (this.state.textInput !== '' && this.state.textInput.length < 3) {
                    toast.error("Wpisz co najmniej 3 litery.");
                } else if (this.state.textInput !== '' && this.state.textInput.length > 30) {
                    toast.error("Wpisz co najwyżej 30 znaków.");
                } else {
                    this.setState({ loading: true });
                    const cookies = new Cookies();
                    const token = cookies.get('token');

                    const data = {
                        token: token,
                        text: this.state.textInput,
                        yearStart: this.state.yearStart,
                        yearEnd: this.state.yearEnd,
                        tags: this.state.multiSelect === null ? JSON.stringify([]) : JSON.stringify(this.state.multiSelect),
                        sort: this.state.sort,
                        page: this.state.page
                    }

                    http_post(`search.php`, data).then((res) => {
                        if (res.status === 200) {
                            this.setState({ data: res.data, loading: false });
                        } else {
                            toast.error("Błąd wyszukiwania.");
                        }
                    })
                }
            })
        }
    }

    handleSort(event) {
        this.setState({ sort: parseInt(event.target.value) }, () => { this.handlerPage("first") })
        //this.forceUpdate()
    }

    handlerPage(page) {

        this.setState({ switch: true }, () => {
            this.forceUpdate()
            switch (page) {
                case "first":
                    this.setState({ page: 1 }, () => { this.updateContent(); });
                    break;
                case "last":
                    this.setState({ page: Math.ceil(parseInt(this.state.data.amount) / 18) }, () => { this.updateContent(); });
                    break;
                case "next":
                    this.setState({ page: this.state.page + 1 }, () => { this.updateContent(); })
                    break;
                case "previous":
                    this.setState({ page: this.state.page - 1 }, () => { this.updateContent(); })
                    break;
                default:
                    this.setState({ page: page }, () => { this.updateContent(); });
                    break;
            }

        });

        this.setState({ switch: false });
    }


    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin>
                    <Header />
                    <Container className="container">
                        <Search withRedirect={false} refreshFunction={this.updateContent} page={this.state.page} sort={this.state.sort} />
                        {this.state.data &&
                            <Card>
                                <Card.Header>WYNIKI WYSZUKIWANIA</Card.Header>
                                <Card.Body>


                                    {this.state.data.results.length <= 0 ?
                                        <div className="text-center">
                                            <p>Niestety, nie znaleziono żadnych zdjęć odpowiadających podanym przez Ciebie kryteriom.</p>Poszerz zakres wyszukiwania, podając np. inne słowa lub inny zakres dat.
                                        </div>
                                        :
                                        <>
                                            <Row>
                                                <Col xs={12} >
                                                    <Form inline className="align-items-center text-right" style={{ marginBottom: "10px", justifyContent: "flex-end" }}>
                                                        <Form.Label>
                                                            Sortuj
                                                    </Form.Label>
                                                        <Form.Control onChange={this.handleSort} as="select" value={this.props.sort} style={{ marginLeft: "10px", marginRight: "10px", width: "auto" }} >
                                                            <option value="0">największa trafność</option>
                                                            <option value="1">od najnowszych</option>
                                                            <option value="2">od najstarszych</option>
                                                        </Form.Control>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="align-iteams-center flex-wrap">
                                                {this.state.data.results.map((photo) => {
                                                    return (
                                                        <Col xs={6} md={4} xl={2} className="colFolder" align="center">
                                                            <Link to={"/galeria/" + photo.id} className="colLink">
                                                                <img src={photo.mini} className="foldersImg" />
                                                                <div class="colLinkText">{photo.title}</div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                            <Pagination handlerPage={this.handlerPage} total={this.state.data.amount} page={this.state.page} />
                                        </>
                                    }


                                </Card.Body>
                            </Card>
                        }
                    </Container>
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default SearchScreen;