import React from 'react';
import { Col, Row, Card, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { Element, Events, animateScroll as Scroll, ScrollElement, scroller } from 'react-scroll';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import NewComment from '../components/NewComment';
import Replies from '../components/Replies';
import styles from '../styles/SingleComment.module.css';
import LatoBold from './LatoBold';

class SingleComment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openReply: false,
            showReplies: false,
            replies: props.replies,
            page: 1,
            controlNumber: 0,
            showReport: false,
            formErrors: {
                reportInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            reportInput: ""
        };

        this.updateReplies = this.updateReplies.bind(this);
        this.newReply = this.newReply.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetValid = this.resetValid.bind(this);

    }

    componentDidMount() {
        scroller.scrollTo('comment_275', {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })

    }

    updateReplies() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            answer: this.props.comment.id
        }

        http_post(`replies.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ replies: res.data });

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=comments.php'
                });
            }
        })

    }

    newReply(newReply) {
        if (this.state.showReplies) this.setState({ replies: [...this.state.replies, newReply] })
        this.setState({ openReply: false });
        this.props.updateReplies(newReply.answer);
        this.forceUpdate()
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    resetValid() {
        let formErrors = this.state.formErrors;
        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })
        this.setState({
            formErrors: formErrors,
            reportInput: ""
        })
    }

    handleSubmit(event) {

        let valid = true;
        const { reportInput, formErrors } = this.state;

        if (reportInput <= 0) {
            formErrors.reportInput.info = "Uzupełnij treść zgłoszenia!";
            formErrors.reportInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.reportInput.isValid = true;
            formErrors.reportInput.isInvalid = false;
        }

        this.setState({ formErrors: formErrors });

        if (valid) {
            this.setState({ loadingShow: true });
            const cookies = new Cookies();
            const token = cookies.get('token');
            const data = {
                report: reportInput,
                cid: this.props.comment.id,
                token: token
            }

            http_post(`reportComment.php`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.valid === 1) {
                        this.setState({ loadingShow: false, showReport: false });
                        toast.success("Twoje zgłoszenie zostało przesłane!");
                        this.resetValid();
                    }
                } else {
                    window.location.reload();
                }
            })

        } else {

            toast.error("Uzupełnij poprawnie formularz!");
            event.stopPropagation();
        }

        event.preventDefault();

    }

    render() {

        if (this.props.page != this.state.page) this.setState({ showReplies: false, openReply: false, page: this.props.page })
        if (this.props.controlNumber != this.state.controlNumber) this.setState({ showReplies: false, openReply: false, controlNumber: this.state.controlNumber + 1 })

        return (
            <>
                <Element name={"comment_" + this.props.comment.id}>
                    <Row className={styles.rowMargin}>
                        <Col xs={12} xl={1}>
                            <img src={"data:image/jpeg;base64, " + this.props.comment.avatar} className={styles.avatar} />
                        </Col>
                        <Col xs={12} xl={11} className={styles.colPadding}>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <strong class="nick">{this.props.comment.nick}</strong>
                                </div>
                                <div>
                                    <div class="text-muted">{this.props.comment.added}</div>
                                </div>
                            </div>
                            <p class="text-muted" className={styles.commentContent}>
                                {this.props.comment.content}
                            </p>
                            <p class="text-muted">
                                {this.props.comment.amount != 0 &&
                                    <>
                                        <Link onClick={() => { this.setState({ showReplies: !this.state.showReplies }, () => { (this.state.showReplies && this.updateReplies()) }) }} className={styles.commentLink}><strong>Zobacz odpowiedzi ({this.props.comment.amount})</strong></Link> &#183;&nbsp;
                                    </>}
                                <Link onClick={() => { this.setState({ openReply: !this.state.openReply }) }} className={styles.commentLink}><strong>Odpowiedz</strong></Link> &#183; <Link onClick={() => { this.setState({ showReport: true }) }} className={styles.commentLink}><strong>Zgłoś</strong></Link>
                                <Modal show={this.state.showReport} onHide={() => { this.setState({ showReport: false }) }}>
                                    <Form noValidate onSubmit={this.handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Zgłoś komentarz</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {this.state.loadingShow ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="border" variant="secondary" />
                                                </div>
                                                :
                                                <>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Treść zgłoszenia</Form.Label>
                                                        <Form.Control isValid={this.state.formErrors.reportInput.isValid} isInvalid={this.state.formErrors.reportInput.isInvalid} onChange={this.handleChange} value={this.state.reportInput} name="reportInput" as="textarea" rows="3" placeholder="Wprowadź treść zgłoszenia" />
                                                        <Form.Text muted>
                                                            Możesz zgłosić do administratorów niezgodność tego komentarza z regulaminem.
                                                            </Form.Text>
                                                        <Form.Control.Feedback type="invalid">{this.state.formErrors.reportInput.info}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="outline-primary" onClick={() => { this.setState({ showReport: false }) }}><LatoBold>Anuluj</LatoBold></Button>
                                            <Button disabled={this.state.loadingShow} type="submit" variant="primary"><LatoBold>Wyślij</LatoBold></Button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>
                            </p>
                        </Col>

                        {this.state.showReplies &&
                            <Col xl={12} className={styles.replyCol}>
                                {!this.state.replies &&
                                    <Form.Text className="text-center">
                                        <Spinner animation="grow" variant="warning" />
                                    </Form.Text>
                                }
                                {this.state.replies && this.state.replies.map((comment) => (
                                    <>
                                        <Replies comment={comment} />
                                    </>
                                ))}
                            </Col>
                        }

                        {this.state.openReply &&
                            <Col xl={12} className={styles.replyCol}>
                                <NewComment type={this.props.type} id={this.props.comment.fid_pid} answerComment={this.props.comment.id} refreshFunction={this.newReply} />
                            </Col>
                        }


                    </Row>
                </Element>
            </>

        )
    }

}


export default SingleComment;