import React from "react";
import Cookies from 'universal-cookie';
import http_post from '../../scripts/post';
import VisibilitySensor from "react-visibility-sensor";
import { Row, Col, Navbar, Breadcrumb, Card, Button, Container, Tabs, Tab, Spinner, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';

class Step1 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            years: null,
            folders: null,
            showModal: false
        };
    }

    componentDidMount() {
        this.getYears();
    }

    getYears() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token
        }
        http_post(`years.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ years: res.data });

            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step1&no=' + res.status + '&text=' + res.text + '&url=years.php'
                });
            }
        })
    }

    getFolders() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            year: this.props.thisRef.state.step1year.value
        }
        http_post(`folders.php`, data).then((res) => {
            if (res.status === 200) {

                this.setState({ folders: res.data });

            } else {
                this.props.thisRef.props.history.push({
                    pathname: '/error',
                    search: '?page=step1&no=' + res.status + '&text=' + res.text + '&url=folders.php'
                });
            }
        })
    }

    render() {
        if (this.state.years === null)
            return <Spinner animation="grow" variant="warning" />
        else
            return (
                <>
                    <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Uwaga!</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Na tym etapie edycja jakichkolwiek pól w tym kroku spowoduje <b>nadpisanie</b> danych wprowadzonych w krokach 2 i 3.
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => { this.setState({ showModal: false }) }} variant="danger">Zamknij</Button>
                        </Modal.Footer>
                    </Modal>
                    <VisibilitySensor
                        onChange={isVisible => {
                            if (isVisible) {
                                if (this.props.thisRef.state.step2visited || this.props.thisRef.state.step3visited) {
                                    this.setState({ showModal: true });
                                }
                                this.props.thisRef.setState({ step1visited: true });
                            }
                        }}
                        delayedCall
                    >
                        <>
                            <Card.Title>Ścieżka wgrywania</Card.Title>
                            <Form.Group>
                                <Form.Label>Rok</Form.Label>
                                <Select isMulti={false} isSearchable={true} value={this.props.thisRef.state.step1year} placeholder={"Wybierz rok..."} options={this.state.years} onChange={(dd) => this.props.thisRef.setState({ step1year: dd, step1folder: null, step2visited: false, step3visited: false }, () => this.getFolders())} noOptionsMessage={(dd) => {
                                    if (dd.inputValue !== "") {
                                        return "Nie znaleziono: " + dd.inputValue
                                    } else {
                                        return "Brak lat!";
                                    }
                                }} />
                                <Form.Text className="text-muted">
                                    Wybierz rok, z którego zostaną wyświetlone dostępne foldery.
                                </Form.Text>
                                {
                                    this.props.thisRef.state.step1year !== null &&
                                    <Form.Text className="text-muted">
                                        Wybrany kontener z rokiem jest <b>{this.props.thisRef.state.step1year.hidden === '1' ? 'ukryty' : 'opublikowany'}</b>.
                                    </Form.Text>
                                }
                            </Form.Group>
                            {
                                this.props.thisRef.state.step1year !== null &&
                                (
                                    this.state.folders === null ?
                                        <Spinner animation="grow" variant="warning" />
                                        :
                                        <>
                                            <Form.Group>
                                                <Form.Label>Folder</Form.Label>
                                                <Select isMulti={false} isSearchable={true} value={this.props.thisRef.state.step1folder} placeholder={"Wybierz folder..."} options={this.state.folders} onChange={(dd) => this.props.thisRef.setState({ step1folder: dd, step2visited: false, step3visited: false, step2day: null, step2month: null, step2tags: null })} noOptionsMessage={(dd) => {
                                                    if (dd.inputValue !== "") {
                                                        return "Nie znaleziono: " + dd.inputValue
                                                    } else {
                                                        return "Brak folderów!";
                                                    }
                                                }} />
                                                <Form.Text className="text-muted">
                                                    Wybierz folder do którego zostaną wgrane zdjęcia.
                                                </Form.Text>
                                                {
                                                    this.props.thisRef.state.step1folder !== null &&
                                                    <Form.Text className="text-muted">
                                                        Wybrany folder jest <b>{this.props.thisRef.state.step1folder.hidden === '1' ? 'ukryty' : 'opublikowany'}</b>.
                                                    </Form.Text>
                                                }
                                            </Form.Group>
                                        </>

                                )
                            }
                        </>
                    </VisibilitySensor>
                </>
            );
    }
}

export default Step1;