import React, { useState } from "react";
import { Container, Form, Spinner, Button, Row, Col, Table, Alert } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import Header from '../components/Header';
import Footer from '../components/Footer';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import ProfilePicturePicker from '../components/ProfilePicturePicker';
import LoadingSpinner from '../components/LoadingSpinner';
import me from '../scripts/me';
import '../styles/Register.css';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import LatoBold from "../components/LatoBold";
import Lato from "../components/Lato";

class Register extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            image: null,
            accountCreated: false,
            teams: <option disabled>Wybierz...</option>,
            firstNameInput: "",
            lastNameInput: "",
            passwordInput: "",
            passwordRepeatInput: "",
            emailInput: "",
            nickInput: "",
            familyNameInput: "",
            formErrors: {
                firstNameInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                lastNameInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                passwordInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                passwordRepeatInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                emailInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                memberOf: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                nickInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                familyNameInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                nickOptions: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                switch1: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                switch2: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                switch3: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
            },
            loadingShow: false,
            memberOf: 0,
            teamIdInput: "Wybierz drużynę...",
            teamNameInput: "",
            teamYearFromInput: "Wybierz rok...",
            teamYearByInput: "Wybierz rok początkowy...",
            teamFormErrors: {
                teamNameInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                teamYearFromInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                },
                teamYearByInput: {
                    info: "",
                    isValid: false,
                    isInvalid: false
                }
            },
            switch1: false,
            switch2: false,
            switch3: false,
            switch4: false,
            myTeams: [],
            nickOptions: null,
            displayedName: "",
            resultError: {
                status: false,
                info: ""
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleMemberOf = this.handleMemberOf.bind(this);
        this.handleNickOptions = this.handleNickOptions.bind(this);
        this.handleDeleteTeam = this.handleDeleteTeam.bind(this);
        this.handleAddTeam = this.handleAddTeam.bind(this);
        this.resetTeamForm = this.resetTeamForm.bind(this);
        this.displayedName = this.displayedName.bind(this);
    }

    handleChange(event) {

        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });

    }

    handleChangeSelect(event) {

        event.preventDefault();
        switch (event.target.name) {
            case "teamNameInput":
                this.setState({
                    teamIdInput: event.target.value,
                    teamNameInput: event.target.options[event.target.selectedIndex].text
                });
                break;
            case "teamYearFromInput":
                let y2 = [];
                y2.push(<option disabled>Wybierz rok...</option>)
                for (var i = event.target.value; i <= (new Date().getFullYear()); i++) {
                    y2.push(<option value={i}>{i}</option>);
                }
                y2.push(<option value="obecnie">obecnie</option>);
                this.setState({
                    [event.target.name]: event.target.value,
                    yearsBy: y2,
                    teamYearByInput: "Wybierz rok..."
                });
                break;
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
                break;
        }
    }

    resetTeamForm() {

        this.setState({ teamIdInput: "Wybierz drużynę...", teamYearByInput: "Wybierz rok początkowy...", teamYearFromInput: "Wybierz rok..." });
        this.resetTeamValid();

    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });
    }

    handleDeleteTeam(id) {

        const myTeams = this.state.myTeams;

        const updatedMyTeams = myTeams.filter((team) => team.id !== id);

        this.setState({ myTeams: updatedMyTeams })

    }

    handleAddTeam() {

        const { teamIdInput, teamNameInput, teamYearFromInput, teamYearByInput, teamFormErrors } = this.state;
        let valid = true;

        if (teamIdInput === "Wybierz drużynę...") {
            teamFormErrors.teamNameInput.info = "Wybierz drużynę!";
            teamFormErrors.teamNameInput.isInvalid = true;
            valid = false;
        } else {
            teamFormErrors.teamNameInput.isValid = true;
            teamFormErrors.teamNameInput.isInvalid = false;
        }

        if (teamYearFromInput === "Wybierz rok...") {
            teamFormErrors.teamYearFromInput.info = "Wybierz rok!";
            teamFormErrors.teamYearFromInput.isInvalid = true;
            valid = false;
        } else {
            teamFormErrors.teamYearFromInput.isValid = true;
            teamFormErrors.teamYearFromInput.isInvalid = false;
        }

        if (teamYearByInput === "Wybierz rok początkowy...") {
            teamFormErrors.teamYearByInput.info = "Wybierz rok początkowy!";
            teamFormErrors.teamYearByInput.isInvalid = true;
            valid = false;
        } else {

            if (teamYearByInput === "Wybierz rok...") {
                teamFormErrors.teamYearByInput.info = "Wybierz rok!";
                teamFormErrors.teamYearByInput.isInvalid = true;
                valid = false;
            } else {
                teamFormErrors.teamYearByInput.isValid = true;
                teamFormErrors.teamYearByInput.isInvalid = false;
            }

        }

        if (teamYearByInput !== "Wybierz rok początkowy..." && teamYearFromInput !== "Wybierz rok..." && teamYearByInput !== "Wybierz rok...") {

            if (teamYearFromInput > teamYearByInput) {
                teamFormErrors.teamYearFromInput.info = "Błędne daty!";
                teamFormErrors.teamYearFromInput.isInvalid = true;
                teamFormErrors.teamYearByInput.info = "Błędne daty!";
                teamFormErrors.teamYearByInput.isInvalid = true;
                valid = false;
            }

        }

        this.setState({ teamFormErrors: teamFormErrors });

        if (valid) {

            const myTeams = this.state.myTeams;
            const updatedMyTeams = myTeams.concat({ id: uuidv4(), teamId: teamIdInput, teamName: teamNameInput, yearFrom: teamYearFromInput, yearBy: teamYearByInput });
            let y2 = [];
            y2.push(<option disabled>Wybierz rok początkowy...</option>);
            this.setState({
                myTeams: updatedMyTeams,
                yearsBy: y2
            })
            this.resetTeamForm();
        }

    }

    showError(show = true, error) {

        const { resultError } = this.state;
        if (!show) resultError.status = show;
        else {
            resultError.status = show;
            resultError.info = error;
        }
        this.setState({ resultError: resultError, loadingShow: false });

    }

    handleSubmit(event) {

        let valid = true;
        const { firstNameInput, lastNameInput, passwordInput, passwordRepeatInput, emailInput, memberOf, myTeams, formErrors, nickInput, familyNameInput, image, nickOptions, switch1, switch2, switch3, switch4, teamFormErrors } = this.state;
        const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        const passwordRegex = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
        this.showError(false);

        if (firstNameInput <= 0) {
            formErrors.firstNameInput.info = "Uzupełnij imię!";
            formErrors.firstNameInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.firstNameInput.isValid = true;
            formErrors.firstNameInput.isInvalid = false;
        }

        if (lastNameInput <= 0) {
            formErrors.lastNameInput.info = "Uzupełnij nazwisko!";
            formErrors.lastNameInput.isInvalid = true;
            valid = false;
        } else {
            formErrors.lastNameInput.isValid = true;
            formErrors.lastNameInput.isInvalid = false;
        }

        if (passwordInput <= 0) {
            formErrors.passwordInput.info = "Uzupełnij nowe hasło!";
            formErrors.passwordInput.isInvalid = true;
            valid = false;
        } else {
            if (passwordRegex.test(passwordInput)) {
                formErrors.passwordInput.isValid = true;
                formErrors.passwordInput.isInvalid = false;
                if (passwordRepeatInput <= 0) {
                    formErrors.passwordRepeatInput.info = "Powtórz nowe hasło!";
                    formErrors.passwordRepeatInput.isInvalid = true;
                    valid = false;
                } else {
                    if (passwordInput !== passwordRepeatInput) {
                        formErrors.passwordRepeatInput.info = "Powtórzone hasło nie jest identyczne!";
                        formErrors.passwordRepeatInput.isInvalid = true;
                        valid = false;
                    } else {
                        formErrors.passwordRepeatInput.isValid = true;
                        formErrors.passwordRepeatInput.isInvalid = false;
                    }
                }
            } else {
                formErrors.passwordInput.info = "Twoje nowe hasło nie spełnia wymagań złożoności!";
                formErrors.passwordInput.isInvalid = true;
                valid = false;
            }
        }

        if (emailInput <= 0) {
            formErrors.emailInput.info = "Uzupełnij adres e-mail!";
            formErrors.emailInput.isInvalid = true;
            valid = false;
        } else {

            if (emailRegex.test(emailInput)) {
                formErrors.emailInput.isValid = true;
                formErrors.emailInput.isInvalid = false;
            } else {
                formErrors.emailInput.info = "Adres e-mail jest niepoprawny!";
                formErrors.emailInput.isInvalid = true;
                valid = false;
            }

        }

        if (memberOf === 0) {
            formErrors.memberOf.info = "Uzupełnij swoje członkowstwo!";
            formErrors.memberOf.isInvalid = true;
            formErrors.memberOf.IsValid = false;
            valid = false;
        } else {
            formErrors.memberOf.isValid = true;
            formErrors.memberOf.isInvalid = false;
            if (memberOf === "1") if (myTeams.length === 0) {
                valid = false;
                teamFormErrors.teamNameInput.isValid = false;
                teamFormErrors.teamNameInput.isInvalid = true;
                teamFormErrors.teamNameInput.info = "Uzupełnij swoją przynależność!"
            }
        }

        if (nickOptions === null || nickOptions === "0") {
            formErrors.nickOptions.info = "Uzupełnij wyświetlanie nazwy użytkownika!";
            formErrors.nickOptions.isInvalid = true;
            formErrors.nickOptions.isValid = false;
            valid = false;
        } else {
            formErrors.nickOptions.isInvalid = false;
            formErrors.nickOptions.isValid = true;
        }

        if (switch1 === false) {
            formErrors.switch1.info = "Zaakceptuj RODO!";
            formErrors.switch1.isInvalid = true;
            formErrors.switch1.isValid = false;
            valid = false;
        } else {
            formErrors.switch1.isInvalid = false;
            formErrors.switch1.isValid = true;
        }

        if (switch2 === false) {
            formErrors.switch2.info = "Zaakceptuj RODO!";
            formErrors.switch2.isInvalid = true;
            formErrors.switch2.isValid = false;
            valid = false;
        } else {
            formErrors.switch2.isInvalid = false;
            formErrors.switch2.isValid = true;
        }

        if (switch3 === false) {
            formErrors.switch3.info = "Zaakceptuj RODO!";
            formErrors.switch3.isInvalid = true;
            formErrors.switch3.isValid = false;
            valid = false;
        } else {
            formErrors.switch3.isInvalid = false;
            formErrors.switch3.isValid = true;
        }

        this.setState({ formErrors: formErrors, teamFormErrors: teamFormErrors });

        if (valid) {

            this.setState({ loadingShow: true });
            const thisRef = this;
            fetch(image).then(r => {
                r.blob().then(a => {
                    var reader = new FileReader();
                    reader.readAsDataURL(a);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        const data = {
                            firstName: firstNameInput,
                            lastName: lastNameInput,
                            password: passwordRepeatInput,
                            email: emailInput,
                            member: memberOf,
                            teams: JSON.stringify(myTeams),
                            nick: nickInput,
                            maiden: familyNameInput,
                            displayedName: nickOptions,
                            newsletter: switch4,
                            img: image === null ? '' : base64data
                        }

                        http_post(`register.php`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    thisRef.setState({ loadingShow: false, accountCreated: true });
                                } else if (res.data.valid === 2) {
                                    const { formErrors } = thisRef.state;
                                    toast.error("Użytkownik z wprowadzonym adresem e-mail już istnieje!");
                                    formErrors.emailInput.isInvalid = true;
                                    formErrors.emailInput.isValid = false;
                                    formErrors.emailInput.info = "Użytkownik z wprowadzonym adresem e-mail już istnieje!";
                                    thisRef.setState({ loadingShow: false, formErrors: formErrors });
                                } else if (res.data.valid === 3) {
                                    const { teamFormErrors } = thisRef.state;
                                    toast.error("Błędnie określona przynależność do drużyn!");
                                    teamFormErrors.teamNameInput.isInvalid = true;
                                    teamFormErrors.teamNameInput.isValid = false;
                                    teamFormErrors.teamNameInput.info = "Błędnie określona przynależność do drużyn!";
                                    thisRef.setState({ loadingShow: false, teamFormErrors: teamFormErrors });
                                } else {
                                    window.location.reload();
                                }
                            } else {
                                window.location.reload();
                            }
                        })
                    }
                });
            });
        } else {
            toast.error("Uzupełnij poprawnie formularz!");
            event.stopPropagation();
        }
        event.preventDefault();
    }

    handleMemberOf(event) {
        let id = event.target.value;
        this.setState({ memberOf: id }, () => {
            this.displayedName();
        });
    }

    handleNickOptions(event) {
        let id = event.target.value;
        this.setState({ nickOptions: id }, () => {
            this.displayedName();
        });
    }

    displayedName() {
        const { nickOptions, firstNameInput, lastNameInput, familyNameInput, nickInput, formErrors } = this.state;
        let displayedName = "";
        let nickSet = "0";
        switch (nickOptions) {
            case "1":
                if (firstNameInput == "" || lastNameInput == "") {
                    formErrors.nickOptions.info = "Uzupełnij pola: imię, nazwisko!";
                    formErrors.nickOptions.isInvalid = true;
                    formErrors.nickOptions.isValid = false;
                    nickSet = "0";
                } else {
                    formErrors.nickOptions.isInvalid = false;
                    formErrors.nickOptions.isValid = true;
                    displayedName = firstNameInput + " " + lastNameInput;
                    nickSet = "1";
                }
                break;
            case "2":
                if (firstNameInput == "" || lastNameInput == "" || familyNameInput == "") {
                    formErrors.nickOptions.info = "Uzupełnij pola: imię, nazwisko, nazwisko rodowe!";
                    formErrors.nickOptions.isInvalid = true;
                    formErrors.nickOptions.isValid = false;
                    nickSet = "0";
                } else {
                    formErrors.nickOptions.isInvalid = false;
                    formErrors.nickOptions.isValid = true;
                    displayedName = firstNameInput + " " + lastNameInput + " (" + familyNameInput + ")";
                    nickSet = "2";
                }
                break;
            case "3":
                if (firstNameInput == "" || lastNameInput == "" || nickInput == "") {
                    formErrors.nickOptions.info = "Uzupełnij pola: imię, nazwisko, pseudonim!";
                    formErrors.nickOptions.isInvalid = true;
                    formErrors.nickOptions.isValid = false;
                    nickSet = "0";
                } else {
                    formErrors.nickOptions.isInvalid = false;
                    formErrors.nickOptions.isValid = true;
                    displayedName = firstNameInput + " " + lastNameInput + " (" + nickInput + ")";
                    nickSet = "3";
                }
                break;
            case "4":
                if (nickInput == "") {
                    formErrors.nickOptions.info = "Uzupełnij pole: pseudonim!";
                    formErrors.nickOptions.isInvalid = true;
                    formErrors.nickOptions.isValid = false;
                    nickSet = "0";
                } else {
                    formErrors.nickOptions.isInvalid = false;
                    formErrors.nickOptions.isValid = true;
                    displayedName = nickInput;
                    nickSet = "4";
                }
                break;
        }
        this.setState({ displayedName: displayedName, formErrors: formErrors, nickOptions: nickSet });
    }

    resetValid() {

        let formErrors = this.state.formErrors;

        Object.values(formErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            formErrors: formErrors
        })
    }

    resetTeamValid() {

        let teamFormErrors = this.state.teamFormErrors;

        Object.values(teamFormErrors).forEach(val => {
            val.isInvalid = false;
            val.isValid = false;
            val.info = "";
        })

        this.setState({
            teamFormErrors: teamFormErrors
        })
    }

    componentDidMount() {
        me().then((self) => {
            if (self.response === 1) {
                const cookies = new Cookies();
                const red = cookies.get('redirect');
                if (red === undefined) {
                    this.cookies.remove('redirect', { path: '/' });
                    this.props.history.push('/');
                } else {
                    this.props.history.push(red);
                }
            } else {
                this.setState({ loading2: false });
            }
        });

        http_post(`teams.php`).then((res) => {
            if (res.status === 200) {
                let t = [];
                t.push(<option disabled>Wybierz drużynę...</option>);
                res.data.map((prop) => {
                    t.push(<option value={prop.id}>{prop.name}</option>);
                })
                let y1 = [];
                y1.push(<option disabled>Wybierz rok...</option>)
                for (var i = 1969; i <= (new Date().getFullYear()); i++) {
                    y1.push(<option value={i}>{i}</option>);
                }
                let y2 = [];
                y2.push(<option disabled>Wybierz rok początkowy...</option>);
                this.setState({ loading: false, teams: t, yearsFrom: y1, yearsBy: y2 });
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Register&no=' + res.status + '&text=' + res.text + '&url=teams.php'
                });
            }
        })

        http_post(`registerAllowed.php`).then((res) => {
            if (res.status === 200) {
                if (res.data.value !== "1")
                    this.props.history.push({ pathname: '/logowanie' });
            } else {
                this.props.history.push({ pathname: '/logowanie' });
            }
        });
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                <Header />
                <Container className="registerForm">
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <h2><Lato>Rejestracja konta</Lato></h2>
                        {this.state.loadingShow ?
                            <div style={{ textAlign: 'center' }}>
                                <Spinner animation="border" variant="secondary" />
                            </div>
                            :
                            <>
                                {this.state.accountCreated ?
                                    <>
                                        <Alert variant="success">
                                            <Alert.Heading>Twoje konto zostało utworzone</Alert.Heading>
                                            <hr />
                                            <p className="mb-0">
                                                Na adres e-mail podany przy rejestracji został wysłany link aktywacyjny utworzonego konta.
                                            </p>
                                        </Alert>
                                        <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Przejdź do logowania</Link></Form.Text>
                                    </>
                                    :
                                    <>
                                        <Form.Row>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Imię</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.firstNameInput.isValid} isInvalid={this.state.formErrors.firstNameInput.isInvalid} onChange={this.handleChange} value={this.state.firstNameInput} name="firstNameInput" type="text" placeholder="Wprowadź imię" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.firstNameInput.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Nazwisko</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.lastNameInput.isValid} isInvalid={this.state.formErrors.lastNameInput.isInvalid} onChange={this.handleChange} value={this.state.lastNameInput} name="lastNameInput" type="text" placeholder="Wprowadź nazwisko" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.lastNameInput.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Hasło</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.passwordInput.isValid} isInvalid={this.state.formErrors.passwordInput.isInvalid} onChange={this.handleChange} value={this.state.passwordInput} name="passwordInput" type="password" placeholder="Wprowadź hasło" />
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        Twoje hasło musi zawierać minimum 8 znaków w tym minimum: jednej małej litery [a-z], jednej wielkiej litery [A-Z], jednej cyfry [0-9] i  jednego znaku specjalnego [# ? ! @ $ % ^ & * -].
                                            </Form.Text>
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.passwordInput.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Powtórz hasło</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.passwordRepeatInput.isValid} isInvalid={this.state.formErrors.passwordRepeatInput.isInvalid} onChange={this.handleChange} value={this.state.passwordRepeatInput} name="passwordRepeatInput" type="password" placeholder="Powtórz hasło" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.passwordRepeatInput.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Adres e-mail</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.emailInput.isValid} isInvalid={this.state.formErrors.emailInput.isInvalid} onChange={this.handleChange} value={this.state.emailInput} name="emailInput" type="email" placeholder="Wprowadź adres e-mail" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.emailInput.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm>
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <Form.Label>Czy jesteś lub byłeś/aś członkiem Pomarańczarni?</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.memberOf.isValid} isInvalid={this.state.formErrors.memberOf.isInvalid} value={this.state.memberOf} as="select" defaultValue="0" onChange={this.handleMemberOf}>
                                                        <option value="0" disabled>Wybierz...</option>
                                                        <option value="1">TAK</option>
                                                        <option value="2">NIE</option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.memberOf.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        {this.state.memberOf === "1" &&
                                            <>
                                                <hr />
                                                <Form.Row>
                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label>Przynależność do drużyny</Form.Label>
                                                            <Form.Control isValid={this.state.teamFormErrors.teamNameInput.isValid} isInvalid={this.state.teamFormErrors.teamNameInput.isInvalid} as="select" defaultValue="Wybierz drużynę..." value={this.state.teamIdInput} name="teamNameInput" onChange={this.handleChangeSelect}>
                                                                {this.state.teams}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">{this.state.teamFormErrors.teamNameInput.info}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label>Od</Form.Label>
                                                            <Form.Control isValid={this.state.teamFormErrors.teamYearFromInput.isValid} isInvalid={this.state.teamFormErrors.teamYearFromInput.isInvalid} as="select" defaultValue="Wybierz rok..." value={this.state.teamYearFromInput} name="teamYearFromInput" onChange={this.handleChangeSelect}>
                                                                {this.state.yearsFrom}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">{this.state.teamFormErrors.teamYearFromInput.info}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label>Do</Form.Label>
                                                            <Form.Control isValid={this.state.teamFormErrors.teamYearByInput.isValid} isInvalid={this.state.teamFormErrors.teamYearByInput.isInvalid} as="select" defaultValue="Wybierz rok początkowy..." value={this.state.teamYearByInput} name="teamYearByInput" onChange={this.handleChangeSelect}>
                                                                {this.state.yearsBy}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">{this.state.teamFormErrors.teamYearByInput.info}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Button variant="primary" className="mb-0" onClick={this.handleAddTeam}><LatoBold>Zapisz drużynę</LatoBold></Button>

                                                </Form.Row>
                                                <Form.Text id="passwordHelpBlock" muted>
                                                    Wprowadź swoją przynależność do drużyn Pomarańczarni, by zobaczyć dopasowane do Ciebie materiały archiwalne.
                                        </Form.Text>
                                                {this.state.myTeams.length > 0 ?
                                                    <>
                                                        <Table striped bordered style={{ marginTop: "10px" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Nazwa drużyny</th>
                                                                    <th>Zakres lat</th>
                                                                    <th>Opcje</th>
                                                                </tr>
                                                            </thead>
                                                            {this.state.myTeams.map((team) => (
                                                                <tr>
                                                                    <td style={{ verticalAlign: "middle" }}>
                                                                        {team.teamName}
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle" }}>
                                                                        {team.yearFrom} - {team.yearBy}
                                                                    </td>
                                                                    <td style={{ verticalAlign: "middle" }}>
                                                                        <Button variant="outline-danger" className="mb-0" onClick={() => this.handleDeleteTeam(team.id)}>Usuń</Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </Table>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }

                                            </>
                                        }

                                        <hr />

                                        <Form.Row>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Pseudonim</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.nickInput.isValid} isInvalid={this.state.formErrors.nickInput.isInvalid} onChange={this.handleChange} value={this.state.nickInput} name="nickInput" type="email" placeholder="Wprowadź pseudonim" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.nickInput.info}</Form.Control.Feedback>
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        Pole opcjonalne.
                                            </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Nazwisko rodowe</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.familyNameInput.isValid} isInvalid={this.state.formErrors.familyNameInput.isInvalid} onChange={this.handleChange} value={this.state.familyNameInput} name="familyNameInput" type="text" placeholder="Wprowadź nazwisko rodowe" />
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.familyNameInput.info}</Form.Control.Feedback>
                                                    <Form.Text id="passwordHelpBlock" muted>
                                                        Pole opcjonalne.
                                            </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Wyświetlanie nazwy użytkownika</Form.Label>
                                                    <Form.Control isValid={this.state.formErrors.nickOptions.isValid} isInvalid={this.state.formErrors.nickOptions.isInvalid} value={this.state.nickOptions} onChange={this.handleNickOptions} as="select" defaultValue="0">
                                                        <option value="0" disabled>Wybierz...</option>
                                                        <option value="1">Imię i nazwisko</option>
                                                        <option value="2">Imię i nazwisko (Nazwisko rodowe)</option>
                                                        <option value="3">Imię i nazwisko (Pseudnonim)</option>
                                                        <option value="4">Pseudonim</option>
                                                    </Form.Control>
                                                    <Form.Text muted>
                                                        Wybierz w jaki sposób będziesz podpisywany/na pod komentarzami.
                                            </Form.Text>
                                                    <Form.Control.Feedback type="invalid">{this.state.formErrors.nickOptions.info}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm>
                                                <Form.Group>
                                                    <Form.Label>Nazwa wyświetlana:</Form.Label>
                                                    <h3>{this.state.displayedName}</h3>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col lg>
                                                <Form.Group>
                                                    <Form.Label>Podgląd zdjęcia profilowego</Form.Label>
                                                    <div align="center">
                                                        {
                                                            this.state.image === null &&
                                                            <div style={{ width: 173, height: 182, padding: 20, border: 'thin dashed black' }}>
                                                                <FontAwesomeIcon icon={faUser} size="9x" />
                                                            </div>
                                                        }{
                                                            this.state.image !== null &&
                                                            <div onClick={() => { this.setState({ image: null }) }} className="profileDiv" style={{ width: 173, height: 182, border: 'thin dashed black' }}>
                                                                <img className="profileImg" width={171} height={180} src={this.state.image} />
                                                                <div class="middle">
                                                                    <div class="centered textProfile">
                                                                        <FontAwesomeIcon icon={faTrash} size="7x" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <Form.Text muted>
                                                        Dodawanie zdjęcia profilowego jest nieobowiązkowe.
                                            </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg>
                                                <Form.Group>
                                                    <Form.Label>Wybierz zdjęcie profilowe</Form.Label>
                                                    <ProfilePicturePicker thisRef={this} />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row className="mb-3">
                                            <a href="/files/CAP_Regulamin.pdf" target="_blank"><Button variant="outline-primary" className="mr-2 mt-1">Regulamin</Button></a>
                                            <a href="/files/CAP_Polityka.pdf" target="_blank"><Button variant="outline-primary" className="mr-2 mt-1">Polityka Prywatności</Button></a>
                                            <a href="/files/CAP_Klauzula.pdf" target="_blank"><Button variant="outline-primary" className="mt-1">Klauzula informacyjna</Button></a>
                                        </Form.Row>

                                        <Form.Group>
                                            <Form.Switch isValid={this.state.formErrors.switch2.isValid} isInvalid={this.state.formErrors.switch2.isInvalid} id="c2" label='Akceptuję Regulamin Cyfrowego Archiwum Pomarańczarni.' checked={this.state.switch2} onChange={this.handleSwitchChange(2)} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check isValid={this.state.formErrors.switch3.isValid} isInvalid={this.state.formErrors.switch3.isInvalid} id="c3" type="switch" label="Zapoznałem/am się z Polityką Prywatności." checked={this.state.switch3} onChange={this.handleSwitchChange(3)} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check isValid={this.state.formErrors.switch1.isValid} isInvalid={this.state.formErrors.switch1.isInvalid} id="c1" type="switch" label="Wyrażam zgodę na przetwarzanie moich danych osobowych przez Harcerską Fundację Turystyczną “Pomarańczarni” w celu założenia konta umożliwiającego korzystanie z Cyfrowego Archiwum Pomarańczarni." checked={this.state.switch1} onChange={this.handleSwitchChange(1)} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check id="c4" type="switch" label="Chcę otrzymywać wiadomości e-mailowe dotyczące działań Szczepu i Fundacji Pomarańczarni (newsletter)." checked={this.state.switch4} onChange={this.handleSwitchChange(4)} />
                                        </Form.Group>

                                        <Button disabled={this.state.loadingShow} type="submit" variant="primary" size="lg" block>
                                            <LatoBold>Utwórz konto</LatoBold>
                                        </Button>
                                        <Form.Text className="text-muted text-right"><Link to="/logowanie" className="linkRoute">Wróć do logowania</Link></Form.Text>

                                    </>

                                }
                            </>
                        }

                    </Form>
                </Container>

            </LoadingSpinner>
        );
    }
}

export default Register;