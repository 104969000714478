import React from "react";
import { Button, Row, Carousel, Col, Jumbotron, Container, Spinner, Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/IndexLogin.css';
import '../styles/RecentComments.css';
import '../styles/RecentFolders.css';
import styles from '../styles/IndexUser.module.css';
import Header from "./Header";
import Footer from "./Footer";
import LoadingSpinner from '../components/LoadingSpinner';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import { ToastContainer } from 'react-toastify';
import LatoBold from "./LatoBold";
import Lato from "./Lato";

class IndexUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            news: null,
            archive: null,
            comments: null,
            photos: null,
            recentComments: false,
            recentPhotos: false
        };
    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }

        http_post(`indexUser.php`, data).then((res) => {
            if (res.status === 200) {

                let res1, res2, res3, res4, res5;

                res1 = res.data.news.map((item) => {
                    if (item.url === '')
                        return (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={'https://archiwum.pomaranczarnia.org/photos/' + item.img}
                                />
                                <Carousel.Caption>
                                    {item.description}
                                </Carousel.Caption>
                            </Carousel.Item>
                        );
                    else
                        return (
                            <Carousel.Item>
                                <Link target="_blank" onClick={(event) => { event.preventDefault(); window.open(item.url); }}>
                                    <img
                                        className="d-block w-100"
                                        src={'https://archiwum.pomaranczarnia.org/photos/' + item.img}
                                    />
                                    <Carousel.Caption>
                                        {item.description}
                                    </Carousel.Caption>
                                </Link>
                            </Carousel.Item>
                        );
                });

                res2 = res.data.archive.map((item) => {
                    return (
                        <Col xs={6} xl={4} className="colFolder" align="center">
                            <Link to={"/folder/" + item.id} className="colLink">
                                <img src={item.mini} className="foldersImg" />
                                <div class="colLinkTextImg">{item.title}</div>
                            </Link>
                        </Col>
                    )
                });

                res3 = res.data.comments.map((item) => {
                    return (
                        <>
                            <Link to={"/" + (item.to_photo === "0" ? "galeria" : "folder") + "/" + item.fid_pid + "/" + item.id} className="comment-deco">
                                <Row>
                                    <Col xs={12} md={4} className="verticalCol">
                                        <img src={item.mini} className="commentImg" />
                                    </Col>
                                    <Col xs={12} md={8} className={styles.mediaPadding}>
                                        <div class="d-flex justify-content-between">
                                            <div class="nick-div">
                                                <strong class="nick">{item.uid}</strong>
                                            </div>
                                            <div>
                                                <div class="text-muted date">{item.added}</div>
                                            </div>
                                        </div>
                                        <p class="text-muted comment-text">
                                            {item.content}
                                        </p>
                                    </Col>
                                </Row>
                            </Link>
                            <hr />
                        </>
                    );
                });

                res4 = res.data.photos.map((item) => {
                    return (
                        <Carousel.Item>
                            <Link to={"/galeria/" + item.id}>
                                <img
                                    className="d-block w-100"
                                    src={'https://archiwum.pomaranczarnia.org/photos/' + item.filename}
                                />
                            </Link>
                        </Carousel.Item>
                    );
                });

                res5 = res.data.liked.map((item) => {
                    return (
                        <Col xs={6} xl={4} className="colFolder" align="center">
                            <Link to={"/galeria/" + item.id} className="colLink">
                                <img src={item.mini} className="foldersImg" />
                                <div class="colLinkTextImg">{item.title}</div>
                            </Link>
                        </Col>
                    )
                });

                this.setState({ news: res1, archive: res2, comments: res3, photos: res4, liked: res5, loading: false });

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=IndexUser&no=' + res.status + '&text=' + res.text + '&url=indexUser.php'
                });
            }
        })
    }

    recentComments() {

        if (this.state.recentComments) this.setState({ recentComments: false });
        else {
            const cookies = new Cookies();
            const token = cookies.get('token');

            const data = {
                token: token
            }

            http_post(`indexUserComments.php`, data).then((res) => {
                if (res.status === 200) {

                    let recCom;

                    recCom = res.data.map((item) => {
                        return (
                            <>
                                <Link to={"/" + (item.to_photo === "0" ? "galeria" : "folder") + "/" + item.fid_pid + "/" + item.id}>
                                    <Row>
                                        <Col xs={12} md={4} className="verticalCol">
                                            <img src={item.mini} className="commentImg" />
                                        </Col>
                                        <Col xs={12} md={8} className={styles.mediaPadding}>
                                            <div class="d-flex justify-content-between">
                                                <div class="nick-div">
                                                    <strong class="nick">{item.uid}</strong>
                                                </div>
                                                <div>
                                                    <div class="text-muted date">{item.added}</div>
                                                </div>
                                            </div>
                                            <p class="text-muted comment-text">
                                                {item.content}
                                            </p>
                                        </Col>
                                    </Row>
                                </Link>
                                <hr />
                            </>
                        );
                    });

                    this.setState({ recCom: recCom }, () => this.setState({ recentComments: true }));

                } else {
                    this.props.history.push({
                        pathname: '/error',
                        search: '?page=IndexUser&no=' + res.status + '&text=' + res.text + '&url=indexUserComments.php'
                    });
                }
            })

        }
    }

    recentPhotos() {

        if (this.state.recentPhotos) this.setState({ recentPhotos: false });
        else {
            const cookies = new Cookies();
            const token = cookies.get('token');

            const data = {
                token: token
            }

            http_post(`indexUserPhotos.php`, data).then((res) => {
                if (res.status === 200) {

                    let recPho;

                    recPho = res.data.map((item) => {
                        return (
                            <>
                                <Link to={"/" + "galeria" + "/" + item.id}>
                                    <Row>
                                        <Col xs={12} md={4} className="verticalCol">
                                            <img src={item.mini} className="commentImg" />
                                        </Col>
                                        <Col xs={12} md={8} className={styles.mediaPadding}>
                                            <div class="d-flex justify-content-between">
                                                <div class="nick-div">
                                                    <strong class="nick">{item.title}</strong>
                                                </div>
                                                <div>
                                                    <div class="text-muted date">{item.added}</div>
                                                </div>
                                            </div>
                                            <p class="text-muted comment-text">
                                                {item.description}
                                            </p>
                                        </Col>
                                    </Row>
                                </Link>
                                <hr />
                            </>
                        );
                    });

                    this.setState({ recPho: recPho }, () => this.setState({ recentPhotos: true }));

                } else {
                    this.props.history.push({
                        pathname: '/error',
                        search: '?page=IndexUser&no=' + res.status + '&text=' + res.text + '&url=indexUserComments.php'
                    });
                }
            })

        }
    }

    render() {
        return (

            <LoadingSpinner loading={this.state.loading}>
                <Header />
                <ToastContainer />
                <Container className="container">
                    <Modal show={this.state.recentComments} onHide={() => { this.setState({ recentComments: !this.state.recentComments }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Najnowsze komentarze</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.recCom}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => { this.setState({ recentComments: !this.state.recentComments }) }}><LatoBold>Zamknij</LatoBold></Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.recentPhotos} onHide={() => { this.setState({ recentPhotos: !this.state.recentPhotos }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Najnowsze zdjęcia</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.recPho}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={() => { this.setState({ recentPhotos: !this.state.recentPhotos }) }}><LatoBold>Zamknij</LatoBold></Button>
                        </Modal.Footer>
                    </Modal>
                    <Row>
                        <Col xl={6} sm={12} className="noPadding">
                            {
                                Array.isArray(this.state.news) && this.state.news.length ?
                                    <Col sm={12}>
                                        <Card className="card indexCard">
                                            <Card.Header className="indexHeader"><Lato>AKTUALNOŚCI</Lato></Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Carousel>
                                                        {this.state.news}
                                                    </Carousel>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    :
                                    <></>
                            }
                            {
                                Array.isArray(this.state.archive) && this.state.archive.length ?
                                    <Col sm={12}>
                                        <Card className="card indexCard">
                                            <Card.Header className="indexHeader"><Lato>PRZEGLĄDAJ ARCHIWUM</Lato></Card.Header>
                                            <Card.Body>
                                                <Row className="align-iteams-center flex-wrap">
                                                    {this.state.archive}
                                                </Row>
                                                <div class="text-right">
                                                    <Button href="/galeria" variant="secondary" className="button"><LatoBold>Przeglądaj wszystkie foldery</LatoBold></Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    :
                                    <></>
                            }
                            {
                                Array.isArray(this.state.liked) && this.state.liked.length ?
                                    <Col sm={12}>
                                        <Card className="card indexCard">
                                            <Card.Header className="indexHeader"><Lato>MOJE ULUBIONE</Lato></Card.Header>
                                            <Card.Body>
                                                <Row className="align-iteams-center flex-wrap">
                                                    {this.state.liked}
                                                </Row>
                                                <div class="text-right">
                                                    <Button href="/profil" variant="secondary" className="button"><LatoBold>Przeglądaj wszystkie ulubione</LatoBold></Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    :
                                    <></>
                            }
                        </Col>
                        <Col xl={6} sm={12} className="noPadding">
                            {
                                Array.isArray(this.state.comments) && this.state.comments.length ?
                                    <Col sm={12}>
                                        <Card className="card indexCard">
                                            <Card.Header className="indexHeader"><Lato>NAJNOWSZE KOMENTARZE</Lato></Card.Header>
                                            <Card.Body>
                                                {this.state.comments}
                                                <div class="text-right">
                                                    <Button variant="secondary" onClick={() => this.recentComments()} className="button"><LatoBold>Zobacz wszystkie najnowsze komentarze</LatoBold></Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    :
                                    <></>
                            }
                            {
                                Array.isArray(this.state.photos) && this.state.photos.length ?
                                    <Col sm={12} >
                                        <Card className="card indexCard">
                                            <Card.Header className="indexHeader"><Lato>NAJNOWSZE ZDJĘCIA</Lato></Card.Header>
                                            <Card.Body>
                                                <Carousel>
                                                    {this.state.photos}
                                                </Carousel>
                                                <div class="text-right">
                                                    <Button variant="secondary" onClick={() => this.recentPhotos()} className="button"><LatoBold>Zobacz wszystkie najnowsze zdjęcia</LatoBold></Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    :
                                    <></>
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </LoadingSpinner>
        )
    }
}

export default IndexUser;