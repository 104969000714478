import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, TableHeaderColumn } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfo, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

const { SearchBar } = Search;

class AdminCommentReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "label",
            sortOrder: "desc",
            newTagInput: "",
            formErrors: {
                newTagInput: {
                    isValid: false,
                    isInvalid: false,
                    info: ""
                }
            },
            show: false,
            modalDelete: {
                label: "",
                value: null
            },
            modalPreview: {
                loading: false,
                report: ""
            }
        };

        this.refreshData = this.refreshData.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
    }

    componentDidMount() {
        this.refreshDataManually();
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }

        http_post(`reportTableComment.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.comments.map((row) => {

                    row.photo = <><Link to={(row.to_photo === "0" ? "/galeria/" : "/folder/") + row.fid_pid} className="colLink"><img src={row.mini} /></Link></>;
                    row.path = <>{row.year}<br />{row.title}</>;
                    row.button = <>
                        <Button className="mr-1" variant="primary" onClick={() => { this.handlePreview([row.value]) }}><FontAwesomeIcon icon={faInfo} /></Button>
                        <Button variant="danger" onClick={() => { this.handleDelete(row.value, row.nickname, row.added) }}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=adminTags&no=' + res.status + '&text=' + res.text + '&url=reportTablePhoto.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleClose2() {

        this.setState({ show2: !this.state.show2, modal_miniPhoto: null })
    }

    handleDelete(value, nickname, added) {
        this.setState({
            modalDelete: {
                value: value,
                nickname: nickname,
                added: added
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteReportComment.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false }, () => { toast.success("Poprawnie usunięto zgłoszenie!") });
                    this.refreshDataManually();
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć zgłoszenia!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        switch (column.dataField) {
            case "closed":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (oldValue === newValue) {
                        done(false);
                    } else {
                        http_post(`editReportComment.php`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono status!")
                                    done();
                                    this.refreshDataManually('pagination');
                                } else {
                                    toast.error("Nie udało się zmienić statusu!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }

                }, 0);
                break;
        }
        return { async: true };
    }

    handlePreview(value) {
        this.setState({
            modalPreview: {
                loading: true
            }
        }, () => {

            this.setState({ show2: true }, () => {

                const cookies = new Cookies();
                const token = cookies.get('token');

                const data = {
                    token: token,
                    value: value
                }

                http_post(`reportTableCommentModal.php`, data).then((res) => {
                    if (res.status === 200) {

                        this.setState({
                            modalPreview: {
                                loading: false,
                                value: value,
                                report: res.data.report,
                                content: res.data.content,
                                author: res.data.author,
                                added: res.data.added,
                                cid: res.data.cid,
                                closed: value,
                                hidden: res.data.hidden
                            }
                        })

                    } else {
                        this.props.history.push({
                            pathname: '/error',
                            search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=reportsTablePhotoModal.php'
                        });
                    }
                })
            })
        })
    }

    deleteComment(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteComment.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show2: false }, () => { toast.success("Poprawnie usunięto komentarz! Zgłoszenie zostało usunięte.") });
                    this.refreshDataManually();
                } else {
                    this.setState({ show2: false }, () => { toast.error("Nie udało się usunąć komentarza!") });
                }
            } else {
                this.setState({ show2: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    hideComment(value, closed) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value,
            label: "TAK",
            closed: closed
        }

        http_post(`editComment.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show2: false }, () => { toast.success("Poprawnie ukryto komentarz! Zgłoszenie zostało zakończone.") });
                    this.refreshDataManually();
                } else {
                    this.setState({ show2: false }, () => { toast.error("Nie udało się ukryć komentarza!") });
                }
            } else {
                this.setState({ show2: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }


    render() {
        const columns = [{
            dataField: 'photo',
            text: 'Nazwa zdjęcia',
            sort: false,
            editable: false,
            editorStyle: {
                textAlign: "center"
            },
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'nickname',
            text: 'Zgłaszający',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'added',
            text: 'Data zgłoszenia',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'closed',
            text: 'Zakończone',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editCellStyle: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: [{
                    value: 'TAK',
                    label: 'TAK'
                }, {
                    value: 'NIE',
                    label: 'NIE'
                }]
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }];

        const defaultSorted = [{
            dataField: 'added',
            order: 'desc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        const rowStyle2 = (row, rowIndex) => {
            const style = {};
            if (row.closed === "NIE") {
                style.backgroundColor = '#c8e6c9';
            }

            return style;
        };

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={3}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>ZGŁOSZENIA KOMENTARZY</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Lista zgłoszeń komentarzy</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.comments}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak zgłoszeń!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                        rowStyle={rowStyle2}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Usuwanie zgłoszenia</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć zgłoszenie użytkownika <strong>{this.state.modalDelete.nickname}</strong> zgłoszone <strong>{this.state.modalDelete.added}</strong>?</p>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={this.state.show2} onHide={() => { this.handleClose2() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title><Lato>Szczegóły zgłoszenia</Lato></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    {this.state.modalPreview.loading ?
                                        <div className="text-center">
                                            <Spinner animation="grow" variant="warning" />
                                        </div>
                                        :
                                        <>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label><strong>Zgłaszany komentarz</strong></Form.Label>
                                                    <p><strong>{this.state.modalPreview.author}</strong> ({this.state.modalPreview.added})</p>
                                                    <p className="text-muted">{this.state.modalPreview.content}</p>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label><strong>Treść zgłoszenia</strong></Form.Label>
                                                    <p>{this.state.modalPreview.report}</p>
                                                </Form.Group>
                                                <Button variant="danger" onClick={() => { this.deleteComment(this.state.modalPreview.cid) }}><FontAwesomeIcon icon={faTrash} /> <LatoBold>Usuń komentarz</LatoBold></Button> {this.state.modalPreview.hidden === "0" ? <Button variant="secondary" onClick={() => { this.hideComment(this.state.modalPreview.cid, this.state.modalPreview.closed) }}><FontAwesomeIcon icon={faEyeSlash} /> <LatoBold>Ukryj komentarz</LatoBold></Button> : ""}
                                                <Form.Text muted>Zgłaszany komentarz aktualnie jest <strong>{this.state.modalPreview.hidden === "0" ? "opublikowany" : "ukryty lub wyfiltrowany"}</strong>.</Form.Text>
                                            </Form>
                                        </>
                                    }
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose2() }} variant="outline-primary"><LatoBold>Zamknij</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner>
        );
    }
}

export default AdminCommentReport;