import React from "react";
import { Button } from 'react-bootstrap';
import { Redirect, Link } from "react-router-dom";
import qs from 'qs';

class Error extends React.Component {
    render() {
        let page = undefined;
        let text = undefined;
        let no = undefined;
        let url = undefined;

        if (this.props.page === undefined)
            page = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).page;
        else
            page = this.props.page;

        if (this.props.text === undefined)
            text = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).text;
        else
            text = this.props.text;

        if (this.props.no === undefined)
            no = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).no;
        else
            no = this.props.no;

        if (this.props.url === undefined)
            url = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).url;
        else
            url = this.props.url;


        if (page === undefined) {
            return (<Redirect to="/" />);
        } else if (no === undefined || text === undefined) {
            return (<Redirect to={"/" + page} />);
        } else {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100vw', top: '0px', bottom: '0px' }}>
                    <div>
                        <h1>Wystąpił błąd: {no}</h1>
                        <h3>Szczegóły: {text}</h3>
                        <h3>Lokalizacja: {page}</h3>
                        <h3>Url: {url}</h3>
                        <br />
                        <Link to="/"><Button size="lg" variant="primary">Przejdź na stronę główną</Button></Link>
                    </div>
                </div>
            );
        }
    }
}

export default Error;