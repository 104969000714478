import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import http_post from '../scripts/post';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Button, Breadcrumb, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/Folder.module.css';
import Comments from '../components/Comments';
import Lato from "../components/Lato";

function nl2br(arr) {
    return arr.split('\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>
    })
}

const enums = {
    "0": 10,
    "1": 15,
    "2": 25,
    "3": 50
};

class Folder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: null,
            commentLists: [],
            page: 1,
            controlNumber: 0,
            amount: 0,
            newest: 0,
            total: 0
        };

        this.updateComment = this.updateComment.bind(this);
        this.refreshAmount = this.refreshAmount.bind(this);
        this.refreshNewest = this.refreshNewest.bind(this);
        this.handlerPage = this.handlerPage.bind(this);
        this.updateReplies = this.updateReplies.bind(this);
    }

    componentDidMount() {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            fid: this.props.match.params.id,
            token: token
        }

        http_post(`folder.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data === 'empty')
                    this.props.history.push({ pathname: '/galeria' });
                else
                    this.setState({ data: res.data[0], loading: false });
            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Folder&no=' + res.status + '&text=' + res.text + '&url=folder.php'
                });
            }
        });

        this.updateComments();
    }

    updateComments() {
        const cookies = new Cookies();
        const token = cookies.get('token');
        let amount = 10;
        let newest = true;
        switch (this.state.amount) {
            case "1":
                amount = 15;
                break;
            case "2":
                amount = 25;
                break;
            case "3":
                amount = 50;
                break;
            default:
                amount = 10;
                break;
        }
        switch (this.state.newest) {
            case "1":
                newest = false;
                break;
            default:
                newest = true;
                break;
        }
        const data2 = {
            token: token,
            pid: this.props.match.params.id,
            page: this.state.page,
            type: 1,
            amount: amount,
            newest: newest
        }

        http_post(`comments.php`, data2).then((res) => {
            if (res.status === 200) {

                if (res.data !== '')
                    this.setState({ commentLists: res.data.comments, total: parseInt(res.data.amount), realAmount: res.data.comments.length });

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=comments.php'
                });
            }
        })


    }

    updateComment(newComment) {

        if (this.state.newest == "0") {
            if (this.state.page != 1) {
                this.handlerPage("first");
            } else {
                this.setState({ controlNumber: this.state.controlNumber + 1 },() => {this.setState({commentLists: [newComment, ...this.state.commentLists]})})
            }
        } else {
            if (this.state.page == 1) {
                if (this.state.total < enums[this.state.amount]) {
                    this.setState({ commentLists: [...this.state.commentLists, newComment] })

                }

            } else {
                if (this.state.page == Math.ceil(parseInt(this.state.total) / parseInt(enums[this.state.amount]))) {
                    if (this.state.realAmount < enums[this.state.amount]) {
                        this.setState({ commentLists: [...this.state.commentLists, newComment], realAmount: this.state.realAmount + 1 })
                    } else {
                        this.handlerPage("last")
                    }

                }
            }
        }
        this.forceUpdate()
    }

    refreshAmount(amount) {
        this.setState({ amount: amount }, () => {
            this.updateComments();
        });
    }

    refreshNewest(newest) {
        this.setState({ newest: newest }, () => {
            this.updateComments();
        });
    }

    handlerPage(page) {

        this.setState({ switch: true }, () => {
            this.forceUpdate()
            switch (page) {
                case "first":
                    this.setState({ page: 1 }, () => { this.updateComments(); });
                    break;
                case "last":
                    this.setState({ page: Math.ceil(parseInt(this.state.total) / parseInt(enums[this.state.amount])) }, () => { this.updateComments(); });
                    break;
                case "next":
                    this.setState({ page: this.state.page + 1 }, () => { this.updateComments(); })
                    break;
                case "previous":
                    this.setState({ page: this.state.page - 1 }, () => { this.updateComments(); })
                    break;
                default:
                    this.setState({ page: page }, () => { this.updateComments(); });
                    break;
            }

        });



        this.setState({ switch: false });

    }

    updateReplies(id) {
        for (let i in this.state.commentLists) {
            if (this.state.commentLists[i].id === id) {
                this.state.commentLists[i].amount++;
                break;
            }
        }
        this.forceUpdate()
    }

    render() {
        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin>
                    <Header />

                    {
                        this.state.data !== null ? (
                            <Container className="container">

                                <Row>
                                    <Col xl={12}>
                                        <Navbar bg="light" className="breadcrumbNav">
                                            <div className="breadcrumbImage mx-auto order-0">
                                                <span className="fakeBreadcrumb d-none d-md-none d-lg-flex"><Breadcrumb.Item align="center" href="/galeria">Galeria</Breadcrumb.Item></span>
                                                <Breadcrumb.Item align="center" href={"/rok/" + this.state.data.year}>{this.state.data.year}</Breadcrumb.Item>
                                                <Breadcrumb.Item active>{this.state.data.title}</Breadcrumb.Item>
                                            </div>
                                        </Navbar>
                                    </Col>
                                </Row>

                                <Row className={styles.folderInfo}>
                                    <Col xl={12}>
                                        <Card>
                                            <Card.Header><Lato>INFORMACJE</Lato></Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Card.Title>Nazwa folderu</Card.Title>
                                                        <Card.Text>
                                                            {this.state.data.title}
                                                        </Card.Text>
                                                    </Col>
                                                    <Col lg={4} className={styles.mediaPadding}>
                                                        <Card.Title>Opis folderu</Card.Title>
                                                        <Card.Text>
                                                            {nl2br(this.state.data.description)}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Card>
                                    <Card.Header><Lato>ZAWARTOŚĆ FOLDERU</Lato></Card.Header>
                                    <Card.Body>
                                        <Row className="align-iteams-center flex-wrap">
                                            {
                                                this.state.data.photos.map((photo) => {
                                                    return (
                                                        <Col xs={6} md={4} xl={2} className="colFolder" align="center">
                                                            <Link to={"/galeria/" + photo.id} className="colLink">
                                                                <img src={photo.mini} className="foldersImg" />
                                                                <div class="colLinkText">{photo.no}</div>
                                                            </Link>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Comments control={this.state.controlNumber} switch={this.state.switch} updateReplies={this.updateReplies} handlerPage={this.handlerPage} total={this.state.total} amount={this.state.amount} newest={this.state.newest} page={this.state.page} refreshAmount={this.refreshAmount} refreshNewest={this.refreshNewest} commentLists={this.state.commentLists} id={this.props.match.params.id} refreshFunction={this.updateComment} type="folder" />
                            </Container>
                        ) : <></>
                    }
                    
                </RequireLogin >
            </LoadingSpinner >
        );
    }
}

export default Folder;