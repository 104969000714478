import React from "react";
import { Container, Spinner } from 'react-bootstrap';
import Header from '../components/AdminHeader';
import Cookies from 'universal-cookie';
import LoadingSpinner from '../components/LoadingSpinner';
import RequireLogin from '../components/RequireLogin';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import http_post from '../scripts/post';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfo, faFolder } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

const { SearchBar } = Search;

class AdminComments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "label",
            sortOrder: "desc",
            newFolderInput: "",
            descriptionInput: "",
            folderYearInput: null,
            miniOption: 0,
            miniUploadingInProgress: false,
            miniDefault: null,
            miniPhoto: null,
            modal_miniOption: 0,
            modal_miniUploadingInProgress: false,
            modal_miniPhoto: null,
            modal_miniSelectOptions: [],
            modal_miniSelected: null,
            editDescriptionInput: "",
            editMiniOption: 0,

            show: false,
            modalDelete: {
                mini: null,
                value: null
            }

        };

        this.refreshData = this.refreshData.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);

    }



    componentDidMount() {
        this.refreshDataManually();

        const cookies = new Cookies();
        const token = cookies.get('token');

        const data = {
            token: token
        }
    }

    refreshDataManually(type = null) {
        this.refreshData(type, { page: this.state.page, sizePerPage: this.state.sizePerPage, searchText: this.state.searchText, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    refreshData(type, newState) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        if (newState.searchText === undefined) newState.searchText = "";
        if (type !== 'pagination') newState.page = 1;

        const data = {
            token: token,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder
        }



        http_post(`commentsTable.php`, data).then((res) => {
            if (res.status === 200) {

                res.data.comments.map((row) => {
                    row.photo = <><Link to={(row.to_photo === "0" ? "/galeria/" : "/folder/") + row.fid_pid} className="colLink"><img src={row.mini} /></Link></>;
                    row.details = <>{row.nickname}<br />{row.added}</>
                    row.button = <>
                        <Button className="ml-1" variant="danger" onClick={() => { this.handleDelete(row.value, row.nickname, row.content, row.added) }}><FontAwesomeIcon icon={faTrash} /></Button>
                    </>;
                })

                this.setState({ page: newState.page, sizePerPage: newState.sizePerPage, data: res.data, searchText: newState.searchText, sortField: newState.sortField, sortOrder: newState.sortOrder })

            } else {
                this.props.history.push({
                    pathname: '/error',
                    search: '?page=Comments&no=' + res.status + '&text=' + res.text + '&url=photosTable.php'
                });
            }
        })
    }

    handleClose() {
        this.setState({ show: !this.state.show })
    }

    handleDelete(value, nickname, content, added) {
        this.setState({
            modalDelete: {
                value: value,
                nickname: nickname,
                content: content,
                added: added
            }
        }, () => {
            this.setState({ show: true })
        })
    }

    deleteRow(value) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const data = {
            token: token,
            value: value
        }
        http_post(`deleteComment.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.setState({ show: false, onSelect: [] }, () => { toast.success("Poprawnie usunięto komentarz!") });
                    this.refreshDataManually();
                } else {
                    this.setState({ show: false }, () => { toast.error("Nie udało się usunąć komentarza!") });
                }
            } else {
                this.setState({ show: false }, () => { toast.error("Błąd transmisji danych!") });
            }
        })
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        switch (column.dataField) {
            case "hidden":
                setTimeout(() => {
                    const cookies = new Cookies();
                    const token = cookies.get('token');
                    const data = {
                        token: token,
                        value: row.value,
                        label: newValue
                    }
                    if (oldValue === newValue) {
                        done(false);
                    } else {
                        http_post(`editComment.php`, data).then((res) => {
                            if (res.status === 200) {
                                if (res.data.valid === 1) {
                                    toast.success("Poprawnie zmieniono widoczność!")
                                    this.refreshDataManually('pagination');
                                    done();
                                } else {
                                    toast.error("Nie udało się zmienić widoczności!")
                                    done(false);
                                }
                            } else {
                                toast.error("Błąd transmisji danych!")
                                done(false);
                            }
                        })
                    }

                }, 0);
                break;
        }
        return { async: true };
    }

    render() {



        const columns = [{
            dataField: 'photo',
            text: 'Element',
            sort: true,
            editorStyle: {
                textAlign: "center"
            },
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'content',
            text: 'Komentarz',
            sort: true,
            editable: false,
            style: {
                textAlign: "justify",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'nickname',
            text: 'Autor',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'added',
            text: 'Data dodania',
            sort: true,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            }
        }, {
            dataField: 'hidden',
            text: 'Ukryty',
            sort: true,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editCellStyle: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            editor: {
                type: Type.SELECT,
                options: [{
                    value: 'TAK',
                    label: 'TAK'
                }, {
                    value: 'NIE',
                    label: 'NIE'
                }, {
                    value: 'WYFILTROWANY',
                    label: 'WYFILTROWANY'
                }]
            }
        }, {
            dataField: 'button',
            text: 'Opcje',
            sort: false,
            editable: false,
            style: {
                textAlign: "center",
                verticalAlign: "middle"
            },
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            }
        }];


        const defaultSorted = [{
            dataField: 'added',
            order: 'desc'
        }];

        const editor = cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            beforeSaveCell: this.beforeSaveCell
        })

        let pagination = null;

        if (this.state.data !== undefined) {
            pagination = paginationFactory({
                page: parseInt(this.state.page),
                totalSize: parseInt(this.state.data.amount),
                sizePerPage: parseInt(this.state.sizePerPage),
                paginationSize: 3,  // the pagination bar size, default is 5
                showTotal: true, // display pagination information
                sizePerPageList: [{
                    text: '5', value: 5
                }, {
                    text: '10', value: 10
                }, {
                    text: '20', value: 20
                }, {
                    text: '50', value: 50
                }, {
                    text: 'Wszystkie', value: this.state.data.amount
                }], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
                withFirstAndLast: true, // hide the going to first and last page button
                alwaysShowAllBtns: true, // always show the next and previous page button
                firstPageText: '<<', // the text of first page button
                prePageText: '<', // the text of previous page button
                nextPageText: '>', // the text of next page button
                lastPageText: '>>', // the text of last page button
                nextPageTitle: 'Go to next', // the title of next page button
                prePageTitle: 'Go to previous', // the title of previous page button
                firstPageTitle: 'Go to first', // the title of first page button
                lastPageTitle: 'Go to last', // the title of last page button
                hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
                paginationTotalRenderer: (from, to, size) => {
                    if (size === "0") return " Niczego nie znaleziono!"
                    else return ' Wyniki od ' + from + ' do ' + to + ' z ' + size
                }
            });
        }

        const rowStyle2 = (row, rowIndex) => {
            const style = {};
            if (row.hidden === "TAK") {
                style.backgroundColor = '#9dabea';
            }

            if (row.hidden === "WYFILTROWANY") {
                style.backgroundColor = '#ea9d9e';
            }

            return style;
        };

        return (
            <LoadingSpinner loading={this.state.loading}>
                <RequireLogin level={3}>
                    <Header />
                    {this.state.data !== undefined &&
                        <Container className="container">
                            <ToastContainer />
                            <Card>
                                <Card.Header><Lato>KOMENTARZE</Lato></Card.Header>
                                <Card.Body>
                                    <Card.Title><Lato>Lista komentarzy</Lato></Card.Title>
                                    <ToolkitProvider
                                        keyField='value'
                                        data={this.state.data.comments}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className="text-right">
                                                        Wyszukaj: <SearchBar {...props.searchProps} placeholder="Nazwa" delay={2000} />
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        defaultSorted={defaultSorted}
                                                        bootstrap4
                                                        striped
                                                        hover
                                                        remote={{ pagination: true, search: true, sort: true }}
                                                        pagination={pagination}
                                                        noDataIndication="Brak komentarzy!"
                                                        cellEdit={editor}
                                                        onTableChange={this.refreshData}
                                                        rowStyle={rowStyle2}
                                                    >
                                                    </BootstrapTable>

                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                </Card.Body>
                            </Card>
                            <Modal show={this.state.show} onHide={() => { this.handleClose() }}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Usuwanie komentarza</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <p>Czy na pewno chcesz usunąć komentarz użytkownika <strong>{this.state.modalDelete.nickname}</strong> dodany <strong>{this.state.modalDelete.added}</strong>?</p>
                                    <p className="text-muted">{this.state.modalDelete.content}</p>


                                </Modal.Body>

                                <Modal.Footer>
                                    <Button onClick={() => { this.handleClose() }} variant="outline-primary"><LatoBold>Anuluj</LatoBold></Button>
                                    <Button onClick={() => { this.deleteRow(this.state.modalDelete.value) }} variant="primary"><LatoBold>Usuń</LatoBold></Button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    }
                </RequireLogin>
            </LoadingSpinner >
        );
    }
}

export default AdminComments;