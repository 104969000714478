import React from "react";
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/Contact.css';
import Lato from "../components/Lato";

class Contact extends React.Component {

    render() {

        return (
            <>
                <Header />
                <Container className="container">
                    <h2><Lato>Kontakt</Lato></h2>
                    <p>
                                <strong>Harcerska Fundacja Turystyczna "Pomarańczarni"</strong><br />
                                ul. Myśliwiecka 6<br />
                                00-469 Warszawa<br />
                                NIP: 526-16-70-446<br />
                                KRS: 0000211613<br />
                                REGON: 010824220<br />
                                fundacja@pomaranczarnia.org<br />
                                archiwum@pomaranczarnia.org
                            </p>
                        
                </Container>
                
            </>
        );
    }
}

export default Contact;