import React from 'react';
import { Navbar, Nav, Form, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/RecentFolders.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import Lato from './Lato';

class SimiliarContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        this.props.data.then((res) => {
            this.setState({ data: res.data[0] })
        });
    }

    render() {
        if (this.state.data === null)
            return <></>
        else
            return (

                <>
                    <Card>
                        <Card.Header><Lato>ZOBACZ PODOBNE</Lato></Card.Header>
                        <Card.Body>
                            <Row className="align-iteams-center flex-wrap">
                                {
                                    this.state.data.similar.map((item) => {
                                        return (
                                            <Col xs={6} xl={2} className="colFolder" align="center">
                                                <Link to={"/galeria/" + item.id} onClick={() => { window.location.href = "/galeria/" + item.id }} className="colLink">
                                                    <img src={item.mini} className="foldersImg" />
                                                    <div class="colLinkText">{item.title}</div>
                                                </Link>
                                            </Col>
                                        )
                                    })
                                }


                            </Row>
                        </Card.Body>
                    </Card>

                </>

            );
    }
}


export default SimiliarContent;