import React from "react";
import RequireLogin from '../components/RequireLogin';
import AdminHeader from '../components/AdminHeader';
import { Row, Col, Navbar, Breadcrumb, Card, Button, Container, Tabs, Tab, Nav, Badge, ProgressBar, Alert } from 'react-bootstrap';
import Step1 from '../components/adminNewPhoto/step1';
import Step2 from '../components/adminNewPhoto/step2';
import Step3 from '../components/adminNewPhoto/step3';
import Step4 from '../components/adminNewPhoto/step4';
import Cookies from 'universal-cookie';
import http_post from '../scripts/post';
import styles from '../styles/AdminNewPhoto.module.css';
import '../styles/AdminNewPhoto.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Lato from '../components/Lato';
import LatoBold from '../components/LatoBold';

class AdminNewPhoto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            step1year: null,
            step1folder: null,
            step1visited: false,
            step2visited: false,
            step2tags: null,
            step2tagsPossible: [],
            step2description: React.createRef(null),
            step2day: null,
            step2month: null,
            step2hidden: false,
            step3visited: false,
            step3images: [],
            step3imagesCount: 0,
            step3loading: false,
            uploadingStatus: 0,
            uploadingInProgress: false,
            jobDone: false,
            uploadingStarted: false
        };

        this.submitPhotos = this.submitPhotos.bind(this);
        this.copyPropertiesGlobalToLocal = this.copyPropertiesGlobalToLocal.bind(this);
    }

    getDays(m) {
        let days;
        if (m === 1 || m === 3 || m === 5 || m === 7 || m === 8 || m === 10 || m === 12)
            days = 31;
        else if (m === 2)
            days = (parseInt(this.state.step1year.label) % 4 === 0 && parseInt(this.state.step1year.label) % 100 !== 0 ? 29 : 28);
        else
            days = 30;

        let result = [];
        for (let i = 1; i <= days; i++)
            result.push({ value: i, label: i });
        return result;
    }

    copyPropertiesGlobalToLocal() {
        if (this.state.step2description.current !== null) {
            if (!this.state.step3visited) {
                let images = [];
                for (let i in this.state.step3images) {
                    let dd = this.state.step3images[i].description;
                    dd.current.value = this.state.step2description.current.value;
                    const elem = { tags: this.state.step2tags, description: dd, month: this.state.step2month, day: this.state.step2day, daysOptions: (this.state.step2month === null ? [] : this.getDays(this.state.step2month.value)), hidden: this.state.step2hidden, src: this.state.step3images[i].src };
                    images.push(elem);
                }
                this.setState({ step3visited: true, step3images: images });
            }
        }
    }

    currentStep() {
        if (this.state.step1year === null || this.state.step1folder === null)
            return 1;
        else if (!this.state.step2visited)
            return 2;
        else if (!Array.isArray(this.state.step3images) || !this.state.step3images.length || this.state.step3loading || !this.state.step3visited)
            return 3;
        else
            return 4;
    }

    removePhoto(index) {
        let arr = this.state.step3images;
        arr.splice(index, 1);
        this.setState({ step3images: arr });
    }

    uploadPhoto(i) {
        const cookies = new Cookies();
        const token = cookies.get('token');
        const elem = { tags: this.state.step3images[i].tags, description: this.state.step3images[i].description.current.value, month: this.state.step3images[i].month, day: this.state.step3images[i].day, hidden: this.state.step3images[i].hidden, src: this.state.step3images[i].src };

        const data = {
            token: token,
            year: this.state.step1year.value,
            folder: this.state.step1folder.value,
            images: JSON.stringify(elem)
        }

        http_post(`adminNewPhoto.php`, data).then((res) => {
            if (res.status === 200) {
                if (res.data.valid === 1) {
                    this.removePhoto(i);
                    i = i - 1;
                }
            }
            this.setState({ uploadingStatus: parseInt(this.state.uploadingStatus) + 1 });
            if (i + 1 < this.state.step3images.length)
                this.uploadPhoto(i + 1);
            else
                this.setState({ jobDone: true, uploadingInProgress: false, step3imagesCount: this.state.step3images.length });
        })
    }

    submitPhotos() {
        this.setState({ uploadingStarted: true, uploadingStatus: 0, uploadingInProgress: true, step3imagesCount: this.state.step3images.length, jobDone: false }, () => this.uploadPhoto(0));
    }

    addMorePhotos() {
        this.setState({
            step2visited: false,
            step2tags: null,
            step2tagsPossible: [],
            step2description: React.createRef(null),
            step2day: null,
            step2month: null,
            step2hidden: false,
            step3visited: false,
            step3images: [],
            step3imagesCount: 0,
            step3loading: false,
            uploadingStatus: 0,
            uploadingInProgress: false,
            jobDone: false,
            uploadingStarted: false
        }, () => this.setState({ step: 2 }));
    }

    render() {
        return (
            <RequireLogin level={2}>
                <AdminHeader />
                <Container>
                    <Card>
                        <Card.Header><Lato>DODAWANIE ZDJĘĆ</Lato></Card.Header>
                        <Card.Body>
                            <Tab.Container
                                activeKey={this.state.step}
                                onSelect={(s) => { this.setState({ step: s }) }}
                            >
                                <Row>
                                    <Col sm={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item className={styles.navItem}>
                                                <Nav.Link className={styles.navItem} eventKey={1} disabled={this.state.step3loading || this.state.uploadingStarted}> <Badge variant="warning">1</Badge> Wybór ścieżki</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className={styles.navItem} eventKey={2} disabled={this.currentStep() < 2 || this.state.step3loading || this.state.uploadingStarted}> <Badge variant="warning">2</Badge> Wspólne właściwości</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className={styles.navItem} eventKey={3} disabled={this.currentStep() < 3 || this.state.uploadingStarted}> <Badge variant="warning">3</Badge> Wgrywanie i edycja</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className={styles.navItem} eventKey={4} disabled={this.currentStep() < 4}> <Badge variant="warning">4</Badge> Podsumowanie</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey={1}>
                                                <Step1 thisRef={this} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={2} >
                                                <Step2 thisRef={this} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={3} >
                                                <Step3 thisRef={this} cf={this.copyPropertiesGlobalToLocal} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={4} >
                                                {
                                                    this.state.jobDone ?
                                                        <>
                                                            {
                                                                Array.isArray(this.state.step3images) && this.state.step3images.length ?
                                                                    <>
                                                                        {
                                                                            this.state.uploadingStatus === this.state.step3images.length ?
                                                                                <>
                                                                                    <Alert variant="danger">
                                                                                        <Alert.Heading>Błąd przesyłania</Alert.Heading>
                                                                                        <p> Nie udało się wgrać żadnego zdjęcia. Spróbuj ponownie lub skontaktuj się z administratorem.</p>
                                                                                    </Alert>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Alert variant="warning">
                                                                                        <Alert.Heading>Częściowy błąd przesyłania</Alert.Heading>
                                                                                        <p>Wgrano pomyślnie {this.state.uploadingStatus - this.state.step3images.length} z {this.state.uploadingStatus} zdjęć. Nie udało się wgrać {this.state.step3images.length} zdjęć - możesz przejrzeć je poniżej. </p>
                                                                                        <hr />
                                                                                        <p className="mb-0">Spróbuj ponownie lub skontaktuj się z administratorem.</p>
                                                                                    </Alert>
                                                                                </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Alert variant="success">
                                                                            <Alert.Heading>Sukces!</Alert.Heading>
                                                                            <p>Pomyślnie wgrano {this.state.uploadingStatus} zdjęcia!</p>

                                                                        </Alert>
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    this.state.uploadingInProgress ?
                                                        <>
                                                            Trwa przetwarzanie zdjęcia {this.state.uploadingStatus} z {this.state.step3imagesCount}
                                                            <ProgressBar style={{ height: "50px" }} animated striped label={`${Math.round(this.state.uploadingStatus / this.state.step3imagesCount * 100)}%`} now={this.state.uploadingStatus / this.state.step3imagesCount * 100} />
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                Array.isArray(this.state.step3images) && this.state.step3images.length ?
                                                                    <Step4 thisRef={this} />
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                }
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                    <Col sm={12} className="mt-2">
                                        {
                                            this.state.jobDone ?
                                                <>
                                                    {
                                                        Array.isArray(this.state.step3images) && this.state.step3images.length ?
                                                            <div className="text-right">
                                                                <Button variant="danger" onClick={() => this.submitPhotos()}>Spróbuj ponownie</Button>
                                                            </div>
                                                            :
                                                            <div className="text-right">
                                                                <Button variant="secondary" onClick={() => window.location.href = '/folder/' + (this.state.step1folder === null ? '' : this.state.step1folder.value)}>Zobacz w folderze</Button> <Button variant="primary" onClick={() => window.location.reload()}>Dodaj więcej zdjęć</Button> <Button variant="success" onClick={() => this.addMorePhotos()}>Dodaj więcej zdjęć w tym samym folderze</Button>
                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div className="text-right">
                                                    <Button variant="primary" onClick={() => { this.setState({ step: parseInt(this.state.step) - 1 }) }} disabled={parseInt(this.state.step) === 1 || this.state.step3loading || this.state.uploadingStarted}><FontAwesomeIcon style={{ marginLeft: "10px", marginRight: "10px" }} icon={faChevronLeft} /></Button> {parseInt(this.state.step) === 4 ? <Button variant="primary" disabled={this.state.uploadingInProgress} onClick={() => this.submitPhotos()}><LatoBold>Wyślij zdjęcia</LatoBold></Button> : <Button variant="primary" onClick={() => this.setState({ step: parseInt(this.state.step) + 1 })} disabled={parseInt(this.state.step) === 4 || this.currentStep() < parseInt(this.state.step) + 1}><FontAwesomeIcon style={{ marginLeft: "10px", marginRight: "10px" }} icon={faChevronRight} /></Button>}
                                                </div>
                                        }
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Container>
            </RequireLogin>
        );
    }
}

export default AdminNewPhoto;