import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Row, Col, Navbar, Breadcrumb, Card, Button, Container, Tabs, Tab, Form, ProgressBar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class Step4 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showPreview: false
        };
    }

    showPreview(index) {
        if (this.state.showPreview) this.setState({ showPreview: false, imgPreview: index });
        else this.setState({ imgPreview: index }, () => { this.setState({ showPreview: true }) });
    }

    render() {
        const yr = this.props.thisRef.state.step1year;
        return (

            <>
                <Modal size="lg" show={this.state.showPreview} onHide={() => { this.showPreview() }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Podgląd zdjęcia</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {
                            this.props.thisRef.state.step3images[this.state.imgPreview] === undefined ?
                                <>Nie znaleziono zdjęcia</>
                                :
                                <img style={{ width: "100%" }} src={this.props.thisRef.state.step3images[this.state.imgPreview].src} />
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => { this.showPreview() }} variant="secondary">Zamknij</Button>
                    </Modal.Footer>
                </Modal>
                <VisibilitySensor
                    onChange={isVisible => {
                        if (isVisible)
                            this.setState({ show: true });
                    }}
                    delayedCall
                >
                    <>
                        <Card.Title>Ścieżka wgrywania</Card.Title>
                        <Breadcrumb>
                            <Breadcrumb.Item>Galeria</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.props.thisRef.state.step1year !== null ? this.props.thisRef.state.step1year.label : ""}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.props.thisRef.state.step1folder !== null ? this.props.thisRef.state.step1folder.label : ""}</Breadcrumb.Item>
                        </Breadcrumb>
                    </>
                </VisibilitySensor>
                {this.state.show ?
                    <>
                        <Card.Title className="mt-2">Podsumowanie (wgrywane zdjęcia: {this.props.thisRef.state.step3imagesCount})</Card.Title>
                        <Row>
                            {
                                this.props.thisRef.state.step3images.map((photo, index) => {
                                    return <>
                                        <Col xl={12} key={index + 'a'}>
                                            <Row key={index + 'b'}>
                                                <Col lg={3} key={index + 'c'}>
                                                    <Col lg={12} key={index + 'cdd'}>
                                                        <img src={photo.src} className="commentImg" key={index + 'd'} />
                                                    </Col>
                                                    <Col className="mt-2" lg={12} key={index + 'cddd'}>
                                                        <Form.Text className="text-muted" key={index + 't'}>
                                                            {(photo.hidden === true ? 'Ukryte' : 'Publiczne')}
                                                        </Form.Text>
                                                    </Col>
                                                    <Col className="mt-2" lg={12} key={index + 'u'}>
                                                        <Button key={index + 'w'} onClick={() => this.showPreview(index)} ><FontAwesomeIcon icon={faSearch} /></Button>
                                                    </Col>
                                                </Col>
                                                <Col lg={9} key={index + 'e'}>
                                                    <Row key={index + 'f'}>
                                                        <Col lg={12} key={index + 'g'}>
                                                            <Form.Group key={index + 'h'}>
                                                                <Form.Label key={index + 'i'}>Tagi</Form.Label>
                                                                <Form.Text key={index + 'j'}>
                                                                    {photo.tags === null || photo.tags === undefined ?
                                                                        'Brak'
                                                                        :
                                                                        photo.tags.map((tag, index) => tag.label + (index < photo.tags.length - 1 ? ', ' : ''))
                                                                    }
                                                                </Form.Text>
                                                            </Form.Group>
                                                            <Form.Group key={index + 'k'}>
                                                                <Form.Label key={index + 'l'}>Opis</Form.Label>
                                                                <Form.Text key={index + 'm'}>
                                                                    {photo.description.current !== null && photo.description.current !== undefined && photo.description.current.value}
                                                                </Form.Text>
                                                            </Form.Group>
                                                            <Form.Group key={index + 'n'}>
                                                                <Form.Label key={index + 'o'}>Data wykonania</Form.Label>
                                                                <Form.Text key={index + 'p'}>
                                                                    {(photo.day === null || photo.day === undefined ? '' : photo.day.value + '.') + (photo.month === null || photo.month === undefined ? '' : m(photo.month.value) + '.') + (yr === null || yr === undefined ? '' : yr.label)}
                                                                </Form.Text>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </Col>
                                    </>
                                })
                            }
                        </Row>
                    </>
                    :
                    <></>
                }
            </>
        );
    }
}

function m(month) {
    if (month.toString().length === 1)
        return '0' + month.toString();
    else
        return month.toString();
}

export default Step4;